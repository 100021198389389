import React from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { useField } from 'formik';
import { useParams } from '@reach/router';
import { useMutation, useQueryClient } from 'react-query';
import { trackTermChange } from '../tracking';
import { RadioCard, ErrorMessage } from '../../common/forms/fields';
import { useAsync, useLead } from '../../client';
import { LineBreak } from '../../ExpressAppUI';
import { useIsMutating } from "react-query";
import { Loading } from "./Loading"
import moment from 'moment';



export function MydSelection() {
  const { data: mydData, isLoading, error } = useAsync(() => Axios.get(`/purchase-bond/${nonce}/get-myd-pricing`));
  const { nonce } = useParams();
  function useMydUpdate() {
    const queryClient = useQueryClient();
    const [, , helpers] = useField('multiTerm');
    const mutation = useMutation((termLength) => Axios.post(`/purchase-bond/${nonce}/update-term`, { multiTerm: termLength }), {
      mutationKey: 'lead',
      onSuccess: (data, term) => {
        trackTermChange({ term, invoice: data.data });
        // second argument of false prevents formik validation
        helpers.setTouched(true, false);
        helpers.setValue(term);
        helpers.setError(null);

        queryClient.setQueryData('lead', (oldLead) => {
          oldLead.data.lead.app.multiTerm = term;
          const oldPremium = oldLead.data.invoice.charges.filter((c) => c.category === 'Bond Premium')[0].amount;
          const newPremium = mydData.data[term - 1].discounted;
          const balanceDifference = newPremium - oldPremium;
          oldLead.data.invoice.balance += balanceDifference;
          oldLead.data.invoice._id = data.data._id
          oldLead.data.invoice.charges.filter((c) => c.category === 'Bond Premium')[0].amount = newPremium;
          return oldLead;
        });

        queryClient.invalidateQueries('lead');
      },
      onError: (data, term) => {
        queryClient.invalidateQueries('lead');
      }
    });

    return { ...mutation, updateMyd: mutation.mutate };
  }

  // this is how we save myd selection to db on change
  const { updateMyd, isLoading: isUpdating } = useMydUpdate();
  function changeHandler(e) { updateMyd(e.target.value) }

  if (error) {
    return (
      <div>
        We've encountered an error
      </div>
    );
  }
  if (!mydData || isLoading) {
    return (
      <>
        <RadioCard>...</RadioCard>
        <RadioCard>...</RadioCard>
        <RadioCard>...</RadioCard>
      </>
    );
  }
  const sortedMydOptions = [...mydData?.data?.sort((a, b) => b.term - a.term)]

  return (
    <>
      <h5 className='panel-title'>Select Term Length</h5>
      <MYDExplainer>Your bond is eligible for a <strong>Multi-Year Discount</strong>. Save money by selecting a longer term.</MYDExplainer>
      <Loading isLoading={isUpdating}>
        <ErrorMessage name="multiTerm"></ErrorMessage>
        {sortedMydOptions.map((term, i) => {

          return (
            <>
              {term?.term == 1 && (<p style={{ textAlign: 'center' }}>Or opt out of <strong>discount</strong></p>)}
              <MydCard expirationDate={term.expirationDate} changeHandler={changeHandler} key={`termItem-${term.term}`} updateMyd={updateMyd} label={`${term.term} Term${term.term > 1 ? 's' : ''}`} name="multiTerm" value={`${term.term}`} discountedPrice={term.discounted} standardPrice={term.standard}></MydCard>
            </>
          )
        })}

        <LineBreak />
      </Loading>
    </>
  );
}
const MYDExplainer = styled.p`
  font-size:14px;
  font-style: italic;
  `;

const MydCard = ({
  label, name, value, standardPrice, discountedPrice, changeHandler, expirationDate
}) => {
  const isMutating = useIsMutating({ mutationKey: "lead" })
  const { lead } = useLead();
  const effDate = moment(lead.bond.application.effectiveDate).format("MM/DD/YYYY")
  const expDate = moment(expirationDate).format("MM/DD/YYYY")

  return (
    <RadioCard name={name} value={value} changeHandler={changeHandler} disabled={isMutating}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>{label}</div>
          <div
            style={{
              fontSize: '11px',
              fontWeight: 600
            }}
          >
            {effDate} - {expDate}
          </div>

        </div>
        {discountedPrice
          && <div style={{
            color: 'rgb(153, 196, 50)',
            fontWeight: 600,
          }}>
            {`Save $${(standardPrice - discountedPrice).toFixed(2)}!`}
          </div>
        }

        {discountedPrice
          && <div style={{
            textDecoration: 'line-through',
            color: 'rgb(158, 158, 158)',
          }}>
            {`$${parseFloat(standardPrice).toFixed(2)}`}
          </div>
        }

        <div style={{
          color: 'rgb(13, 139, 209)',
          fontWeight: 'bold',
        }}
        >
          {`$${parseFloat(discountedPrice).toFixed(2) || parseFloat(standardPrice).toFixed(2)}`}
        </div>
      </div>
    </RadioCard>
  );
};