// hooks.js
import { globalHistory, navigate } from '@reach/router';
import Axios from 'axios';
import React, {
  useEffect, useReducer, useRef, useState,
} from 'react';
import { getCityState, toMoney, validateInput } from './helpers';
import { Mixpanel } from './mixpanel';

function useFormState(initialState, initialIncomplete, onSubmit, formEl) {
  const initErrors = {};
  const initTouched = {};

  Object.keys(initialState).forEach((field) => {
    initTouched[field] = false;
  });

  // Values
  const [values, setValues] = useState(initialState);

  // Errors
  const [errors, setErrors] = useState(initErrors);

  // Touched
  const [touched, setTouched] = useState(initTouched);

  // Incomplete
  const [incompleteFields, setIncompleteFields] = useState(initialIncomplete);

  const zipEl = useRef(
    !!formEl.current && formEl.current.querySelector('input[data-zip]'),
  );

  function removeFormValues(removedValues) {
    const leftoverValues = {};
    const leftoverErrors = {};
    const leftoverTouched = {};
    const leftoverIncomplete = [];
    Object.keys(values).forEach((value) => {
      // If we need to remove the field
      if (removedValues.indexOf(value) < 0) {
        leftoverValues[value] = values[value];
        leftoverErrors[value] = values[value];
        leftoverTouched[value] = values[value];
      }
    });
    incompleteFields.forEach((field) => {
      if (removedValues.indexOf(field) < 0) {
        leftoverIncomplete.push(field);
      }
    });
    setValues(leftoverValues);
    setErrors(leftoverErrors);
    setTouched(leftoverTouched);
    setIncompleteFields(leftoverIncomplete);
  }

  function addFormValues(valuesToAdd, requiredValues) {
    const newValues = { ...values };
    const newTouched = { ...touched };
    const newIncomplete = [...incompleteFields];

    Object.keys(valuesToAdd).forEach((value) => {
      newValues[value] = valuesToAdd[value];
      newTouched[value] = false;
    });
    requiredValues.forEach((value) => {
      newIncomplete.push(value);
    });

    setValues(newValues);
    setTouched(newTouched);
    setIncompleteFields(newIncomplete);
  }

  async function formBlur(e) {
    const {
      name,
      value,
      required,
      dataset: { inputtype = '', track, ...otherData },
    } = e.target;

    const updateObj = {
      errors: { ...errors },
      touched: { ...touched },
      values: { ...values },
    };

    Mixpanel.track(track);

    updateObj.touched[name] = true;

    setTouched({ ...touched, [name]: true });

    let { newValue, error } = validateInput(
      name,
      value,
      inputtype,
      otherData,
    );

    // Do our zip code magic
    if (!!newValue && inputtype === 'zip') {
      // Check for valid zip codes first, so we don't needlessly hit the google api
      const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(newValue);

      if (isValidZip && !otherData.cancelautofill) {
        const cityState = await updateCityState(newValue, otherData.allcaps);

        updateObj.errors = { ...updateObj.errors, ...cityState.errors };
        updateObj.values = { ...updateObj.values, ...cityState.values };
      }
    }

    if (!newValue && !error && required) {
      if (incompleteFields.includes(name)) {
        setIncompleteFields([...incompleteFields, name]);
      }
      error = 'This is a required field';
    }

    if (!!newValue && required && incompleteFields.indexOf(name) > 0) {
      setIncompleteFields(
        incompleteFields.splice(incompleteFields.indexOf(name, 1)),
      );
    }

    if (inputtype.toLowerCase() === 'boolean' && !!newValue) {
      newValue = newValue === 'true';
    }

    setErrors({ ...updateObj.errors, [name]: error || false });

    setValues({ ...updateObj.values, [name]: newValue });
  }

  function submitForm(e, invalidCallback) {
    e.preventDefault();
    const valid = validateForm();
    if (valid) {
      onSubmit();
    } else if (invalidCallback) { invalidCallback(); }
  }

  function validateForm() {
    // get all required inputs
    const inputs = [
      formEl.current.querySelectorAll('input[required]:not([type=radio]), select[required]'),
    ];

    const radios = {};

    const radioinputs = [
      formEl.current.querySelectorAll('input[required][type=radio]'),
    ];

    Array.prototype.slice.call(radioinputs[0]).forEach((radio) => {
      if (!radios.hasOwnProperty(radio.name)) {
        if (!radio.checked) {
          radio.value = '';
        }
        radios[radio.name] = radio;
      } else if (radio.checked && radio.value) {
        radios[radio.name].value = radio.value;
      }
    });

    const allInputs = [...inputs[0], ...Object.values(radios)];

    // initialize update Obj
    const updateObj = {
      errors: {},
      touched: {},
      values: {},
      incompleteFields: [...incompleteFields],
    };

    allInputs.forEach(({
      name, value, dataset: { inputtype, ...otherData }, ...rest
    }) => {
      // if (inputtype === 'radio'){
      //   let {newValue, error} = validateInput(name, rest.checked ? value : '')
      // }

      // if our input is a string we should trim it, so we don't save padded empty strings as correct values
      const isString = typeof value === 'string' || value instanceof String;
      const initialValue = isString ? value.trim() : value;

      let { newValue, error } = validateInput(name, initialValue, inputtype, otherData);

      const incompleteIndex = updateObj.incompleteFields.indexOf(name);
      if (!newValue && !error) {
        error = inputtype === 'radio' ? 'Please select an option' : 'This is a required field';
      }

      if (incompleteIndex < 0 && !!error) {
        updateObj.incompleteFields.push(name);
      }

      if (!!newValue && incompleteIndex >= 0) {
        updateObj.incompleteFields.splice(incompleteIndex, 1);
      }

      updateObj.errors[name] = error || false;
      updateObj.values[name] = newValue;
    });

    Object.keys(touched).forEach((f) => (updateObj.touched[f] = true));

    setTouched(updateObj.touched);
    setValues({ ...values, ...updateObj.values });
    setErrors({ ...errors, ...updateObj.errors });
    setIncompleteFields(updateObj.incompleteFields);

    return (
      updateObj.incompleteFields.length === 0
      && Object.values(errors).filter((e) => !!e).length === 0
    );
  }

  // helper functions
  function hasError(value) {
    return touched[value] && errors[value];
  }

  function isPristine() {
    return !Object.values(touched).includes(true);
  }

  function hasErrors() {
    return Object.values(errors).find((e) => !!e);
  }

  async function updateCityState(zip, allCaps = false) {
    const update = { errors: {}, values: {} };

    const cityEl = formEl.current.querySelector('input[data-zip2city]');
    const stateEl = formEl.current.querySelector('select[data-zip2state]');

    const location = await getCityState(zip);

    if (!!location.city && !!location.state) {
      const cityValue = allCaps ? location.city.toUpperCase() : location.city;
      const stateValue = allCaps ? location.state.toUpperCase() : location.state;
      cityEl.value = cityValue;
      stateEl.value = stateValue;

      update.errors = { [cityEl.name]: false, [stateEl.name]: false };
      update.values = {
        [cityEl.name]: cityValue,
        [stateEl.name]: stateValue,
      };
    }

    return update;
  }

  useEffect(() => {
    if (zipEl.current) {
      updateCityState(values[zipEl.name]);
    }
  }, [values[zipEl.name]]);

  return [
    values,
    touched,
    errors,
    formBlur,
    submitForm,
    {
      hasError,
      isPristine,
      hasErrors,
      setValues,
      setErrors,
      setTouched,
      removeFormValues,
      addFormValues,
    },
  ];
}

function useBondAmountCheck(initialState) {
  const [amount, setAmount] = React.useState({
    value: initialState.value,
    min: toMoney(initialState.min, { noCents: false })
      .substr(1)
      .replace(',', ''),
    error: false,
    validAmount: initialState.value !== 0,
  });

  function checkAmount(value) {
    const newVal = value
      .substr(1)
      .replace(',', '')
      .trim();
    // eslint-disable-next-line no-unused-vars
    const [money, cents] = newVal.split('.');
    const update = { value: newVal };
    if (money) {
      update.validAmount = true;
      if (parseFloat(newVal) < parseFloat(amount.min)) {
        update.error = `The Obligee requires a minimum bond amount of ${toMoney(
          amount.min,
          { noCents: false },
        )}.`;
      } else {
        update.error = false;
      }
    } else {
      update.value = '';
      update.error = 'Please enter a valid money amount ($XXX.XX)';
      update.validAmount = false;
    }
    setAmount({ ...amount, ...update });
  }

  return [amount, checkAmount];
}

function useInitApp(url, reducer, initState) {
  // Set up loading state
  const [loading, setLoading] = useState(true);

  // Set up our 'application' state and it's reducer
  const [state, dispatch] = useReducer(reducer, initState);

  async function getState() {
    // Get's app state from provided url, and timestamps to avoid caching
    const result = await Axios.post(
      `${url}?timestamp=${new Date().getTime()}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    );

    // If we need to redirect -- abort and redirect
    if (result.data.redirect) {
      window.location = result.data.redirect;
      return false;
    }

    // Otherwise grab our fetched state
    const state = result.data;

    // Identify our mixpanel user
    Mixpanel.identify(state.nonce);

    // Props to Track
    const trackProps = {
      'Lead ID': state.leadId,
      'Nonce ID': state.nonce,
      'Bond ID': state.bondId,
      'Bond Type': state.bondType,
      Type: 'Title Bond',
      'Bond State': state.bondState,
      'Bond Version': state.bondVersion,
    };

    // Register Props in mixpanel
    Mixpanel.register(trackProps);

    // Register props in datalayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      leadId: state.leadId,
      userId: state.leadId,
      bondId: state.bondId,
      bondType: state.bondType,
      bondState: state.bondState,
      bondAmount: state.bondAmount,
      bondVersion: state.bondVersion,
      nonceId: state.nonce,
      ecData: {
        email: state.email,
        phone_number: state.phone,
      },
      event: 'Data Layer Ready',
    });

    // After tracking our user, navigate to the needed page.
    navigate(`/title-bond/${result.data.nonce}/${result.data.activePage}`);

    // Dispatch we loaded our state from Db -- update state to reflect.
    dispatch({ type: 'SET_STATE_FROM_DB', payload: result.data });

    // Remove Loading indicator and load the user's view
    setLoading(false);
    dispatch({ type: 'TOGGLE_SAVE', payload: { save: false } });
  }

  // Only run on Mount (empty array as 2nd arg)
  useEffect(() => {
    // Set our page title
    document.title = 'Title Bond Application';
    // init our app with fetched data
    getState();
  }, []);

  return [state, loading, dispatch];
}

async function createAddLog(nonce, dispatch) {
  return function (log) {
    dispatch({
      action: 'TOGGLE_SAVE',
      payload: {
        save: true,
      },
    });

    Axios.post(`title-bond/${nonce}/addLog`, log)
      .then((response) => dispatch({
        action: 'TOGGLE_SAVE',
        payload: {
          save: false,
        },
      }))
      .catch((error) => dispatch({
        action: 'TOGGLE_SAVE',
        payload: {
          save: false,
        },
      }));
  };
}

const useMedia = (query, defaultState = false) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    let mounted = true;
    const mq = window.matchMedia(query);
    const onChange = () => {
      if (!mounted) return;
      setState(!!mq.matches);
    };

    mq.addListener(onChange);
    setState(mq.matches);

    return () => {
      mounted = false;
      mq.removeListener(onChange);
    };
  }, [query]);

  return state;
};

const useLocation = () => {
  const initialState = {
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const removeListener = globalHistory.listen((params) => {
      const { location } = params;
      const newState = { ...initialState, location };
      setState(newState);
    });
    return () => {
      removeListener();
    };
  }, []);

  return state;
};

export {
  useInitApp,
  useBondAmountCheck,
  useFormState,
  createAddLog,
  useMedia,
  useLocation,
};
