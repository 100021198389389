import React from 'react';
import { Router } from '@reach/router';
import PurchaseBond from './PurchaseBond';
import Confirmation from './components/Confirmation';
import MissingSignatures from './components/MissingSignatures';

const PurchaseRouter = () => (
      <Router>
        <PurchaseBond path='/purchase'></PurchaseBond>
        <Confirmation path='/paid'></Confirmation>
        <MissingSignatures path='/missing-signatures'></MissingSignatures>
      </Router>
);

export default PurchaseRouter;
