import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { addressValidators } from "../../utils/validators";
import { AddressFields, FormField } from "../../common/forms/fields";
export function AddressForm({ activeAddress, setActiveAddress }) {
  return (
    <>
      <h5
        style={{
          textAlign: "center",
          marginBottom: "1rem",
          fontWeight: "normal",
        }}
      >
        Enter Shipping Address
      </h5>
      <Formik
        validationSchema={addressSchema}
        initialValues={activeAddress.address}
        onSubmit={(values) => {
          setActiveAddress({
            address: values,
            canEdit: activeAddress.canEdit,
            viewMode: "validate",
          });
        }}
      >
        {(props) => (
          <Form style={{ margin: "0", padding: "0" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
                gridGap: "1rem",
              }}
            >
              <div style={{ gridColumn: "1 / -1" }}>
                <FormField name="name.first" label="Name" />
              </div>
              <AddressFields
                name={"new-address-form"}
                label="Address"
                address="address"
                address2="address2"
                city="city"
                state="state"
                zip="zipcode"
                trackMap={{
                  address: "Shipping Info - Address",
                  address2: "Shipping Info - Address 2",
                  city: "Shipping Info - City",
                  state: "Shipping Info - State",
                  zipcode: "Shipping Info - Zip"
                }}
              />
              <div
                style={{
                  gridColumn: "1 / -1",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button type="submit">Save Address</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const addressSchema = Yup.object().shape({
  name: Yup.object().shape({
    first: Yup.string().trim().required("Name is a required field"),
  }),
  address: addressValidators.address,
  address2: addressValidators.address2,
  city: addressValidators.city,
  state: addressValidators.state,
  zipcode: addressValidators.zip,
});
