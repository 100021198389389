import React from "react"
import Axios from 'axios';
import { APIClient, useLead } from "../client";
import { useParams } from "@reach/router";
import { creditCardValidators } from "../utils/validators"
import "../App.css";

import * as Yup from 'yup';
import { getRequiresShipping, isMYDEligible, getSelectedMailingAddress } from '../utils/business-logic';
export function usePurchaseBondForm() {
  const { lead } = useLead();
  const { initialValues, validationSchema } = getPurchasePageFormConfig(lead);
  const { error, handleSubmit } = useHandleSubmit();
  return {
    initialValues,
    validationSchema,
    handleSubmit,
    error,
  };
}

export function getPurchasePageFormConfig(lead) {
  const schema = {
    cardNumber: creditCardValidators.cardNumber,
    cardExp: creditCardValidators.cardExp,
    cardCode: creditCardValidators.cardCode,
    agreeToTerms: Yup.boolean()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.'),
    cardExpYear: creditCardValidators.cardExpYear,
    cardExpMonth: creditCardValidators.cardExpMonth
  };

  const initialValues = {
    cardNumber: '',
    cardExpMonth: '',
    cardExpYear: '',
    cardCode: '',
    agreeToTerms: false,
  };

  if (getRequiresShipping(lead)) {
    schema.selectedShippingMethod = Yup.mixed().required('Please select a shipping method').oneOf(['Overnight', '2 Day Air', 'Express'], "Please selected a shipping method");
    initialValues.selectedShippingMethod = lead.selectedShipping;

    const hasValidMailingAddress = getSelectedMailingAddress(lead);
    schema.selectedMailingAddress = Yup.mixed().required('You must provide a mailing address');
    if (hasValidMailingAddress) {
      initialValues.selectedMailingAddress = lead.selectedMailingAddress;
    }
    initialValues.selectedMailingAddress = null;
  }

  if (isMYDEligible(lead)) {
    schema.multiTerm = Yup.mixed().required('Please select a term length')
    initialValues.multiTerm = lead.app.multiTerm && lead.app.multiTerm.toString() !== "1" ? lead.app.multiTerm.toString() : ''

  }

  return { validationSchema: Yup.object().shape(schema), initialValues };
}

function useHandleSubmit() {
  const [dataLayer, setDataLayer] = React.useState({});
  const { nonce } = useParams();
  const { invoice, lead } = useLead();
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    // The function that gets our state
    async function getAppState() {
      const { data } = await Axios.get(
        `/purchase-bond/${
          nonce
        }/get-app-state/?timestamp=${new Date().getTime()}&page=paid`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );

      setDataLayer({
        bondId: data?.bond?.id || '',
        bondType:  data?.bond?.name,
        bondAmount: data.bond?.amount,
        bondVersion: data?.bond?.version,
      });
    }

    getAppState();
  }, []);

  // Scroll to error message whenever there is an error
  React.useEffect(() => {
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error])

  // the handle submit function passed to formik
  async function handleSubmit(values, actions) {
    setError(false);
    const data = {
      cardExp: `${values.cardExpMonth}/${values.cardExpYear}`,
      cardNumber: values.cardNumber,
      cardCode: values.cardCode,
    };

    await APIClient(`invoices/${invoice._id.toString()}/processCreditCard`, {
      data,
    })
      .then((authorizeResponse) => {
        if (
          authorizeResponse &&
          authorizeResponse.data &&
          authorizeResponse.data.transactionSuccessful === true
        ) {
          // push variables into dataLayer and trigger event
          window.dataLayer.push({
            leadId: lead._id,
            userId: lead._id,
            bondId: dataLayer.bondId,
            bondType: dataLayer.bondType,
            bondState: lead?.bond?.state,
            bondAmount: dataLayer.bondAmount,
            bondVersion: dataLayer.bondVersion,
            nonceId: nonce,
            ecData: {
              email: lead.email || '',
              phone_number: lead.phone || '',
            },
            event: 'Confirm Bond Purchase',
          });

          window.location = `/purchase-bond/${nonce}`;
        } else {
          setError(
            "Unfortunately, your payment was not able to be processed. Please verify the information you entered matches the card, or try using another card."
          );
        }
      })
      .catch((e) => {
        setError(
          "Something went wrong! Please refresh the page and try again."
        );
      })
      .finally(() => {
        actions.setSubmitting(false)
      });
  }

  return { error, handleSubmit }
}
