import React from 'react';

import { PageButton } from '../ExpressAppUI';

import { Mixpanel } from '../mixpanel';

export default class ContactHub extends React.Component {
  state = {
    submitting: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const blocked = !!this.props.contacts.filter(
      (c) => c.incompleteFields.length,
    )[0];

    if (blocked) {
      return false;
    }

    this.setState({ submitting: true });

    setTimeout(() => {
      Mixpanel.track('Confirmed Principal information (from principal-hub)');
      this.setState({ submitting: false });
      this.props.moveToVerify();
    }, 500);
  };

  render() {
    const {
      addNewContact,
      companyType,
      contacts,
      deleteContact,
      editContact,
    } = this.props;

    const blocked = !!contacts.filter((c) => c.incompleteFields.length)[0];
    let showAdd = true;
    const showDelete = true;

    if (
      contacts.filter((c) => c.incompleteFields.length > 0).length > 0
      || ((companyType === 'individual'
        || companyType === 'sole_proprietorship')
        && contacts.length >= 1)
    ) {
      showAdd = false;
    }

    return (
      <div className="contactHub">
        {blocked ? (
          <p className="error-text">Please Fill Out the Missing Contacts</p>
        ) : (
          <h3 className="helper-text">
            {showAdd ? (
              <strong>
                All business owners must be included before a quote can be
                provided.
              </strong>
            ) : (
              <>
                Click 'Save and Continue' to review your application before
                submission.
              </>
            )}
          </h3>
        )}
        <div className="contacts">
          {contacts.map((c, idx) => {
            const incomplete = c.incompleteFields.length > 0;
            const { fields } = c;
            return (
              <div
                key={`contact-${c.id}`}
                className={`contact-item ${incomplete && 'incomplete'}`}
              >
                <div className="contact-name">
                  <div className="first-name">
                    {fields['[name][first]'] || ''}
                  </div>
                  <div className="last-name">
                    {fields['[name][last]'] || ''}
                  </div>
                </div>
                <div className="buttons">
                  {!c.isPrimary && contacts.length > 1 && showDelete && (
                    <button
                      className="delete"
                      onClick={() => deleteContact(idx)}
                    >
                      Delete
                    </button>
                  )}
                  <button className="edit" onClick={() => editContact(c.id)}>
                    Edit
                  </button>
                </div>
              </div>
            );
          })}
          {showAdd && (
            <div className="contact-item add-owner" onClick={addNewContact}>
              <div className="contact-name">
                <div className="last-name">Add Another Owner</div>
              </div>
              <div className="buttons">
                <button className="add-owner">
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="right-button">
          <PageButton disabled={blocked} onClick={this.handleSubmit}>
            {this.state.submitting ? (
              <>
                {'Saving     '}
                <i class="fa fa-spinner fa-spin" />
              </>
            ) : (
              'Save and Continue'
            )}
          </PageButton>
        </div>
      </div>
    );
  }
}
