import React from 'react';
import { TopBar } from '../DesignGuide';
import Footer from '../Components/Footer';

const Layout = ({ children }) => (
  <div
    style={{
      margin: '0',
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div style={{ flex: 'none' }}>
      <TopBar />
    </div>

    <div
      style={{
        margin: '40px auto',
        flex: 'auto',
        maxWidth: '600px',
        width: '100%',
        minHeight: '',
      }}
    >
      {children}
    </div>
    <div style={{ flex: 'none' }}>
      <Footer />
    </div>
  </div>
);

export default Layout;
