import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
`;

export const Spacer = styled.div`
  width: 50%;
  padding: 0.5rem;
  display: block;
  @media(max-width: 700px){
    display: none;
  };
`;
export const TableHeader = styled.div`
  width: 50%;
  display: flex;
  @media(max-width: 700px){
    width: 100%;
  };
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const HeaderColumn = styled.div`
  width: 50%;
  padding: 0.5rem;
  text-align: center;
  background: rgb(249, 249, 250);
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-color: rgb(233, 233, 233);
  font-weight: 600;
`;

export const TableBody = styled.div`
  width: 100%;
  border: 1px solid rgb(233, 233, 233);
  display: flex;
  flex-direction: column;
`;

export const SectionHeader = styled.div`
  background: rgb(249, 249, 250);
  padding: 0.5rem;
  font-weight: 600;
  font-size: 16px;
`;
export const ChangeTableRow = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  border-top: ${(props) => (props.index === 0 ? 'none' : '1px solid rgb(233,233,233)')};
    @media(max-width: 700px){
      flex-direction: column;
  };
`;

export const ChangeName = styled.div`
  width: 50%;
  padding: 0.5rem;
  font-weight: 300;
  @media(max-width: 700px){
    width: 100%;
  };
`;

export const Changes = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  @media(max-width: 700px){
    width: 100%;
  };
`;

export const ChangeItem = styled.div`
  word-break: break-all;
  width: 50%;
  text-align: center;
  padding: 0.5rem;
  /* border-top:1px solid rgb(233,233,233); */
  border-right: 1px solid rgb(233, 233, 233);
  border-left: 1px solid rgb(233, 233, 233);
  background: ${(props) => props.background};
`;

export default function ChangeTable({ changes }) {
  // TODO: Group changes by category
  // changes = {
  //   Principal: [],
  //   Bond: [],
  //   Mailing: []
  // }
  const groupedChanges = {};
  changes.forEach((change) => {
    if (groupedChanges[change.category]) {
      groupedChanges[change.category].push(change);
    } else {
      groupedChanges[change.category] = [change];
    }
  });
  return (
    <Wrapper>
      <TableHeaderWrapper>
        <Spacer />
        <TableHeader>
          <HeaderColumn>
            Old{' '}
            <i style={{ color: 'rgb(210, 70, 55)' }} className="fa fa-times" />
          </HeaderColumn>
          <HeaderColumn>
            New{' '}
            <i
              style={{ color: 'rgb(116, 219, 102)' }}
              className="fa fa-check"
            />
          </HeaderColumn>
        </TableHeader>
      </TableHeaderWrapper>
      <TableBody>
        {Object.keys(groupedChanges).map((category) => {
          const changesInCategory = groupedChanges[category];
          return (
            <>
              <SectionHeader>{category}</SectionHeader>
              {changesInCategory.map((change, index) => (
                  <ChangeTableRow index={index}>
                    <ChangeName>{change.display}</ChangeName>
                    <Changes>
                      <ChangeItem index={index} background={'#e57f7452'}>
                        {change.oldVal}
                      </ChangeItem>
                      <ChangeItem index={index} background={'#74db6626'}>
                        {change.newVal}
                      </ChangeItem>
                    </Changes>
                  </ChangeTableRow>
              ))}
            </>
          );
        })}
      </TableBody>
    </Wrapper>
  );
}
