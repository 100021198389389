import React from 'react';
import {
  Field as Formikfield, ErrorMessage as FormikErrorMessage, useField, useFormikContext,
} from 'formik';
import { StateOptions, getCityState } from '../../helpers';
import { ZipCodeRegex } from '../../utils/validators';
import 'styled-components/macro';
import { LineBreak } from '../../ExpressAppUI'
import { Mixpanel } from '../../mixpanel'
import {
  FormInput,
  FormLabel,
  FormSelect,
} from '../../ExpressAppUI';
import styled from 'styled-components';


function Field(props){
  const [field] = useField(props.name);
  const blurHandler = (e) => {
    if(props.track){
      Mixpanel.track(props.track)
    }
    if(props.onBlur){
      props.onBlur(e)
    }
    else {
      field.onBlur(e)
    }
  }
  return (
    <Formikfield {...props} onBlur={blurHandler}/>
  )
}

export const ErrorText = styled.div`
  margin: 0px 5px;
  font-size: 12px;
  font-style: italic;
  color: crimson;
  font-weight: 600;
`;
export function ErrorMessage(props) {
  return (
    <FormikErrorMessage {...props} component={ErrorText}></FormikErrorMessage>
  );
}
export const FormField = ({
  label,
  name,
  as,
  subLabel,
  showError = true,
  ...props
}) => {
  // Formik uses array destructuring so we have to do this
  //  weird empty array item thing to get the second value
  const [, meta] = useField(name);
  const hasError = meta.error && meta.touched;
  const renderComp = as || FormInput;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label && (
        <FormLabel htmlFor={name}>
          <>
            {label}
            {subLabel && <i className="sub-label">{subLabel}</i>}
          </>
        </FormLabel>
      )}
      <Field as={renderComp} hasError={hasError} name={name} {...props}></Field>
      {showError && (
        <ErrorMessage name={name}></ErrorMessage>
      )}
    </div>
  );
};

export const RadioField = (props) => {
  return (
    <label
      htmlFor={props.value}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <div>{props.label}</div>
      <Field {...props} type="radio" id={props.value} name={props.name}></Field>
    </label>
  );
};

export const InlineRadio = ({name, value, labelText, ...props}) => {
  const [field] = useField(name);
  const changeHandler = props.changeHandler || field.onChange;

  return (
    <div style={{position: "relative"}}>
      <Field
         as={HiddenRadioInput}
         type="radio"
         id={value}
         name={name}
         value={value}
         onChange={changeHandler}
      />
      <RadioLabel style={{padding: "0px"}} htmlFor={value}>
        <span style={{"marginLeft": "25px"}}>{labelText}</span>
      </RadioLabel>
    </div>
  )
}
export const RadioCard = ({ name, value, ...props }) => {
  const [field] = useField(name);
  const changeHandler = props.changeHandler || field.onChange;

  return (
    <RadioContainer>
      <Field
        as={HiddenRadioInput}
        type="radio"
        id={value}
        name={name}
        value={value}
        onChange={changeHandler}
        disabled={props.disabled}
        checked={field.value === value}
      />
      <RadioLabel htmlFor={value}>
        <RadioCardContent>{props.children}</RadioCardContent>
      </RadioLabel>
    </RadioContainer>
  );
};

const RadioContainer = styled.div`
  box-shadow: rgb(0 0 0 / 8%) 0px 0.125rem 0.25rem;
  position: relative;
  margin: 8px 0px;
  color: black;
  background: white;
  border-radius: 4px;
`;

export const Checkbox = ({name, ...props}) => {
  const [field] = useField(name)
  const changeHandler = props.changeHandler || field.onChange

  return (
    <div style={{position: "relative"}}>
      <Field
        as={HiddenCheckbox}
        type="checkbox"
        id={name}
        name={name}
        onChange={changeHandler}
      />
     <HiddenCheckboxLabel htmlFor={name}></HiddenCheckboxLabel>

     </div>
  )
}

function getValidCardExpYears(){
  const thisYear = new Date().getFullYear();
  const validYears = [thisYear];
  for (let i = 1; i <= 10; i++) {
    validYears.push(thisYear + i);
  }
  return validYears;
}
export const CardFields = (props) => {
  const validYears = getValidCardExpYears()
  return (
    <>
      <div
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
      gridGap: '.25rem',
    }}
    >
      <div css="grid-column: span 12; display: flex; flex-direction: column;">
        <FormField track="CC Info - card num"  as={FormInput} label="Card Number" name="cardNumber"  />
      </div>
      <div css="grid-column: span 4; display: flex; flex-direction: column;">
        <FormField track="CC Info - exp month" label="Month" name="cardExpMonth" as={FormSelect}>
          <option value="" disabled>
            MM
          </option>
          <option value="01">01</option>
          <option value="02">02</option>
          <option value="03">03</option>
          <option value="04">04</option>
          <option value="05">05</option>
          <option value="06">06</option>
          <option value="07">07</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </FormField>
      </div>

      <div css="grid-column: span 4; display: flex; flex-direction: column;">
        <FormField track="CC Info - exp year" label="Year" name="cardExpYear" as={FormSelect}>
          <option value="" disabled>YYYY</option>
          {validYears.map((year,i) => {
            return <option value={year} key={`cc-year-${year}`}>{year}</option>
          })}
        </FormField>
      </div>

      <div css="grid-column: span 4; display: flex; flex-direction: column;">
        <FormField track="CC Info - card code" label="CVV" as={FormInput} name="cardCode" placeholder="CVV"></FormField>
      </div>
    </div>
    <LineBreak/>
    </>
  )
}

const HiddenCheckbox = styled.input`
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  :checked + label::before {
    background-color: #0d8bd1;
    color: white;
    font-size: 16px;
    box-shadow: none;
  }
`

const HiddenCheckboxLabel = styled.label`
  :before{
    box-sizing: border-box;
    vertical-align: top;
    text-align: center;
    width: 20px;
    height: 20px;
    content: '\\002713';
    font-weight: 700;
    position: absolute;
    text-align: center;
    line-height: 1.3;
    text-align: center;
    transition: all .08s ease-out;
    line-height: 1.15;
    color: rgba(0, 0, 255, 0);
    background-color: #d6dcde;
    border-radius: 5px;
    cursor: pointer;
    display: 'block'
  }
`

const HiddenRadioInput = styled.input`
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  :checked + label::before {
    border: 4px solid rgb(13, 139, 209);
    background-color: rgb(13, 139, 209);
    box-shadow: rgb(255 255 255 / 95%) 0px 0px 0px 0.22em inset;
  }
`;


const RadioLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    margin-right: 5px;
    display: block;
    box-sizing: border-box;
    float: left;
    background: rgba(255, 255, 255, 0.8);
    border: 4px solid rgb(214, 220, 222);
    width: 20px;
    height: 20px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 0.08s ease-out 0s;
  }
`;
const RadioCardContent = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ZipField = ({
  name, label, cityField, stateField, ...props
}) => {
  const { setFieldValue } = useFormikContext();
  // Formik uses array destructuring so we have to do this
  //  weird empty array item thing to get the second value
  const [field, meta, helpers] = useField(name);
  const hasError = meta.error && meta.touched;

  async function handleBlur(e) {
    const zip = e.target.value;

    if(props.track){
      Mixpanel.track(props.track)
    }

    if (!meta.error && !!meta.value && ZipCodeRegex.test(meta.value)) {

      field.onBlur(e);
      const location = await getCityState(zip);
      if (location.city && location.state) {
        setFieldValue(cityField, location.city);
        setFieldValue(stateField, location.state);
      }

    }
  }

  return (
    <>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Field as={FormInput} onBlur= {handleBlur} hasError={hasError} name={name} {...props}></Field>
    </>
  );
};

export const AddressFields = ({
  label,
  city,
  state,
  address,
  address2,
  zip,
  name,
  trackMap = {}
}) => (
    <>
      <div css={`
        grid-column: 1/-1;
        display: flex;
        justify-content:start;
        flex-direction: column;
      `}>
        <FormLabel>{label}</FormLabel>
        {[city, state, address, address2, zip].map((field) => (
            <ErrorMessage
              key={`${name}-${field}-error`}
              name={field}
            ></ErrorMessage>
        ))}
        <FormField track={trackMap[address]} showError={false} name={address} placeholder="Street Address"/>
      </div>
      <div css="grid-column: 1/-1;">
        <FormField track={trackMap[address2]} showError={false} name={address2} placeholder="Suite, Unit, Building, Floor, Etc." />
      </div>
      <div css="grid-column: span 2;@media(max-width: 450px){grid-column:1/-1};">
        <ZipField
          cityField={city}
          stateField={state}
          name={zip}
          showError={false}
          placeholder="Zip"
          track={trackMap[zip]}
        ></ZipField>
      </div>
      <div css="grid-column: span 2;@media(max-width: 450px){grid-column:1/-1};">
        <FormField name={city} track={trackMap[city]} showError={false} placeholder="City"></FormField>
      </div>
      <div css="grid-column: span 2;@media(max-width: 450px){grid-column:1/-1};">
        <FormField track={trackMap[state]} name={state} as={FormSelect} showError={false}>
          <option value="" disabled >
            Select State
          </option>
          {StateOptions.map((option) => (
            <option key={`companyState-${option.value}`} value={option.value}>
              {option.display}
            </option>
          ))}
        </FormField>
      </div>
    </>
);
