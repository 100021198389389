import React from 'react';
import styled from 'styled-components';

export function SlidingMenu({
  isOpen,
  setIsOpen,
  children,
  className,
  ...props
}) {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);
  return (
    <div {...props}>
      {isOpen && (
        <ModalBackdrop
          onClick={() => setIsOpen(false)}
        ></ModalBackdrop>
      )}
      <MenuWrapper isOpen={isOpen}>
        {children}
      </MenuWrapper>
      </div>
  );
}

const MenuWrapper = styled.div`
position: fixed;
right: 0;
top: 0;
background: white;
height: 100%;
transition: all .3s ease;
z-index: 1050;
width: 500px;
overflow-y: overlay;
transform: ${(props) => (props.isOpen ? 'translateX(0%)' : 'translateX(100%)')};
max-width:85%;
`;

const ModalBackdrop = styled.div`
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:#000;
  opacity:0.3;
  z-index:1040;
  width:100%;
  height:100%;
`;

const HeaderWrapper = styled.div`
padding: 15px 20px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
background: #0a6ba1;
color: white;

`;

const ModalTitle = styled.div`
font-size: 20px;
font-weight: 500;
`;
export const ModalHeader = ({ icon, title }) => (
    <HeaderWrapper>
      {icon && icon}
      {title && <ModalTitle>{title}</ModalTitle> }
    </HeaderWrapper>
);

export const ModalBody = styled.div`
  width: 100%;
  display:flex;
  justify-content:center;
  padding:8px;
  box-sizing: border-box;
  flex-direction:column;
  margin: 0 auto;
`;
