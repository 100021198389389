import React from "react";
import { useQueryClient, useMutation, useIsMutating } from "react-query";
import { useLead, useUpdateLead, useAsync, APIClient } from "../../client";
import "styled-components/macro";
import { formatAddressForLog } from "../../utils/business-logic";
import styled from "styled-components";
import { trackFedexValidationSelection } from "../tracking";
import { Loading } from "../components/Loading";
export function ValidateAddress({ activeAddress, setActiveAddress }) {
  const {
    changes,
    handleValidationSelection,
    validateAddressResponse,
    showSuggested,
    missingSuite,
    isAlreadyValidAddress,
  } = useFedExValidateAddress(activeAddress.address, setActiveAddress);
  const isMutating = useIsMutating({ mutationKey: "lead" });
  React.useEffect(() => {
    if (isAlreadyValidAddress) {
      setActiveAddress({ address: false });
    }
  }, [isAlreadyValidAddress]);

  if (!validateAddressResponse || isAlreadyValidAddress) {
    return (
      <Loading isLoading={true}>
        <div style={{ height: "500px" }}></div>
      </Loading>
    );
  }

  return (
    <Loading isLoading={isMutating}>
      <h5
        style={{
          textAlign: "center",
          marginBottom: "1rem",
          fontWeight: "normal",
        }}
      >
        Address Validation
      </h5>
      {showSuggested && (
        <>
          <AddressCards>
            <Address
              onClick={() =>
                handleValidationSelection(
                  {
                    _id: activeAddress?.address?._id,
                    ...validateAddressResponse.originalAddress,
                    name: activeAddress.address.name,
                  },
                  false,
                  showSuggested
                )
              }
            >
              <h3 style={{ marginTop: "1rem" }}>Original Address</h3>
              <div>
                <AddressItem>
                  {validateAddressResponse.originalAddress.address}
                </AddressItem>
              </div>
              <div>
                <AddressItem>
                  {validateAddressResponse.originalAddress.address2}
                </AddressItem>
              </div>
              <div>
                <AddressItem>
                  {validateAddressResponse.originalAddress.city}
                </AddressItem>
                ,{" "}
                <AddressItem>
                  {validateAddressResponse.originalAddress.state}
                </AddressItem>{" "}
                <AddressItem>
                  {validateAddressResponse.originalAddress.zip}
                </AddressItem>
              </div>
            </Address>
            <Address
              onClick={() =>
                handleValidationSelection(
                  {
                    _id: activeAddress?.address?._id,
                    name: activeAddress.address.name,
                    ...validateAddressResponse.suggestedAddress,
                  },
                  true,
                  showSuggested
                )
              }
            >
              <h3 style={{ display: "block" }}>
                <img
                  style={{ height: "1rem", display: "block" }}
                  src="/img/logos/fedex-transparent.png"
                  alt="FEDEX"
                ></img>
                <div>Suggested Address</div>
              </h3>
              <div>
                <AddressItem hasChange={changes.address}>
                  {validateAddressResponse.suggestedAddress.address}
                </AddressItem>
              </div>
              <div>
                <AddressItem hasChange={changes.address2}>
                  {validateAddressResponse.suggestedAddress.address2}
                </AddressItem>
              </div>
              <div>
                <AddressItem hasChange={changes.city}>
                  {validateAddressResponse.suggestedAddress.city}
                </AddressItem>
                ,{" "}
                <AddressItem hasChange={changes.state}>
                  {validateAddressResponse.suggestedAddress.state}
                </AddressItem>{" "}
                <AddressItem hasChange={changes.zip}>
                  {validateAddressResponse.suggestedAddress.zip}
                </AddressItem>
              </div>
            </Address>
          </AddressCards>
          <p style={{}}>
            Our shipping partner FedEx would like to update your address. To
            accept the changes, select the Suggested Address above. To use the
            address you entered, select the Original Address.
          </p>
        </>
      )}
      {!showSuggested && (
        <>
          <AddressCards>
            <Address
              onClick={() =>
                handleValidationSelection(
                  {
                    _id: activeAddress?.address?._id,
                    ...validateAddressResponse.originalAddress,
                    name: activeAddress.address.name,
                  },
                  false,
                  showSuggested
                )
              }
              failed
            >
              <p
                style={{
                  fontSize: ".7rem",
                  color: "crimson",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <em>*FedEx was unable to validate this address</em>
              </p>
              <h3 style={{ marginTop: "1rem" }}>Original Address</h3>
              <div>
                <AddressItem>
                  {validateAddressResponse.originalAddress.address}
                </AddressItem>
              </div>
              <div>
                <AddressItem>
                  {validateAddressResponse.originalAddress.address2}
                </AddressItem>
              </div>
              <div>
                <AddressItem>
                  {validateAddressResponse.originalAddress.city}
                </AddressItem>
                ,{" "}
                <AddressItem>
                  {validateAddressResponse.originalAddress.state}
                </AddressItem>{" "}
                <AddressItem>
                  {validateAddressResponse.originalAddress.zip}
                </AddressItem>
              </div>
            </Address>
          </AddressCards>
          {missingSuite ? (
            <div style={{ marginTop: "1rem", textAlign: "center" }}>
              The shipping address you entered could not be verified with our
              shipping partner FedEx. It looks like you may have forgotten a
              valid apartment, unit or suite number. To update your address,
              click cancel.
            </div>
          ) : (
            <div style={{ marginTop: "1rem", textAlign: "center" }}>
              The shipping address you entered could not be verified with our
              shipping partner FedEx. Please make sure the address you entered
              is correct.
            </div>
          )}
          <div css="display: flex; width:100%; flex-direction:row; align-items: center; justify-content: center; font-size: 12px; margin-top:1rem;">
            <div
              css="color:crimson; cursor: pointer; background:none; border:2px solid crimson; border-radius: 5px; padding: 12px 20px; font-weight:600; margin-right:1.5rem;"
              onClick={() => {
                if (activeAddress.canEdit) {
                  setActiveAddress({ ...activeAddress, viewMode: "edit" });
                } else {
                  setActiveAddress({
                    viewMode: false,
                    address: false,
                    canEdit: false,
                  });
                }
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ height: "1rem", width: "1rem" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                  CANCEL
                </span>
              </div>
            </div>
            <button
              css="color: white; font-weight:600; background: none; border: 2px solid #0a6ba1; color: #0a6ba1;"
              onClick={() => {
                handleValidationSelection(
                  {
                    _id: activeAddress?.address?._id,
                    ...validateAddressResponse.originalAddress,
                    name: activeAddress.address.name,
                  },
                  false,
                  showSuggested
                );
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "12px" }}>Use this address</span>
              </div>
            </button>
          </div>
        </>
      )}
    </Loading>
  );
}

// Utility to get suggested addresses from fed ex
function useFedExValidateAddress(addressToValidate, setActiveAddress) {
  const { handleValidationSelection } =
    useValidationSelection(setActiveAddress);
  const { data, isError, isSuccess } = useAsync(() =>
    APIClient("fedex/validateAddress", {
      data: {
        address: addressToValidate.address,
        address2: addressToValidate.address2,
        city: addressToValidate.city,
        state: addressToValidate.state,
        zip: addressToValidate.zipcode,
      },
    })
  );
  React.useEffect(() => {
    if (isError) {
      handleValidationSelection(addressToValidate, false, false);
    }
  }, [isError]);

  const validateAddressResponse = data && data.data;
  const showSuggested =
    validateAddressResponse?.hasSuggestions &&
    validateAddressResponse?.isVerified;

  const missingSuite =
    validateAddressResponse && isMissingSuite(validateAddressResponse);

  const changes = {};
  if (showSuggested) {
    Object.keys(validateAddressResponse.originalAddress).forEach((key) => {
      changes[key] =
        validateAddressResponse.originalAddress[key] !==
        validateAddressResponse.suggestedAddress[key];
    });
  }

  const isAlreadyValidAddress = React.useMemo(
    () =>
      addressToValidate &&
      validateAddressResponse?.isVerified === true &&
      validateAddressResponse.hasSuggestions === false,
    [
      validateAddressResponse?.isVerified,
      validateAddressResponse?.hasSuggestions,
      addressToValidate,
    ]
  );

  React.useEffect(() => {
    if (isAlreadyValidAddress) {
      handleValidationSelection(addressToValidate, true, true);
    }
  }, [isAlreadyValidAddress]);

  function isMissingSuite(validateAddressResponse) {
    let isMissingSuite = false;
    const attributes =
      validateAddressResponse &&
      validateAddressResponse.fedExResponse.AddressResults &&
      validateAddressResponse.fedExResponse.AddressResults[0] &&
      validateAddressResponse.fedExResponse.AddressResults[0].Attributes;
    if (attributes) {
      isMissingSuite =
        attributes.find(
          (a) => a.Name === "SuiteRequiredButMissing" && a.Value === "true"
        ) || false;
    }
    return isMissingSuite;
  }

  return {
    isAlreadyValidAddress,
    missingSuite,
    showSuggested,
    changes,
    validateAddressResponse,
    isSuccess,
    handleValidationSelection,
  };
}

// Handler to call when user makes an address selection
// 1.) Creates contact / edits existing one
// 2.) Logs on lead
// 3.) Updates queryClient with contact updates
// 4.) uses useUpdateShipping Address to update the lead reference
function useValidationSelection(setActiveAddress) {
  const { lead } = useLead();
  const queryClient = useQueryClient();
  const { updateShippingAddress } = useUpdateShippingAddress(setActiveAddress);

  const { mutateAsync: createContact } = useMutation(
    (data) => APIClient("contacts", { data }),
    { mutationKey: "lead" }
  );
  const { mutateAsync: updateContact } = useMutation(
    ({ _id, data }) =>
      APIClient(`contacts/${_id}`, {
        data,
        method: "PUT",
      }),
    { mutationKey: "lead" }
  );
  async function handleValidationSelection(
    address,
    isFedExValidated,
    showSuggested
  ) {
    let contactItem = address?._id ? address : null;
    const contactUpdate = {
      address: address.address,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zipcode: address.zip,
      type: "mailing_address",
      name: address.name,
      fedexValidatedAddress: isFedExValidated,
    };

    // Make a contact item if it doesnt exist yet
    if (!contactItem) {
      const data = {
        contact: contactUpdate,
        options: { leadId: lead._id.toString() },
      };
      const { data: newContact } = await createContact(data);
      contactItem = newContact;
      queryClient.setQueryData("lead", (oldLead) => {
        oldLead.data.lead.contacts.push(contactItem);
        return oldLead;
      });
    } else {
      // update the existing one
      const data = {
        ...contactUpdate,
      };
      await updateContact({ _id: contactItem._id.toString(), data });

      contactItem = { ...contactItem, ...contactUpdate };
      queryClient.setQueryData("lead", (oldLead) => {
        const contacts = oldLead.data.lead.contacts.map((c) => {
          if (c._id.toString() === contactItem._id) {
            return contactItem;
          }
          return c;
        });
        oldLead.data.lead.contacts = contacts;
        return oldLead;
      });
    }

    trackFedexValidationSelection(isFedExValidated, showSuggested);

    // We will always have a valid contact Item
    updateShippingAddress({
      selectedMailingAddress: contactItem._id.toString(),
    });
  }

  return { handleValidationSelection };
}

// Mutation to update the actual shipping address reference on the lead
function useUpdateShippingAddress(setActiveAddress) {
  const queryClient = useQueryClient();
  const { lead } = useLead();
  const mutation = useUpdateLead({
    mutationKey: "lead",
    onMutate: (leadUpdate) => {
      queryClient.cancelQueries("lead");
      queryClient.setQueryData("lead", (oldLead) => {
        oldLead.data.lead.selectedMailingAddress =
          leadUpdate.selectedMailingAddress;
        return oldLead;
      });
      // close modal
      setActiveAddress({ address: false });
    },
    onSuccess: () => {
      const selectedContact = lead.contacts.find(
        (c) => c._id.toString() === lead.selectedMailingAddress
      );
      APIClient(`leads/${lead._id.toString()}/addLog`, {
        data: formatAddressSelectionLog(selectedContact),
      });
      queryClient.invalidateQueries("lead");
    },
  });

  return { ...mutation, updateShippingAddress: mutation.mutate };

  function formatAddressSelectionLog(contactUpdate) {
    const log = {
      action: "Shipping Address Validation",
      category: "Account Information",
      user: "Customer",
    };
    let comment = `User selected a ${
      contactUpdate.fedexValidatedAddress
        ? "<strong>Verified</strong>"
        : "<strong>UNVERIFIED</strong>"
    } FedEx Address<br><br>`;
    comment += formatAddressForLog(contactUpdate);
    log.comment = comment;
    return log;
  }
}

const AddressCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }
`;

const AddressItem = styled.span`
  background: ${(props) =>
    props.hasChange ? "rgba(134,239,172,.25)" : "none"};
`;

const Address = styled.div`
  background: ghostwhite;
  padding: 1rem;
  cursor: pointer;
  max-width: 300px;
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  outline: ${(props) =>
    props.selected
      ? "3px solid rgb(13, 139, 209)"
      : props.failed
      ? "1px solid crimson"
      : "1px solid lightgray"};
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 50%;
  &:first-child {
    margin-right: ${(props) => (props.failed ? "" : "1rem")};
  }
  @media (max-width: 700px) {
    max-width: 100%;
    padding: 1rem 0 1rem 0;
    &:first-child {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  :hover {
    cursor: pointer;
    outline: 3px solid rgb(13, 139, 209);
  }
  h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  button {
    font-size: 1rem;
    background: lightblue;
    color: blue;
    text-transform: none;
    margin-top: 0.5rem;
  }
`;
