import React from 'react';
import { Router } from '@reach/router';
import License from './License';
import LicenseNumber from './LicenseNumber';
import { Processing, PostSignature } from './Processing';
import Layout from './Layout';
function index(props) {
  return (
    <Layout>
      <Router>
        {/* handles asking for license number */}
        <License path="/license"></License>
        {/* handles collecting indemnitor info for a valid license number and submitting application for quote */}
        <LicenseNumber path="/license/:licenseNumber"></LicenseNumber>
        {/* handles pending,error,and success ui when processing quote after submission.
              pending: shows loader while thunderwriting job processes, polls every X seconds to check for completion of thunderwriting job
              success: prompt user to sign docs in docusign -> redirects to 'processing-sigs' on completion in docusign,
              error: prompts user to reload to try again or call customer care
        */}
        <Processing path="/processing" />
        {/* this is the route a user is redirected to after signature colleciton in docusign
            pending: show loader while we poll every X seconds to check for docusign webhook to have completed
            success: redirect user to purchase page
            error: prompts user to reload to try again or call customer care
         */}
        <PostSignature path="/processing-sigs" />
      </Router>
    </Layout>
  );
}

export default index;
