import React from 'react';
import MaskedInput from 'react-text-mask';

import { StateOptions } from '../helpers';

import {
  FormInput, FormSelect, FormItem, FormLabel,
} from '../ExpressAppUI';

export const ContactFields = ({
  handleBlur,
  fields,
  errors,
  hasError,
  handleSubmit,
  handleChange,
  incompleteFields,
}) => (
  <>
    {!!errors.missingFields && (
      <p className="error-text">Please Fill in Missing Fields</p>
    )}
    <FormItem>
      <FormLabel>
        Name<sup>*</sup>
      </FormLabel>
      <FormInput
        onBlur={handleBlur}
        required
        defaultValue={fields['[name][first]']}
        name={'[name][first]'}
        autoComplete='given-name'
        width="49%"
        margin="0 2% 0 0"
        placeholder="First"
        hasError={hasError('[name][first]')}
        data-track="Principal First Name"
      />
      <FormInput
        onBlur={handleBlur}
        name={'[name][last]'}
        defaultValue={fields['[name][last]']}
        required
        autoComplete='family-name'
        width="49%"
        placeholder="Last"
        hasError={hasError('[name][last]')}
        data-track="Principal Last Name"
      />
    </FormItem>
    <FormItem>
      <FormLabel>
        Phone<sup>*</sup>
      </FormLabel>
      {errors.phone && <p className="small-error">{errors.phone}</p>}
      <MaskedInput
        mask={[
          '(',
          /\d/,
          /\d/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        className={`${hasError('phone') ? 'has-error' : ''} form-input`}
        name={'phone'}
        defaultValue={fields.phone}
        required
        width="49%"
        placeholder="(000) 000-0000"
        data-inputtype="phone"
        onBlur={handleBlur}
        data-track="Principal Phone"
      />
    </FormItem>

    <FormItem>
      <FormLabel>
        Email<sup>*</sup>
        <i className="sub-label">
          Confidential documents will be sent to this address.
        </i>
      </FormLabel>
      {errors.email && <p className="small-error">{errors.email}</p>}
      <FormInput
        name="email"
        defaultValue={fields.email}
        required
        placeholder="name@example.com"
        hasError={hasError('email')}
        onBlur={handleBlur}
        data-inputtype="email"
        data-track="Principal Email"
      />
    </FormItem>
    <FormItem>
      <FormLabel>
        Date Of Birth<sup>*</sup>
      </FormLabel>
      {errors.dob && <p className="small-error">{errors.dob}</p>}
      <MaskedInput
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholder="MM/DD/YYYY"
        name="dob"
        defaultValue={fields.dob}
        required
        autoComplete='bday'
        className={`${hasError('dob') ? 'has-error' : ''} form-input`}
        data-inputtype="date"
        onBlur={handleBlur}
        data-track="Principal DOB"
      />
    </FormItem>
    <SSNFieldItem
      onBlur={handleBlur}
      hasProvidedSSN={typeof fields.ssn == 'undefined'}
      fields={fields}
      errors={errors}
      hasError={hasError('ssn')}
    />
    <FormItem>
      <FormLabel>
        Do you own or rent your home?<sup>*</sup>
      </FormLabel>
      {hasError('home_type') && (
        <p className="small-error">Please Select an Option</p>
      )}
      <div
        className={`${hasError('home_type') ? 'radio-error' : ''} radio-wrap`}
        onChange={handleBlur}
      >
        <input
          required
          className="toggle-radio"
          type="radio"
          id="own"
          data-inputtype="radio"
          name="home_type"
          value="own"
          data-track="Principal Home Type -- OWN"
          // onBlur={handleBlur}
          // onClick={handleBlur}
          defaultChecked={fields.home_type === 'own'}
        />
        <label className="toggle-label" htmlFor="own">
          Own
        </label>
        <input
          required
          className="toggle-radio"
          type="radio"
          id="rent"
          data-inputtype="radio"
          name="home_type"
          value="rent"
          data-track="Principal Home Type -- RENT"
          defaultChecked={fields.home_type === 'rent'}
        // onClick={handleBlur}
        // onBlur={handleBlur}
        />
        <label className="toggle-label" htmlFor="rent">
          Rent
        </label>
      </div>
    </FormItem>
    <FormItem>
      <FormLabel>Years of Industry Experience</FormLabel>
      <FormInput
        onBlur={handleBlur}
        type="number"
        name={'experience'}
        defaultValue={fields.experience}
        data-track="Principal Experience"
        style={{ zIndex: 'auto' }}
        data-type="number"
        hasError={hasError('experience')}
        required
      />
    </FormItem>
    <FormItem>
      <FormLabel>
        Home Address
        <sup>*</sup>
      </FormLabel>
      <FormInput
        defaultValue={fields.address}
        required
        onBlur={handleBlur}
        name={'address'}
        autoComplete='address-line1'
        data-inputtype="address"
        placeholder="Street Address"
        hasError={hasError('address')}
        data-track="Principal Address"
      />
      <FormInput
        defaultValue={fields.address2}
        autoComplete='address-line2'
        onBlur={handleBlur}
        data-inputtype="address"
        name={'address2'}
        placeholder="Suite, Unit, Building, Floor, Etc."
        data-input-type={'address'}
        data-track="Principal Address 2"
      />
      <FormInput
        onBlur={handleBlur}
        width={'30%'}
        name={'zipcode'}
        defaultValue={fields.zipcode}
        placeholder="Zip"
        data-page="company"
        autoComplete='postal-code'
        data-inputtype="zip"
        required
        hasError={hasError('zipcode')}
        data-track="Principal Zipcode"
      />
      <FormInput
        onBlur={handleBlur}
        width={'48%'}
        margin={'0% 1%'}
        name={'city'}
        placeholder="City"
        autoComplete='address-level2'
        value={fields.city}
        required
        hasError={hasError('city')}
        onChange={handleChange}
        data-track="Principal City"
      />
      <FormSelect
        onBlur={handleBlur}
        name={'state'}
        autoComplete='address-level1'
        value={fields.state}
        required
        width={'20%'}
        hasError={hasError('state')}
        onChange={handleChange}
        data-track="Principal State"
      >
        <option value="" disabled>
          State
        </option>
        {StateOptions.map((option) => (
          <option key={`companyState-${option.value}`} value={option.value}>
            {option.display}
          </option>
        ))}
      </FormSelect>
    </FormItem>
    <input type="hidden" name="lastStep" value="true" />
  </>
);


const SSNFieldItem = ({ hasProvidedSSN, hasError, errors, fields, ...props }) => {
  // check if user has provided SSN just let them know we have it
  if (hasProvidedSSN) {
    return <FormItem>
      <FormLabel>
        Social Security
        <i className="sub-label" style={{ marginTop: '2px' }}>
          Thanks for providing your Social Security Number. For your own safety we can't display this
        </i>
      </FormLabel>
    </FormItem>
  }

  // otherwise we need user to provide SSN
  return (
    <>
      <FormItem>
        <FormLabel>
          Social Security<sup>*</sup>
          <i className="sub-label">
            <sup>
              <i className="fa fa-xs fa-lock" style={{ marginLeft: '5px' }} />
            </sup>
            {'   '}
            Stored securely, required for soft credit review.
          </i>
        </FormLabel>
        {errors.ssn && <p className="small-error">{errors.ssn}</p>}
        <MaskedInput
          mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          placeholder="XXX-XX-XXXX"
          name={'ssn'}
          className={`${hasError ? 'has-error' : ''} form-input`}
          required
          data-inputtype="ssn"
          data-track="Principal SSN"
          // this field will only be prefilled if user edits contact they submitted 
          // w/o refreshing page
          defaultValue={fields.ssn}
          {...props}
        />
      </FormItem>
    </>)
}