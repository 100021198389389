import React from "react";
import { useField } from "formik";
import styled from "styled-components";
import { ErrorMessage } from "../../common/forms/fields";
import { Mixpanel } from "../../mixpanel";
import { poBoxRegex } from "../../utils/validators";

export function AddressCards({
  selectedMailingAddress,
  principalAddress,
  setActiveAddress,
}) {
  const [, meta] = useField("selectedMailingAddress");
  const hidePrincipalAddress = poBoxRegex.test(principalAddress.address) || poBoxRegex.test(principalAddress.address2)
 
  if (selectedMailingAddress)
    return (
      <AddressCard
        address={selectedMailingAddress}
        canEdit={true}
        onClick={() => {
          setActiveAddress({
            address: selectedMailingAddress,
            viewMode: "edit",
            canEdit: true,
          })
          // track Edit Shipping Address
          Mixpanel.track("Edit Shipping Address");
        }}
      />
    );
  else
    return (
      <div>
        <ErrorMessage name="selectedMailingAddress"></ErrorMessage>
        <AddressCard
          style={{
            border:
              meta.error && meta.touched
                ? "1px solid red"
                : "1px solid rgb(237, 242, 247)",
          }}
          onClick={() => {
            setActiveAddress({
              address: {
                name: { first: "" },
                address: "",
                address2: "",
                city: "",
                state: "",
                zipcode: "",
              },
              viewMode: "edit",
              canEdit: true,
            });
            // track Add New Shipping Address
            Mixpanel.track("Add New Shipping Address");
          }}
        />
        {!hidePrincipalAddress && (
          <>
            <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              with: "100%",
              margin: "15px 0px",
            }}
            >
            <div style={{ height: "1px", flex: 1, background: "#ccc" }}></div>
            <div style={{ padding: "0px 15px", fontWeight: 500 }}>
              Or use address on file
            </div>
            <div style={{ height: "1px", flex: 1, background: "#ccc" }}></div>
          </div>

          <AddressCard
          style={{
            border:
            meta.error && meta.touched
            ? "1px solid red"
            : "1px solid rgb(237, 242, 247)",
          }}
          address={principalAddress}
          onClick={() => {
            setActiveAddress({
              address: principalAddress,
              viewMode: "validate",
              canEdit: false,
            });
            // track Customer selected shipping address on file
            Mixpanel.track("Customer selected address on file")
          }}
          ></AddressCard>
          </>
        )}
      </div>
    );
}

const AddressCard = ({ address, canEdit, onClick, ...props }) => {
  return (
    <ShippingSelection onClick={onClick}>
      {address && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
          }}
        >
          <div
            style={{
              color: "black",
              fontSize: "16px",
              fontWeight: 500,
              marginBottom: ".25rem",
            }}
          >
            {address?.name?.first}
          </div>
          <div>{address.address}</div>
          <div>{address.address2}</div>
          <div>
            {address.city}, {address.state}, {address.zipcode}
          </div>
        </div>
      )}

      {!address && (
        <div
          style={{
            color: "black",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          Add New Address
        </div>
      )}

      {canEdit && (
        <div
          style={{
            color: "rgb(13, 139, 209)",
            fontWeight: "bold",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: ".25rem" }}>Edit</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style={{ height: "18px" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </div>
      )}

      {!canEdit && (
        <div
          style={{
            color: "rgb(13, 139, 209)",
            fontWeight: "bold",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <svg
            style={{ height: "18px", color: "green" }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      )}
    </ShippingSelection>
  );
};

const ShippingSelection = styled.div`
  align-items: center;
  border: 1px solid rgb(237, 242, 247);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  padding: 12.5px;
  line-height: 1;
  box-shadow: rgb(0 0 0 / 8%) 0px 0.125rem 0.25rem;
  color: black;
  background: white;
  border-radius: 4px;
  justify-content: space-between;
`;
