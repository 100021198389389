import React from 'react';
import styled from 'styled-components';

const PrincipalP = styled.p`
  margin-bottom:0;
  margin-top:0;
`;

const PrincipalInformation = ({ company }) => (
    <>
      <h5 className='panel-title'>Principal Information</h5>
      <PrincipalP>{company.name}</PrincipalP>
      <PrincipalP>{company.address}</PrincipalP>
      <PrincipalP>{company.address2}</PrincipalP>
      <PrincipalP>{company.city}, {company.state} {company.zip}</PrincipalP>

    </>
);

export default PrincipalInformation;
