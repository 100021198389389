import mixpanel from 'mixpanel-browser';

mixpanel.init('92f0bcc45d3833be41727b37c79a049b');

const env_check = true;

const actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name) => {
    if (env_check) mixpanel.track(name);
  },
  register: (userProperties) => {
    if (env_check) mixpanel.register(userProperties);
  },
};

export const Mixpanel = actions;
