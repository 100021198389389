import Axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import MediaQuery from 'react-responsive';
import './App.css';
import AccordianWrapper from './Components/Accordian';
import BondForm from './Components/BondForm';
import CompanyForm from './Components/CompanyForm';
import ContactPage from './Components/ContactPage';
import Footer from './Components/Footer';
import Header from './Components/Header';
import { Panels } from './Components/Panels';
import { ProgressBar } from './Components/ProgressBar';
import ScrollTo from './Components/ScrollTo';
import SideBar from './Components/SideBar';
import { ThankYouPage } from './Components/ThankYou';
import TrustSeals from './Components/TrustSeals';
import VerifyPage from './Components/VerifyPage';
import { Accordian, MainPage } from './ExpressAppUI';
import { getQueryVariable, toFullStateName, toMoney } from './helpers';
import { Mixpanel } from './mixpanel';

class ExpressApp extends React.Component {
  state = {
    isLoaded: false,
    activeContact: false,
    hitVerify: false,
  };

  moveToVerify = () => {
    this.setState({ hitVerify: true, activeStep: 'verify' });
  };

  submitApplication = () => {
    Axios.post(`${window.location.pathname}/verify`).then((res) => this.setState({ submitted: true }, () => setTimeout(() => {
      // custom event to trigger google ads express app conversion
      window.dataLayer.push({
        leadId: this.state?.lead?.id,
        userId: this.state?.lead?.id,
        bondId: this.state?.bondInfo?.id,
        bondType: this.state?.bondInfo?.name,
        bondState: this.state?.fields?.bond?.state,
        bondAmount: this.state?.fields?.bond?.amount,
        bondVersion: this.state?.bondInfo?.isV2 ? 'v2' : 'v1',
        nonceId: this.state?.lead?.nonce,
        ecData: {
          email: this.state?.lead?.email,
          phone_number: this.state?.lead?.phone,
        },
        event: 'Express App Form Complete',
      });

      if (this.state.bondInfo.isExpressLeadEligible) {
        window.location.replace(`/express-application/${this.state.lead.nonce}/processing`);
      } else {
        this.setState({ activeStep: 'ThankYou' })
      }
    }, 800)));
  };

  componentWillMount() {
    Axios.get(
      `${window.location.pathname}/getState?timestamp=${new Date().getTime()}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    ).then((res) => {
      const newState = res.data;

      Mixpanel.identify(newState.lead.nonce);

      Mixpanel.register({
        'Lead ID': newState.lead.id,
        'Bond Version': newState.bondInfo.isV2 ? 'v2' : 'v1',
        'Nonce ID': newState.lead.nonce,
        'Bond ID': newState.bondInfo.id,
        'Bond Type': newState.bondInfo.name,
        Type: 'Express Application',
      });

      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        leadId: newState.lead.id,
        userId: newState.lead.id,
        bondId: newState.bondInfo.id,
        bondType: newState.bondInfo.name,
        bondState: newState.fields.bond.state,
        bondAmount: newState.fields.bond.amount,
        bondVersion: newState.bondInfo.isV2 ? 'v2' : 'v1',
        nonceId: newState.lead.nonce,
        ecData: {
          email: newState.lead?.email,
          phone_number: newState.lead?.phone,
        },
        event: 'Data Layer Ready',
      });

      // If the lead has an underwriter, force them out of the Express App
      if (newState.lead.underwriting.length > 0) {

        const selectedUnderwriter = newState.lead.underwriting.find(underwriter => underwriter.selected && underwriter.quote !== 0);
        const location = (
          selectedUnderwriter
            ? `/purchase/${newState.lead.nonce}`
            : `/express-application/${newState.lead.nonce}/processing`
        );

        window.location.href = location
      }

      // hijack active step for now
      const appStart = getQueryVariable('appStart') === 'true';
      if (appStart) {
        window.dataLayer.push({ applicationStarted: true });
      }

      newState.activeStep = newState.activeStep.toLowerCase() === 'verify'
        ? 'owners'
        : newState.activeStep;

      if (
        newState.contacts.length === 1
        && newState.contacts[0].incompleteFields.length > 0
      ) {
        newState.activeContact = newState.contacts[0].id;
      }

      this.setState({
        ...newState,
        isLoaded: true,
        submitted: newState.activeStep === 'ThankYou',
        hitVerify: newState.activeStep === 'ThankYou',
      });
    });
  }





  componentDidUpdate(prevProps, prevState) {

    let trackPage = false;
    const stepToPage = {
      company: 'Company Page',
      bond: 'Bond Page',
      verify: 'Verify Information Page',
      ThankYou: 'Express Application Complete',
      owners:
        this.state.activeContact === false ? 'Principal Hub' : 'Principal Page',
    };

    if (prevState.activeStep !== this.state.activeStep) {
      trackPage = this.state.activeStep === 'owners' && !this.state.contacts.length
        ? false
        : stepToPage[this.state.activeStep];
    }

    if (
      prevState.activeContact !== this.state.activeContact
      && this.state.activeStep === 'owners'
    ) {
      trackPage = stepToPage[this.state.activeStep];
    }

    if (trackPage) {
      Mixpanel.track(trackPage);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'EAEvent',
        eventCategory: 'EA Pageview',
        eventAction: `EA ${trackPage} Pageview`,
        eventLabel: `${this.state.fields.bond.state} - ${this.state.bondInfo.name
          }`,
        eventValue: `${this.state.lead.id}`,
      });
    }
  }

  deleteContact = (idx) => {
    const { contacts } = this.state;
    const newContacts = [...contacts];

    Axios.post(
      `${window.location.pathname}/delete-principal/${contacts[idx].id}`,
    );

    newContacts.splice(idx, 1);

    this.setState({
      contacts: newContacts,
      activeContact: false,
    });
  };

  addNewContact = () => {
    Axios.get(
      `${window.location.pathname
      }/add-principal?timestamp=${new Date().getTime()}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    ).then((res) => {
      const newContact = res.data;
      const contacts = [...this.state.contacts];

      Mixpanel.track('Customer Added additional Owner');
      Mixpanel.register({ 'Additional Owners': true });

      contacts.push(newContact);

      this.setState({
        contacts,
        activeContact: newContact.id,
      });
    });
  };

  submitContact = ({
    id,
    shouldSave = true,
    updateIncomplete,
    ...fields
  }) => {
    const { incompleteFields, contacts } = this.state;
    const contactIdx = contacts.indexOf(
      contacts.filter((c) => c.id.toString() === id.toString())[0],
    );

    contacts[contactIdx] = {
      ...contacts[contactIdx],
      fields,
      incompleteFields: updateIncomplete,
    };

    let subIncomplete = incompleteFields.owners;

    subIncomplete = subIncomplete.filter((f) => !f.includes(id));

    if (shouldSave) {
      Axios.post(
        `${window.location.pathname}/principal/${id}${window.location.search
        }`,
        qs.stringify({ ...fields, lastStep: true }),
      );
    }

    this.setState({
      incompleteFields: {
        ...this.state.incompleteFields,
        owners: subIncomplete,
      },
      contacts,
      activeContact: false,
    });
  };

  saveContact = (name, value, incomplete, id) => {
    const { incompleteFields, contacts } = this.state;
    const contactIdx = contacts.indexOf(
      contacts.filter((c) => c.id.toString() === id.toString())[0],
    );
    const activeContact = contacts[contactIdx];

    activeContact.fields = { ...activeContact.fields, [name]: value };

    activeContact.incompleteFields = incomplete;

    let subIncomplete = incompleteFields.owners;

    subIncomplete = subIncomplete.filter((f) => !f.includes(id));

    this.setState({
      contacts,
      incompleteFields: {
        ...this.state.incompleteFields,
        owners: subIncomplete,
      },
    });
  };

  pushPrimaryContact = (data, callback = false) => {
    const { contacts, incompleteFields } = this.state;
    const incompleteC = incompleteFields.owners;

    incompleteC.push(data.id);

    contacts.push(data);

    this.setState({ contacts, activeContact: data.id });

    if (callback) {
      return callback();
    }
  };

  toggleActiveContact = (id = false) => {
    if (
      id !== 'New Contact'
      && this.state.contacts.length === 1
      && this.state.contacts[0].isPrimary
    ) {
      id = this.state.contacts[0].id;
    }

    this.setState({ activeContact: id });
  };

  toggleActivePanel = (page) => {
    const toTrack = { owners: 'Principal', company: 'Company', bond: 'Bond' }[
      page
    ];
    Mixpanel.track(`Customer clicked to edit ${toTrack} information`);
    let id = false;

    if (
      this.state.contacts.length === 1
      && this.state.contacts[0].isPrimary
      && this.state.contacts[0].incompleteFields.length
    ) {
      id = this.state.contacts[0].id;
    }

    this.setState({
      activeStep: page,
      activeContact: id,
    });
  };

  updateFields = (updatedFields, category) => {
    const { fields, incompleteFields } = this.state;

    let subIncomplete = incompleteFields[category];

    subIncomplete = subIncomplete.filter(
      (f) => !Object.keys(updatedFields).includes(f),
    );

    // TODO: POST TO API WITH FINAL STEP TRUE

    this.setState(
      {
        fields: {
          ...fields,
          [category]: updatedFields,
        },
        incompleteFields: {
          ...incompleteFields,
          [category]: subIncomplete,
        },
      },
      () => {
        const activeStep = this.getActiveStep();

        this.setState({
          lead: {
            ...this.state.lead,
            Appstatus: { ...this.state.lead.Appstatus, bond: true },
          },
          activeStep,
        });
      },
    );
  };

  getActiveStep = () => {
    const { incompleteFields } = this.state;

    let { activeStep, hitVerify } = this.state;

    if (hitVerify) activeStep = 'verify';
    else if (incompleteFields.bond.length) activeStep = 'bond';
    else if (incompleteFields.company.length) activeStep = 'company';
    else activeStep = 'owners';

    return activeStep;
  };

  renderActiveStep = () => {
    switch (this.state.activeStep) {
      case 'bond':
        return (
          <BondForm
            fields={{ ...this.state.fields.bond }}
            customFields={this.state.customFields}
            bondInfo={this.state.bondInfo}
            incompleteFields={[...this.state.incompleteFields.bond]}
            updateFields={this.updateFields}
            submitted={this.state.lead.Appstatus.bond}
          />
        );

      case 'company':
        return (
          <CompanyForm
            fields={{ ...this.state.fields.company }}
            principalType={this.state.bondInfo.principalType}
            incompleteFields={[...this.state.incompleteFields.company]}
            updateFields={this.updateFields}
            submitted={this.state.lead.Appstatus.company}
          />
        );

      case 'owners':
        return (
          <ContactPage
            contacts={this.state.contacts}
            incompleteOwners={this.state.incompleteFields.owners}
            submitContact={this.submitContact}
            deleteContact={this.deleteContact}
            pushPrimaryContact={this.pushPrimaryContact}
            companyType={this.state.fields.company.companyType}
            toggleActiveContact={this.toggleActiveContact}
            activeContact={this.state.activeContact}
            addNewContact={this.addNewContact}
            saveContact={this.saveContact}
            moveToVerify={this.moveToVerify}
          />
        );
      case 'verify':
        return (
          <VerifyPage
            fields={this.state.fields}
            customFields={Object.keys(this.state.customFields).map((f) => ({
              ...this.state.customFields[f],
            }))}
            bondInfo={this.state.bondInfo}
            contacts={this.state.contacts}
            toggleActivePanel={this.toggleActivePanel}
            submitApplication={this.submitApplication}
          />
        );
      case 'ThankYou':
        return (
          <ThankYouPage
            fields={this.state.fields}
            customFields={Object.keys(this.state.customFields).map((f) => ({
              ...this.state.customFields[f],
            }))}
            bondInfo={this.state.bondInfo}
            contacts={this.state.contacts}
          />
        );

      default:
        break;
    }
  };

  createPanels = () => {
    const panels = [];
    if (!this.state.isLoaded) return panels;

    // Bond Panel
    const bondFields = this.state.fields.bond;
    // Show Effective Date & Amount after effectiveDate has been selected
    const bondDetails = [
      {
        name: null,
        value: `${toFullStateName(bondFields.state)} ${this.state.bondInfo.name
          }`,
      },
      {
        name: 'Effective Date',
        value:
          !!this.state.bondInfo.isV2 && !this.state.bondInfo.isGeneric && !this.state.incompleteFields.bond.includes('effectiveDate')
            ? moment(bondFields.effectiveDate).format('MM/DD/YYYY')
            : '',
      },
      {
        name: 'Bond Amount',
        value:
          bondFields.amount !== 0 || !!bondFields.amount
            ? toMoney(bondFields.amount)
            : '',
      },
    ];

    const bondPanel = {
      name: 'bond',
      title: 'Your Bond',
      disabled: false,
      icon: 'fa-clipboard',
      active: this.state.activeStep === 'bond',
      completed:
        this.state.incompleteFields.bond.length === 0
        && this.state.lead.Appstatus.bond,
      details: bondDetails,
    };

    const companyFields = this.state.fields.company;

    const companyDetails = [
      {
        name: null,
        value: `${companyFields.companyName}`,
      },
      {
        name: 'DBA',
        value: companyFields.companyDBA ? `${companyFields.companyDBA}` : null,
      },
      { name: null, value: `${companyFields.companyAddress}` },
      { name: null, value: `${companyFields.companyAddress2}` },
      {
        name: null,
        value: `${companyFields.companyCity
          && `${companyFields.companyCity},`} ${companyFields.companyState} ${companyFields.companyZip
          }`,
      },
    ];

    const companyPanel = {
      name: 'company',
      title: 'Your Company',
      disabled: !bondPanel.completed,
      icon: 'fa fa-building',
      active: this.state.activeStep === 'company',
      completed: this.state.incompleteFields.company.length === 0,
      details: companyDetails,
    };

    const ownerTitle = ['sole_proprietorship', 'individual'].includes(
      this.state.fields.company.companyType,
    )
      ? 'Your Information'
      : 'Owner Information';
    const ownerCompleted = this.state.contacts.length !== 0
      && this.state.contacts.filter((c) => c.incompleteFields.length > 0).length
      === 0
      && !!this.state.hitVerify;

    const ownerDetails = this.state.contacts.map((c) => {
      const hasName = c.fields['[name][first]'] && c.fields['[name][last]'];
      return {
        name: null,
        value: hasName
          ? `${c.fields['[name][first]']} ${c.fields['[name][last]']}`
          : 'Incomplete Information!',
        hasError: c.incompleteFields && c.incompleteFields.length > 0,
      };
    });
    const ownerPanel = {
      name: 'owners',
      icon: 'fa-users',
      disabled: companyPanel.disabled || !companyPanel.completed,
      active: this.state.activeStep === 'owners',
      title: ownerTitle,
      completed: ownerCompleted,
      details: ownerDetails,
    };

    return [
      bondPanel,
      companyPanel,
      ownerPanel,
      {
        name: 'verify',
        icon: 'fa-book-reader',
        title: 'Verify Your Information',
        details: [],
        disabled: false,
        completed: false,
        active: true,
      },
    ];
  };

  renderPanels = () => {
    const panels = {
      bond: {}, company: {}, owners: {}, verify: {},
    };

    if (!this.state.isLoaded) return panels;

    // render bond
    panels.bond.complete = !this.state.incompleteFields.bond.length
      && this.state.lead.Appstatus.bond;

    panels.bond.icon = panels.bond.complete ? 'fa-check' : 'fa-clipboard';

    panels.bond.active = this.state.activeStep.toLowerCase() === 'bond';
    panels.bond.title = 'Your Bond';
    panels.bond.disabled = false;

    // render company
    panels.company.complete = !this.state.incompleteFields.company.length;
    panels.company.icon = panels.company.complete ? 'fa-check' : 'fa-building';

    panels.company.active = this.state.activeStep.toLowerCase() === 'company';
    panels.company.title = 'Your Company';
    panels.company.disabled = !panels.bond.complete;

    // render Owners
    panels.owners.complete = this.state.contacts.length !== 0
      && this.state.contacts.filter((c) => c.incompleteFields.length > 0).length
      === 0;

    panels.owners.icon = panels.owners.complete ? 'fa-check' : 'fa-users';

    panels.owners.active = this.state.activeStep.toLowerCase() === 'owners';

    panels.owners.title = this.state.fields.company.companyType
      === ['sole-proprietorship', 'individual']
      ? 'Your Information'
      : 'Ownership Information';

    panels.owners.disabled = !(panels.bond.complete && panels.company.complete);

    // Render Verify
    panels.verify.icon = 'fa-check-square';
    panels.verify.title = 'Verify Your Information';

    return panels;
  };

  render() {
    const { activeStep } = this.state;
    const panels = this.createPanels();
    const activePanel = panels.filter((f) => f.name === activeStep)[0];
    return (
      <div
        className={`page-wrap ${activeStep === 'ThankYou' ? 'fancy-background' : ''
          }`}
      >
        {this.state.isLoaded ? (
          <>
            <div className='stuck-top'>
              <Header />
              <div
                className='container-fluid'
                style={{ backgroundColor: 'white' }}
              >
                <div className='container'>
                  <ProgressBar
                    hitVerify={this.state.hitVerify}
                    submitted={this.state.submitted}
                    panels={panels}
                  />
                </div>
              </div>
            </div>

            <div
              className='container main-container'
              style={{ paddingTop: '160px' }}
            >
              {activeStep !== 'ThankYou' ? (
                <>
                  <h2
                    style={{ fontSize: '16px', textAlign: 'center' }}
                    className='active-step'
                  >
                    {this.state.activeStep !== 'verify'
                      || (!this.state.hitVerify
                        && 'Fill out your information below and submit.')}
                  </h2>
                  <MediaQuery query='(min-width: 801px)'>
                    <div
                      className={`split-layout ${this.state.activeStep === 'verify'
                        ? 'is-verify'
                        : 'is-fill'
                        }`}
                    >
                      <ScrollTo
                        activePage={activeStep}
                        submitted={this.state.submitted}
                        type='desktop'
                      >
                        <MainPage
                          toggle={this.toggleActivePanel}
                          panelInfo={activePanel}
                          fields={this.renderActiveStep()}
                        />
                      </ScrollTo>

                      <SideBar>
                        <Panels
                          toggle={this.toggleActivePanel}
                          panels={this.createPanels()}
                        />
                      </SideBar>
                    </div>
                  </MediaQuery>
                  <MediaQuery query='(max-width: 800px)'>
                    <ScrollTo
                      type='mobile'
                      activePage={activeStep}
                      submitted={this.state.submitted}
                      activeContact={this.state.activeContact}
                    >
                      <AccordianWrapper
                        accordian={
                          <Accordian
                            activePanel={this.state.activeStep}
                            panels={this.createPanels()}
                            toggle={this.toggleActivePanel}
                            bond={
                              <BondForm
                                fields={{ ...this.state.fields.bond }}
                                customFields={this.state.customFields}
                                bondInfo={this.state.bondInfo}
                                incompleteFields={[
                                  ...this.state.incompleteFields.bond,
                                ]}
                                updateFields={this.updateFields}
                                submitted={this.state.lead.Appstatus.bond}
                              />
                            }
                            company={
                              <CompanyForm
                                fields={{ ...this.state.fields.company }}
                                principalType={
                                  this.state.bondInfo.principalType
                                }
                                incompleteFields={[
                                  ...this.state.incompleteFields.company,
                                ]}
                                updateFields={this.updateFields}
                                submitted={this.state.lead.Appstatus.company}
                              />
                            }
                            owners={
                              <ContactPage
                                contacts={this.state.contacts}
                                incompleteOwners={
                                  this.state.incompleteFields.owners
                                }
                                submitContact={this.submitContact}
                                deleteContact={this.deleteContact}
                                pushPrimaryContact={this.pushPrimaryContact}
                                companyType={
                                  this.state.fields.company.companyType
                                }
                                toggleActiveContact={this.toggleActiveContact}
                                activeContact={this.state.activeContact}
                                addNewContact={this.addNewContact}
                                saveContact={this.saveContact}
                                moveToVerify={this.moveToVerify}
                              />
                            }
                            verify={
                              <VerifyPage
                                fields={this.state.fields}
                                customFields={Object.keys(
                                  this.state.customFields,
                                ).map((f) => ({
                                  ...this.state.customFields[f],
                                }))}
                                bondInfo={this.state.bondInfo}
                                contacts={this.state.contacts}
                                toggleActivePanel={this.toggleActivePanel}
                                submitApplication={this.submitApplication}
                                isMobile={true}
                              />
                            }
                          />
                        }
                        activePage={this.state.activePage}
                      />

                      <TrustSeals />
                    </ScrollTo>
                  </MediaQuery>
                </>
              ) : (
                <ThankYouPage
                  fields={this.state.fields}
                  customFields={Object.keys(this.state.customFields).map((f) => ({
                    ...this.state.customFields[f],
                  }))}
                  bondInfo={this.state.bondInfo}
                  contacts={this.state.contacts}
                />
              )}
            </div>
            <Footer />
          </>
        ) : null}
      </div>
    );
  }
}

export default ExpressApp;
