import React from 'react';
import { Router } from '@reach/router';
import { StartAppLayout } from './Layout';
import { StartAppProvider } from './StartAppContext';
import { CreateLead } from './create-lead/CreateLead';
import { Affiliate } from './affiliate/Affiliate';
import { SelectBond } from './select-bond/SelectBond';

// Routing for StartApp
export default function StartApp(props) {
  const [state, updateState] = React.useState({ loading: true });
  return (
    <StartAppProvider value={{ state, updateState }}>
      <StartAppLayout affiliateName={state.affiliateName || false}>
        <Router>
          <SelectBond path="/:nonce"></SelectBond>
          <CreateLead path="/" />
          <Affiliate path="/partner/:partnerID" />
        </Router>
      </StartAppLayout>
    </StartAppProvider>
  );
}
