import React from 'react';
import styled from 'styled-components';
import { AltProgressBar } from './Components/ProgressBar';

import Header from './Components/Header';

const AffiliateHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3px 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px;
  background-color: white;
  align-items: center;
  min-height: 50px;
`;

export const AffiliateHeader = ({ name, imageUrl }) => (
  <AffiliateHeaderWrapper>
    <h6 style={{ textAlign: 'center', margin: '0px 5px 0px 0px' }}>IN PARTNERSHIP WITH</h6>
    {imageUrl ? (
       <div style={{ width: '150px', marginLeft: '5px' }}>
       <img src={imageUrl} alt={`${name} Logo`}/>
     </div>
    ) : (<h6 style={{ marginBottom: '0px', fontWeight: '600' }}>{name}</h6>)
    }

  </AffiliateHeaderWrapper>
);

export const SplitLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  > div {
    &:nth-child(1) {
      width: 55%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      flex-grow: 1;
      margin-left: 20px;
      max-width: 45%;
      @media (max-width: 800px) {
        width: 100%;
        max-width:100%;
        margin-left: 0;
      }
    }
  }
`;

export const Container = styled.div`
  max-width: 75rem;
  width: 100%;
`;

const ProgressBarWrapper = styled.div`
  height: 4rem;
  background-image: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0.266667em 1em 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
`;

export const Card = styled.div`
  /* position: relative; */
  background-color: ${(props) => props.color || '#fff'};
  border-radius: 8px;
  padding: 30px;
  width: ${(props) => props.width || 'auto'};
  flex-grow: 1;
  /* margin-right: 1rem; */
    @media (max-width: 800px) {
    margin: 0px;
    padding: 10px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-radius: 0px;
  }



  /* :first-of-type {
    margin-right: 0.5rem;
    margin-left: 1rem;
  } */
  /* box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
      0 18px 36px -18px rgba(0, 0, 0, 0.3),
      0 -12px 36px -8px rgba(0, 0, 0, 0.025); */

  /* @media (min-width: 880px) {
      padding: 50px;
  } */
`;

const CenteredWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckoutHeader = styled.h1`
  font-weight: 700;
  color: #074b71;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 1.75em;
  @media (max-width: 800px) {
          font-size: 1.35em;
          /* margin: 1em 0; */
      }
`;

const SubHeader = (props) => (
    <div className="mobile-subheader">
      <div className="mobile-title-wrapper">
        <div className="container mobile-title">
          <i className="fa fa-lock fa-sm fa-inverse" />
          {props.title || 'Bond Application'}
        </div>
      </div>
    </div>
);

export const TopBar = ({ progressBar, subHeader, title }) => (
  <>
    <Header />
    {!!subHeader && <SubHeader title={title} />}
    {!!progressBar && (
      <ProgressBarWrapper>
        <AltProgressBar steps={3} activeStep={1} />
      </ProgressBarWrapper>
    )}
  </>
);

function DesignGuide(props) {
  return (
    <>
      <TopBar />
      <CenteredWrapper>
        <Container>
          <CheckoutHeader>First, let's get some basic info</CheckoutHeader>
          <SplitLayout>
            <Card color={'#f3f6f9'} width="55%" />
            <Card color={'#f3f6f9'} />
          </SplitLayout>
        </Container>
      </CenteredWrapper>
    </>
  );
}

DesignGuide.propTypes = {};

export default DesignGuide;
