import { Router } from '@reach/router';
import produce from 'immer';
import React from 'react';
import Axios from 'axios';
import { animated, useTransition } from 'react-spring';
import { useInitApp, useLocation } from '../hooks';
import { TitleProvider } from '../titleContext';
import BondPage from './BondPage';
import { TitleBondLayout } from './Layout';
import PrincipalPage from './PrincipalPage';
import ConfirmLien from './ConfirmLien';
import ContactObligee from './ContactObligee';
import TitleBondStart from './TitleBondView';
import EndPage from './EndPage';
import NotFound from './NotFound';
import { Mixpanel } from '../mixpanel';

function reducer(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'SET_STATE_FROM_DB':
      return { ...state, ...action.payload };
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'MOVE_TO_PRINCIPAL':
      return { ...state, ...action.payload };
    case 'MOVE_TO_BOND':
      return produce(state, (draft) => {
        draft.step = action.payload.step;
        draft.fields.company = action.payload.fields;
      });
    case 'TOGGLE_SAVE':
      return produce(state, (draft) => {
        draft.saving = action.payload.save;
      });
    case 'SHOW_ERROR':
      return { ...state, hasError: true };
    case 'UPDATE_BOND_AMOUNT':
      return produce(state, (draft) => {
        draft.fields.bond = action.payload;
      });
  }
}

const TitleRouter = ({
  children, tracking, dispatch, loading,
}) => {
  const { location } = useLocation();

  const locationMap = {
    start: { track: 'Title Bonds - Start Application', step: 1 },
    contactObligee: { track: 'Title Bonds - Contact Obligee', step: 1 },
    confirmLien: { track: 'Title Bonds - Confirm Lien', step: 1.2 },
    principal: { track: 'Title Bonds - Principal Information', step: 1.7 },
    bond: { track: 'Title Bonds - Bond Information', step: 2 },
    end: { track: 'Title Bonds - End', step: 3 },
  };

  React.useEffect(() => {
    // Mixpanel Page Track
    if (!loading) {
      const page = locationMap[location.pathname.split('/').pop()];
      Mixpanel.track(page.track);

      // DataLayer page track
      window.dataLayer.push({
        event: 'TBEvent',
        eventCategory: 'TB Pageview',
        eventAction: page.track,
        ...tracking,
      });

      dispatch({ type: 'UPDATE_STATE', payload: { activeStep: page.step } });
    }
  }, [location]);

  const transitions = useTransition(location, (location) => location.key, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
    },
    leave: { opacity: 0, height: '0px' },
  });
  return transitions.map(({ item, props, key }) => (
      <animated.div style={{ ...props }}>
        <Router key={key} location={item} primary={false} >
          {children}
        </Router>
      </animated.div>
  ));
};

export default function TitleBonds(props) {
  // Initialize our App
  const [state, loading, dispatch] = useInitApp(`/title-bond/${props.nonce}/getState`, reducer, { activePage: false, saving: true, activeStep: 1 });

  // Our Add Log function
  const addLog = async function (comment, updateData = {}, showSave = true) {
    if (showSave) {
      dispatch({
        type: 'UPDATE_STATE',
        payload: {
          saving: true,
        },
      });
    }

    Axios.post(`/title-bond/${props.nonce}/addLog`, { ...comment, ...updateData }).then((response) => {
      if (showSave) {
        dispatch({
          type: 'UPDATE_STATE',
          payload: { saving: false, ...updateData },
        });
      } else {
        dispatch({
          type: 'UPDATE_STATE',
          payload: { ...updateData },
        });
      }
      return true;
    }).catch((error) => {
      console.log(error);
      dispatch({ type: 'SHOW_ERROR' });
    });
  };

  return (
    <>
      <TitleProvider value={{
        dispatch, state, addLog, Mixpanel,
      }}>
        <TitleBondLayout
          splitView
          saving={state.saving}
          hasError={state.hasError}
          activeStep={state.loading ? 1 : state.activeStep}
        >
          {loading ? (
            <div style={{ width: '55%', height: '600px' }} />
          ) : (
            <TitleRouter
              dispatch={dispatch}
              primary={false}
              loading={loading}
              tracking={{
                label: `${state.bondState} - ${state.bondType}`,
                eventValue: state.leadId,
                ecData: {
                  email: state.email,
                  phone_number: state.phone,
                }
              }}
            >
              <TitleBondStart path='start' />
              <ContactObligee path='contactObligee' />
              <ConfirmLien path='confirmLien' />
              <PrincipalPage path='principal' />
              <BondPage path='bond' />
              <EndPage path='end' />
              <NotFound default />
            </TitleRouter>
          )}
        </TitleBondLayout>
      </TitleProvider>
    </>
  );
}
