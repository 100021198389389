import * as Yup from "yup";
import moment from "moment";
export const ZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
export const poBoxRegex =
  /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;
export const numbersOnly = /^[0-9]+$/;
export const MMYYRegex = /(0[1-9]|1[0-2])\/[0-9]{2}/;
const phoneRegex =
  /^(.)\1{9}|^(..)\2{4}|^((.)..)\3{2}\4|(1234567890|0123456789|0987654321|9876543210)|^(555)|^(0)|^(1)|^(.11)/;

function isValidPhoneNumber(string) {
  return phoneRegex.test(string);
}

// This removes any non number characters
export function parseStringToNumbersOnly(value) {
  const parsedVal = value.replace(/\D/g, "");
  return parsedVal;
}

Yup.addMethod(Yup.date, 'format', function () {
  return this.transform((value, originalValue) => {
    if (this.isType(value)) return value;
    const isValid = moment(originalValue, 'MM-DD-YYYY').isValid();
    return isValid ? moment(originalValue, 'MM-DD-YYYY').toDate() : new Date('');
  }).typeError('Please enter a valid date formatted MM-DD-YYYY');
});

export const getEffectiveDateValidator = () => {
  const max = moment().add(90, 'days')
  const min = moment().subtract(30, 'days')

  return Yup.date().format().max(max, `Please enter a date before ${max.format('MM-DD-YYYY')}`).min(min, `Please enter a date after ${min.format('MM-DD-YYYY')}`).required('Effective Date is required')
}


// Address Fields
export const addressValidators = {
  address: Yup.string()
    .trim()
    .required("Street Name is required")
    .test(
      "PObox",
      "Sorry, we cannot ship to a PO Box",
      (value) => !poBoxRegex.test(value)
    ),
  address2: Yup.string()
    .trim()
    .test(
      "PObox",
      "Sorry, we cannot ship to a PO Box",
      (value) => !poBoxRegex.test(value)
    ),
  city: Yup.string().trim().required("City is required"),
  state: Yup.string().trim().required("State is required"),
  zip: Yup.string()
    .trim()
    .required("Zip Code is required")
    .matches(ZipCodeRegex, "Please enter a valid zip code"),
};

export const contactValidators = {
  name: Yup.object().shape({
    first: Yup.string().trim().required(),
    last: Yup.string().trim().required(),
  }),
  ssn: Yup.string()
    .transform(parseStringToNumbersOnly)
    .length(9, "Must be a valid 9 digit SSN")
    .required(),
  phone: Yup.string()
    .transform(parseStringToNumbersOnly)
    .min(10, "Please enter a valid 10 digit U.S Phone Number")
    .test(
      "Not a valid phone number",
      "Not a valid phone number",
      (value) => !isValidPhoneNumber(value)
    )
    .required(),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email Address")
    .required(),
  address: addressValidators["address"],
  address2: addressValidators["address2"],
  city: addressValidators["city"],
  state: addressValidators["state"],
  zipcode: addressValidators["zip"],
  home_type: Yup.string().oneOf(["own", "rent"]).required(),
  dob: Yup.date().format().required('Date of Birth is required').max(moment(), 'Please enter a valid Date of Birth').min(moment().set('year', '1900'), 'Please enter a valid Date of Birth'),
};

export const principalValidators = {
  company_name: Yup.string().required(),
  company_has_dba: Yup.boolean().nullable(),
  company_dba: Yup.string().when(
    "company_has_dba",
    ([company_has_dba], schema) => {
      return company_has_dba ? schema.required() : schema.notRequired();
    }
  ),
  company_address: addressValidators["address"],
  company_address2: addressValidators["address2"],
  company_city: addressValidators["city"],
  company_state: addressValidators["state"],
  company_zip: addressValidators["zip"],
};

export const licenseNumber = Yup.string()
  .trim()
  .required("License Number is required")
  .test(
    "license-number",
    "Please enter a valid 8 or 13 digit license number (not including padded zeros)",
    (value) => {
      const withoutPaddedZeros = value?.replace(/^0+/, "");
      return (
        withoutPaddedZeros?.length == 8 || withoutPaddedZeros?.length == 13
      );
    }
  );

// Credit Card Fields
export const creditCardValidators = {
  cardNumber: Yup.string()
    .required("Credit Card Number is required")
    .matches(numbersOnly, "Please only enter numbers")
    .min(13, "Credit card numbers are at least 13 digits long")
    .max(19, "Credit card numbers are not longer than 19 digits"),
  cardCode: Yup.string()
    .trim()
    .required("Credit Card CVV is required")
    .matches(numbersOnly, "Code must be numbers only"),
  cardExpYear: Yup.string().trim().required("Please select a valid year"),
  cardExpMonth: Yup.string().trim().required("Please select a valid month"),
};
