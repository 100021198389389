export function getRequiresShipping(lead) {
  const { company } = lead.selectedUnderwriter;
  const bondRenewalCode = (
    lead.bond
    && lead.bond.application
    && lead.bond.application.suretyBond
    && lead.bond.application.suretyBond.renewalCode
  ) || null;

  if (
    lead.prevPolicy
    && bondRenewalCode
    && ['B', 'C', 'F'].includes(bondRenewalCode.toUpperCase())
    && lead.prevPolicy.company === company
  ) {
    return false;
  } if (lead.selectedShipping && ['emailed', 'no shipping required'].includes(lead.selectedShipping.toLowerCase())) {
    return false;
  }
  return true;
}

export function formatAddressForLog(address) {
  let returnAddress = '';
  if (address.name) {
    returnAddress += `${address.name.first} ${address.name.last ? address.name.last : ''}<br>`;
  }
  returnAddress += `${address.address}<br>`;
  if (address.address2) {
    returnAddress += `${address.address2}<br>`;
  }
  returnAddress += `${address.city}, ${address.state} ${address.zipcode}`;
  return returnAddress;
}

export function isTitleBond(bond) {
  return (
    bond.version === 'v2'
    && bond.categories.filter((c) => c.name.toUpperCase() === 'VEHICLE TITLE')
      .length > 0
  );
}

export function getSelectedMailingAddress(lead) {
  return (
    lead.selectedMailingAddress
    && lead.contacts.filter(
      (c) => c._id.toString() === lead.selectedMailingAddress,
    )[0]
  );
}

export function isMYDEligible(lead) {

  const selectedQuote = lead.selectedUnderwriter;
  // v1 bonds cannot be eligible
  if (lead.bond.version === 'v1') return false;
  // checks for instant purchase eligibility
  if (selectedQuote.mydAvailable && selectedQuote.mydAvailable === true) return true;
  // checks for underwritten elibility
  else if (selectedQuote?.multiTerm?.length >= 1 && selectedQuote?.status === 'appr-multiterm') return true
  // otherwise not eligible
  return false
}

export function isInstantPurchase(lead) {
  const { suretyBond } = lead.bond.application;
  const isIP = lead && lead.app && lead.app.type === 'instant-issue'
    && !lead.claimedBy
    && !isTitleBond(suretyBond);
  return isIP;
}

export function getPrincipalName(lead) {
  let name = lead.company_name;
  if (lead.company_dba) name += ` DBA ${lead.company_dba}`;
  return name;
}

export function getPrincipalAddress(lead) {
  return {
    address: lead.company_address,
    address2: lead.company_address2,
    city: lead.company_city,
    state: lead.company_state,
    zipcode: lead.company_zip,
    name: { first: getPrincipalName(lead) },
  };
}

export function getShippingPrice(method) {
  switch (method) {
    case 'Express':
      return 10.00;
    case '2 Day Air':
      return 15.00;
    case 'Overnight':
      return 20.00;
    default:
      return null;
  }
}

export function getTrackingPremium(invoice) {
  const premium = invoice?.charges?.find(
    (c) => ['BOND PREMIUM', 'DOWN PAYMENT'].includes(c.category.toUpperCase())
  )?.amount || 'N/A';

  return premium;
}
