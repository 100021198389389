import React from "react";
import { useField } from "formik";
import {
  getSelectedMailingAddress,
  getPrincipalAddress,
} from "../../utils/business-logic";
import { useLead } from "../../client";
import { AddressCards } from "./AddressCards";

export function ShippingAddress({activeAddress, setActiveAddress}) {
  const { lead } = useLead();
  const selectedMailingAddress = getSelectedMailingAddress(lead);
  const principalAddress = getPrincipalAddress(lead);
  const [,,helpers] = useField("selectedMailingAddress");
  // Watch for selectedMailingAddress
  React.useEffect(() => {
    if (lead.selectedMailingAddress) {
      helpers.setValue(lead.selectedMailingAddress);
      setActiveAddress({ address: false, viewMode: false, canEdit: false });
    }
  }, [lead.selectedMailingAddress]);

  React.useEffect(() => {
    if (!activeAddress.address) {
      setActiveAddress({ address: false, viewMode: false, canEdit: false });
    }
  }, [activeAddress.address]);

  return (
    <>
      <h5
        className="panel-title"
        style={{ position: "relative", marginBottom: "8px" }}
      >
        Select Shipping Address
      </h5>
      <p style={{ fontSize: "14px", marginBottom: "1rem", marginTop: "-5px" }}>
        <em>Confidential documents will be sent to this address.</em>
      </p>
      <AddressCards selectedMailingAddress={selectedMailingAddress} principalAddress={principalAddress} setActiveAddress={setActiveAddress}></AddressCards>
    </>
  );
}
