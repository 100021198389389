import React from 'react';

export const Loading = ({ isLoading, children }) => {
  if (isLoading) {
    if(!children) {
      return (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          display: 'block',
          transform: 'translate(-50%, -50%)',
          fontSize: '2rem',
        }}>
          <i className="fa fa-spinner fa-spin" />
        </div>
      )
    }
    return (
      <div style={{ position: 'relative' }}>
        {children}
        <div style={{
          position: 'absolute',
          background: 'rgb(249,249,250)',
          width: '100%',
          height: '100%',
          top: '0',
          opacity: '0.5',
        }}/>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          display: 'block',
          transform: 'translate(-50%, -50%)',
          fontSize: '2rem',
        }}>
          <i className="fa fa-spinner fa-spin" />
        </div>
      </div>
    );
  }
  return <>{children}</>;
};