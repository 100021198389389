import React from 'react';
import Axios from 'axios';
import {useField} from "formik"
import {useParams} from "@reach/router"
import { Checkbox, ErrorMessage } from "../../common/forms/fields"
import { trackAgreeToTerms } from '../tracking';

export function TermsAndConditions(props) {
  const {nonce} = useParams()
  const [field] = useField("agreeToTerms");
  const handleChange = (e) => {
    trackAgreeToTerms(e.target.checked);
    if (e.target.checked) {
      Axios.post(`/purchase-bond/${nonce}/log-terms-agreement`);
    }
    field.onChange(e)
  };
  return (
    <div className='purchase-section'>
      <ErrorMessage name={field.name}></ErrorMessage>
      <Checkbox changeHandler={handleChange} name="agreeToTerms" />
      <span style={{ display: 'block', paddingLeft: '25px' }}>
        I agree to all <a href={`/purchase-bond/${nonce}/terms`} target="_blank">terms and conditions</a> and acknowledge that once my purchase is completed, all first-term bonds are considered issued and non-refundable.
      </span>
    </div>
  );
}

