import React from 'react';
import { TopBar } from '../DesignGuide';
import Footer from '../Components/Footer';

const Layout = ({ children }) => (
  <>
  <TopBar/>
  {children}
  <Footer/>
  </>
);

export default Layout;
