import React from 'react';
import Axios from 'axios';

import ContactForm from './ContactForm';
import ContactHub from './ContactHub';

import { Mixpanel } from '../mixpanel';

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.contacts.length === 0) {
      Axios.get(
        `${
          window.location.pathname
        }/principal?timestamp=${new Date().getTime()}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      ).then((res) => {
        const contact = res.data;

        this.props.pushPrimaryContact(contact, () => this.setState({
          isLoading: false,
        }));

        // TODO: Update Global STATE WITH NEW CONTACT
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.contacts.length == 1 && this.props.incompleteOwners) {
  //     this.setState({
  //       openForm: true,
  //       activeContact: this.props.contacts[0]
  //     });
  //   }
  // }

  editContact = (id) => {
    Mixpanel.track('Edit Principal');
    this.props.toggleActiveContact(id);
  };

  toggleNewContact = () => {
    this.props.toggleActiveContact('New Contact');
  };

  render() {
    const { contacts } = this.props;
    const activeContact = contacts.filter(
      (c) => c.id.toString() === this.props.activeContact.toString(),
    )[0];
    return (
      <>
        {this.state.isLoading ? (
          <div>loading...</div>
        ) : (
          <>
            {this.props.activeContact ? (
              <ContactForm
                contact={activeContact}
                submitContact={this.props.submitContact}
                pushPrimaryContact={this.props.pushPrimaryContact}
                saveContact={this.props.saveContact}
              />
            ) : (
              <ContactHub
                contacts={contacts}
                toggleActiveContact={this.props.toggleActiveContact}
                editContact={this.editContact}
                deleteContact={this.props.deleteContact}
                toggleNewContact={this.toggleNewContact}
                addNewContact={this.props.addNewContact}
                companyType={this.props.companyType}
                moveToVerify={this.props.moveToVerify}
              />
            )}
          </>
        )}
      </>
    );
  }
}
