import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { toFullStateName, toMoney } from '../../helpers';

const BondInfo = styled.div`
display:flex;
flex-direction:row;
padding-top:15px;
color:#444;
p,h5 {
  color:#444;
  margin:0px;
}
h5{
  font-weight:400;
  font-size: 20px;
}
`;

const BondImage = styled.img`
  display:block;
  position:relative;
  width:120px;
  height:120px;
  margin-right:20px;
`;

const Charges = styled.div`
  padding: 15px 15px;
  border-bottom:1px solid rgba(175, 175, 175, 0.34);
`;
const ChargeCategory = styled.span`
  color:#0d8bd1;

`;
const ChargeAmount = styled.span`
  float:right;
`;

const Total = styled.div`
  font-size:18px;
  font-weight: 800;
  padding:10px 10px 0 10px;
`;
const Wrapper = styled.div`
box-sizing:border-box;
background-color:#f9f9fa;
border-radius: 5px;
padding: 15px;
padding-top:0;
width: 100%;
padding-bottom:20px;
max-height:40px;
${(props) => props.shown && css`
  max-height:1000px;
`};
overflow:hidden;
transition:max-height .3s ease-in-out;
display:none;
div > p {
  margin:0;
  padding:0;
}
@media(max-width:799px){
  display:block
}
`;
const Carat = styled.button`
  height:40px;
  background:none;
  border:none;
  color:#444;
  padding:0;
  transition: transform 0.3s linear;
  transform:rotate(0deg);
  ${(props) => props.shown && css`
    transform:rotate(180deg);
  `};
  :hover{
    padding:0;
    color:#444;
    background:none;
    border:none;
  }
  :focus{
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    outline:none;
  }
`;

const BondSummary = (props) => {
  const [shown, setShown] = useState(false);

  return (
    <Wrapper shown={shown}>
    <div style={{ height: '40px', lineHeight: '40px', fontSize: '14px' }}>
      <p style={{ float: 'left' }}>{shown ? 'Hide Bond Summary' : 'Show Bond Summary'} <Carat shown={shown} onClick={() => setShown(!shown)}><i className="fa fa-chevron-up" /></Carat></p>
      <p style={{ float: 'right' }}> Total: {toMoney(props.rightBox.balance, { noCents: false })}</p>
    </div>
    <br />
    <div className="checkout-panel-heading">
      <div>
        <BondInfo>
          <BondImage src='/img/bond-mock.png' alt='bond-img'></BondImage>
          <div>
            <p>{toMoney(props.rightBox.bond.amount, { noCents: false })}</p>
            <h5>{toFullStateName(props.rightBox.bond.state)}</h5>
            <p>{props.rightBox.bond.name}</p>
          </div>
        </BondInfo>
        <Charges>
          {props.rightBox.charges.map((charge, i) => <div key={i}>
                <ChargeCategory key={`cat${i}`}>{charge.category}</ChargeCategory>
                <ChargeAmount key={`amount${i}`}>{toMoney(charge.amount, { noCents: false })}</ChargeAmount>
              </div>)}
        </Charges>
        {/* id attr required for E2E tests */}
        <Total id='order-total'>
          <ChargeCategory>ORDER TOTAL</ChargeCategory>
          <ChargeAmount>{toMoney(props.rightBox.balance, { noCents: false })}</ChargeAmount>
        </Total>
      </div>
    </div>
  </Wrapper>
  );
};

BondSummary.propTypes = {

};

export default BondSummary;
