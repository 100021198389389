import { Formik, Form } from 'formik';
import React from 'react';
import moment from 'moment';
import {
  FormField,
  AddressFields,
  RadioCard,
  ErrorMessage,
} from '../common/forms/fields';
import { Loading } from '../purchase-bond/components/Loading';
import { contactValidators, getEffectiveDateValidator } from '../utils/validators';
import { LICENSE_NUMBER_FETCH_FAIL_ATTEMPTS, useLicenseInfo } from './hooks';
import * as Yup from 'yup';
import { PhoneInput, SSNInput, DateInput } from '../common/forms/maskedFields';
import { FormLabel } from '../ExpressAppUI';
import { useLead } from '../client';
import { useNavigate, useParams } from '@reach/router';

const BLANK_CONTACT = {
  name: {
    first: '',
    last: '',
  },
  type: 'owner',
  dob: '',
  ssn: '',
  phone: '',
  email: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  home_type: '',
};

function LicenseNumber(props) {
  const { licenseData, isLoading, failureCount, error, isFetched } = useLicenseInfo();
  const { licenseNumber} = useParams();
  const contacts = licenseData?.lead?.contacts || [];
  const navigate = useNavigate();
  if (failureCount >= LICENSE_NUMBER_FETCH_FAIL_ATTEMPTS) {
    navigate(`../license`, { state: { initialValues: { licenseNumber } } });
  }
  return (
    <Loading isLoading={isLoading}>
      {error && failureCount >= LICENSE_NUMBER_FETCH_FAIL_ATTEMPTS && <div>We've encountered an error. Please try again</div>}
      {!error && isFetched && <IndemnityForm contacts={contacts} />}
    </Loading>
  );
}

function IndemnityForm({ contacts }) {
 
  const [selectedContact, setSelectedContact] = React.useState(() => {
    if (contacts.length === 1) return contacts[0];
    else if (contacts.length > 1) return null;
    else return BLANK_CONTACT;
  });
  const allowOverride = (new URL(document.location)).searchParams.get('overrideOwner') === 'true'


  return (
    <>
      <DisabledLicenseFields  />

      <div style={{ display: 'flex', alignItems: 'center', marginTop: '40px' }}>
        <h5 style={{ margin: 0 }}>
          <i
            style={{ color: '#ffca28', marginRight: '10px' }}
            className="fa fa-exclamation"
          />
          Owner Information

        </h5>
      </div>
      <hr />

      {selectedContact ? (
        <EditContactForm allowOverride={allowOverride} selectedContact={selectedContact} />
      ) : (
        <SelectContactForm
          contacts={contacts}
          setSelectedContact={setSelectedContact}
        />
      )}
    </>
  );
}

function SelectContactForm(
  { contacts, setSelectedContact}
) {
  return (
    <>
      <p>
        We’ve found multiple contacts associated with your CSLB number. Please
        select yourself.
      </p>
      <div style={{ maxHeight: '200px', overflow: 'scroll', padding: '5px' }}>
        {contacts
          .sort((a, b) => b.fico - a.fico)
          .map((contact, i) => {
              return (
                <div
                  key={contact?.name?.first + contact?.name?.last + i}
                  onClick={() => setSelectedContact(contact)}
                  style={{
                    border: '.5px solid gray',
                    borderRadius: '.3rem',
                    padding: '10px',
                    margin: '.5rem 0',
                    cursor: 'pointer',
                    fontWeight: '500',
                  }}
                >
                  <div>
                    {contact.name.first} {contact.name.last}
                  </div>
                </div>
              );
          })}
      </div>
    </>
  );
}

function EditContactForm({ selectedContact = {}, isLoading, allowOverride }) {
  const { licenseData } = useLicenseInfo();
  const { lead } = useLead();
  const { licenseNumber } = useParams();
  const [didUserConsent, setDidUserConsent] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState(false)

  const navigate = useNavigate();
  const licenseTypeCustomField = lead?.customFields.find(
    (f) => f.field === 'licenseType',
  );

  const initialValues = {
    ...BLANK_CONTACT,
    ...selectedContact,
    effectiveDate: !!lead?.bond?.application?.effectiveDate
      ? moment(lead.bond.application.effectiveDate).format('MM-DD-YYYY')
      : '',
    licenseType: getLicenseType(licenseData.classCodes, licenseTypeCustomField),
  };

  const validationSchema = Yup.object().shape({
    ...contactValidators,
    effectiveDate: getEffectiveDateValidator()
  });

  return (
    <>
      {isLoading ? (
        <>loading...</>
      ) : (
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (values, formikBag) => {
            if(formikBag.isSubmitting) {
              return
            }
            setSubmissionError(false)
            const { licenseType, effectiveDate, ...contactValues } = values;
            const { contacts, ...principalInfo } = licenseData.lead;

            const body = {
              principalInfo,
              contact: contactValues,
              licenseNumber,
              licenseType,
              effectiveDate
            };
            try {
              const res = await window.fetch(`submit-for-quote`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              await res.json()
              setSubmissionError(false)
              navigate('../processing');
            } catch (e) {
              setSubmissionError(true)
            }
          }}
        >
          {(props) => {
            const { setFieldValue, isSubmitting } = props;


            return (
              <Form>
                <div
                  style={{
                    position: 'relative',
                    marginTop: '.85rem',
                  }}
                >
                  <FormField
                    as={DateInput}
                    label="Effective Date"
                    name="effectiveDate"
                    subLabel="When should your bond start?"
                  />
                  <button
                    type="button"
                    style={{ position: 'absolute', top: '-3px', right: '12px' }}
                    className="button-effective-today"
                    onClick={() =>
                      setFieldValue(
                        'effectiveDate',
                        moment().format('MM-DD-YYYY'),
                      )
                    }
                  >
                    Effective Today
                  </button>
                </div>
                <FormField
                  disabled={allowOverride ? false : selectedContact?.name?.first}
                  label="First Name"
                  name="name.first"
                />
                <FormField
                  disabled={allowOverride ? false :selectedContact?.name?.last}
                  label="Last Name"
                  name="name.last"
                />
                <FormField as={PhoneInput} label="Phone" name="phone" />
                <FormField
                  label="Email"
                  subLabel={
                    'Confidential documents will be sent to this address.'
                  }
                  name="email"
                />
                <FormField
                  as={SSNInput}
                  subLabel={
                    <>
                      <i
                        className="fa fa-xs fa-lock"
                        style={{ marginLeft: '5px' }}
                      />
                      {'   '}
                      Stored securely, required for soft credit review.
                    </>
                  }
                  label="Social Security"
                  name="ssn"
                />
                <FormField as={DateInput} label="Date of Birth" name="dob" />

                <AddressFields
                  label="Address"
                  address="address"
                  address2="address2"
                  zip="zipcode"
                  state="state"
                  city="city"
                />

                <FormLabel htmlFor="home_type">
                  Do you own or rent your home?
                </FormLabel>
                <ErrorMessage name="home_type"></ErrorMessage>
                <RadioCard label="Rent" value="rent" name="home_type">
                  Rent
                </RadioCard>
                <RadioCard label="Own" value="own" name="home_type">
                  Own
                </RadioCard>
                {/* 
                 */}
                <label
                  style={{
                    display: 'flex',
                    columnGap: '.5rem',
                    boxShadow: 'rgb(0 0 0 / 8%) 0.1rem 0.2rem 0.2rem',
                    padding: '15px',
                    margin: '1rem 0',
                    borderRadius: '4px',
                    // bg-green-100 or bg-yellow-50
                    backgroundColor: didUserConsent ? '#dcfce7' : '#fefce8',
                    // text-green-700 or text-yellow-800
                    color: didUserConsent ? '#15803d' : '#854d0e',
                  }}
                >
                  <input
                    type="checkbox"
                    name="credit-check-consent"
                    required
                    onChange={(e) => setDidUserConsent(e.target.checked)}
                  />
                  <span style={{ marginLeft: '5px' }}>
                    I understand that any person who knowingly presents false
                    information in an application for insurance is guilty of a
                    crime. I authorize SuretyBonds.com to use all submitted
                    information, including consumer credit reports, to determine
                    eligibility for bonding.
                  </span>
                </label>
                <button
                  type="submit"
                  disabled={!didUserConsent || isSubmitting}
                  style={{
                    cursor: !didUserConsent && 'no-drop',
                  }}
                >
                  submit
                </button>
                {submissionError && (
                   <p style={{color: 'crimson'}}>
                    There was a problem, please reload the page and try again.
                    <br/>
                    If you continue to encounter errors please contact our customer care team at <a href="tel:+18003084358">1-800-308-4358</a> or <a href="mailto:customercare@suretybonds.com">customercare@suretybonds.com</a>
                 </p>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}

function getLicenseType(classCodesResponse, licenseTypeCustomField) {
  let matchedCode;
  for (let index = 0; index < classCodesResponse.length; index++) {
    const { codeId, codeDescription } = classCodesResponse[index];
    const match = licenseTypeCustomField.optionList.find((option) =>
      option.startsWith(`${codeId} -`),
    );
    if (match) {
      matchedCode = match;
      break;
    }
    if (index === classCodesResponse.length - 1) {
      if (!matchedCode) {
        matchedCode = `${codeId} - ${codeDescription}`;
      }
    }
  }

  return matchedCode;
}

function DisabledLicenseFields() {
  const { licenseData } = useLicenseInfo();
  const { lead } = useLead();
  const licenseTypeCustomField = lead?.customFields.find(
    (f) => f.field == 'licenseType',
  );
  if (licenseData)
    return (
      <>
        <h5>
          <i
            style={{ color: 'rgb(116, 219, 102)', marginRight: '10px' }}
            className="fa fa-check"
          />
          Business information
        </h5>
        <hr />

        <p>
          Your business information was pulled directly from the CSLB database.
          If any information displayed is incorrect at this time, contact the
          CSLB directly to update it.
        </p>

        <div
          style={{
            border: '.5px solid gray',
            borderRadius: '.3rem',
            padding: '10px',
          }}
        >
          <div>
            {licenseData.lead.company_name}{' '}
            {licenseData.lead.company_dba
              ? ` DBA: ${licenseData.lead.company_dba}`
              : ''}
          </div>
          <div>{licenseData.lead.company_address}</div>
          {licenseData.lead.company_address2 && (
            <div>{licenseData.lead.company_address2}</div>
          )}
          <div>
            {licenseData.lead.company_city}, {licenseData.lead.company_state}{' '}
            {licenseData.lead.company_zip}
          </div>
          <div>
            License Type:{' '}
            {getLicenseType(licenseData.classCodes, licenseTypeCustomField)}
          </div>
        </div>
      </>
    );

  return null;
}

export default LicenseNumber;
