import React from 'react';
import { useRifm } from 'rifm';
import { useField } from 'formik';
import { FormInput } from '../../ExpressAppUI';

/**
 * @typedef {function(string): string} StringProcessor
 */
/**
 * Renders a <FormInput /> component with a mask
 * @param {object} props
 * @param {StringProcessor}  props.format - a formatter function. see {@link https://github.com/realadvisor/rifm#input-props Rifm docs}.
 * @param {StringProcessor}  props.addMask - a function that adds a mask. see {@link https://github.com/realadvisor/rifm#input-props Rifm docs}.
 * @param {string}  props.placeholder - placeholder text to show when input value is empty
 * @param {StringProcessor}  props.mutateValue - optional function that will mutate the value before setting into form state
 * @param {string} props.name - name of the input as recognized by formik
 */
function MaskedInput({ format, addMask, placeholder, mutateValue, name, ...props }) {
  
  const [field, meta, helpers] = useField(name);
  const rifm = useRifm({
    mask: true,
    value: mutateValue ? mutateValue(field.value) : field.value,
    onChange: (value) =>
      helpers.setValue(mutateValue ? mutateValue(value) : value),
    format,
    replace: addMask,
  });
  return (
    <FormInput
      {...props}
      name={name}
      placeholder={placeholder}
      value={rifm.value}
      onChange={rifm.onChange}
    ></FormInput>
  );
}

/**
 * Renders a <FormInput /> component with a dollar sign and enforces numbers only
 * with comma seprator mask -- must be hooked into Formik
 * @param  props - props passed to the FormInput
 * @param  props.name - the name of the input as its called in the formik instance
 */
export function MoneyInput(props) {
  const [field, meta, helpers] = useField(props.name);
  const rifm = useRifm({
    format: formatters.money,
    accept: /\d/g,
    value: parseDigits(field.value),
    onChange: (value) => helpers.setValue(parseDigits(value)),
  });
  return (
    <div className="input-group">
      <div className="input-group-addon">$</div>
      <FormInput
        {...props}
        onChange={rifm.onChange}
        value={rifm.value}
      ></FormInput>
    </div>
  );
}

/**
 * Renders a <FormInput /> component with mm/dd/yyyyy mask -- must be hooked into Formik
 * @param  props - props passed to the FormInput
 * @param  props.name - the name of the input as its called in the formik instance
 */
export function DateInput(props) {
  return (
    <MaskedInput
      format={formatters.date}
      addMask={masks.date}
      placeholder="xx-xx-xxxx"
      {...props}
    />
  );
}

/**
 * Renders a <FormInput /> component with (xxx) xxx-xxxx mask -- must be hooked into Formik
 * @param  props - props passed to the FormInput
 * @param  props.name - the name of the input as its called in the formik instance
 */
export function PhoneInput(props) {
  return (
    <MaskedInput
      format={formatters.phone}
      addMask={masks.phone}
      placeholder="(xxx) xxx-xxxx"
      mutateValue={(val) => parseDigits(val)}
      {...props}
    />
  );
}

/**
 * Renders a <FormInput /> component with xxx-xx-xxxx mask -- must be hooked into Formik
 * @param  props - props passed to the FormInput
 * @param  props.name - the name of the input as its called in the formik instance
 */
export function SSNInput(props) {
  return (
    <MaskedInput
      format={formatters.ssn}
      addMask={masks.ssn}
      placeholder="xxx-xx-xxxx"
      mutateValue={(val) => parseDigits(val)}
      {...props}
    />
  );
}

// Takes a string and returns a string of only the number chars
function parseDigits(string) {
  if (!string) return '';
  return (string?.match(/\d+/g) || []).join('') || '';
}

/**
 * Formatters for Masked Inputs see {@link https://github.com/realadvisor/rifm#input-props Rfim Docs}
 */
const formatters = {
  phone: (string) => {
    const digits = parseDigits(string);
    const chars = digits.split('');
    return chars
      .reduce((r, v, index) => {
        if (index === 0) return `(${r}${v}`;
        if (index === 3) return `) ${r}${v}`;
        if (index === 6) return `${r}-${v}`;
        if (index === 10) return ` x${r}${v}`;
        return `${r}${v}`;
      }, '')
      .substr(0, 21);
  },
  money: (string) => {
    const parsed = parseDigits(string);
    const number = Number.parseInt(parsed, 10);
    if (Number.isNaN(number)) {
      return '';
    }
    return number.toLocaleString('en');
  },
  ssn: (string) => {
    const digits = parseDigits(string);
    const chars = digits.split('');
    return chars
      .reduce((r, v, index) => {
        if (index === 3) return `${r}-${v}`;
        if (index === 5) return `${r}-${v}`;
        return `${r}${v}`;
      }, '')
      .substr(0, 11);
  },
  date: (string) => {
    const digits = parseDigits(string);
    const chars = digits.split('');
    const test = chars
      .reduce(
        (r, v, index) =>
          index === 2 || index === 4 ? `${r}-${v}` : `${r}${v}`,
        '',
      )
      .substr(0, 10);
    return test;
  },
};

/**
 * Masks (Replace) for Masked Inputs see {@link https://github.com/realadvisor/rifm#input-props Rfim Docs}
 */
const masks = {
  phone: (string) => {
    if (!string) return '';
    const digits = parseDigits(string);
    const area = digits.slice(0, 3).padEnd(3, '_');
    const first3 = digits.slice(3, 6).padEnd(3, '_');
    const last4 = digits.slice(6, 10).padEnd(4, '_');
    const ext = digits.slice(10, 15).padEnd(5, '_');
    return `(${area}) ${first3}-${last4} x${ext}`;
  },
  date: (string) => {
    const digits = parseDigits(string);
    if (digits.length === 0) return '';
    const days = digits.slice(0, 2).padEnd(2, '_');
    const months = digits.slice(2, 4).padEnd(2, '_');
    const years = digits.slice(4, 8).padEnd(4, '_');
    return `${days}-${months}-${years}`;
  },
  ssn: (string) => {
    const digits = parseDigits(string);
    const chars = digits.split('');
    return chars
      .reduce((r, v, index) => {
        if (index === 3) return `${r}-${v}`;
        if (index === 5) return `${r}-${v}`;
        return `${r}${v}`;
      }, '')
      .substr(0, 12);
  },
};
