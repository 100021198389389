import React from 'react';

import moment from 'moment';

import MaskedInput from 'react-text-mask';
import { FormInput, FormLabel, FormSelect } from '../../ExpressAppUI';

import { SectionHeader } from './Styles';

import { toFullStateName } from '../../helpers';
// Custom Field Input
const CustomFieldInput = ({
  field, values, handleChange, ...props
}) => {
  const fieldName = `customFields[${field.field}]`;
  if (field.type.toLowerCase() === 'date') {
    return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}

        <MaskedInput
          className={`form-input ${
            props.hasError(fieldName) ? 'has-error' : ''
          }`}
          name={fieldName}
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          placeholder="MM/DD/YYY"
          defaultValue={moment(values[fieldName]).format('MM/DD/YYYY')}
          required={field.required}
          data-inputtype={'date'}
          onBlur={handleChange}
          data-iscommissiondate={field.field === 'commissionDate'}
          data-track={`Bond Requirements - ${field.display}`}
        />
      </>
    );
  } if (field.type.toLowerCase() === 'boolean') {
    return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
        <FormSelect
          name={fieldName}
          defaultValue={values[fieldName]}
          hasError={props.hasError(fieldName)}
          required={field.required}
          onChange={handleChange}
          data-track={`Bond Requirements - ${field.display}`}
        >

          <option value="" disabled >Please Select an Option</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </FormSelect>
      </>
    );
  } if (field.optionList && field.optionList.length > 1) {
    return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
        <FormSelect
          name={fieldName}
          defaultValue={values[fieldName]}
          hasError={props.hasError(fieldName)}
          required={field.required}
          onChange={handleChange}
          data-track={`Bond Requirements - ${field.display}`}
        >
          <option value="" selected={!field.value} disabled>
            Select an Option
          </option>
          {field.optionList.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </FormSelect>
      </>
    );
  } if (field.field === 'phone') {
    return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
        <MaskedInput
          mask={[
            '(',
            /\d/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          className={`${
            props.hasError(fieldName) ? 'has-error' : ''
          } form-input`}
          name={fieldName}
          defaultValue={values[fieldName]}
          required
          width="100%"
          placeholder="(000) 000-0000"
          data-inputtype="phone"
          onBlur={handleChange}
          data-track={`Bond Requirements - ${field.display}`}
        />
      </>
    );
  } if (field.type === 'Number') {
    return (
      // Add a zIndex of zero to the number input b/c we have some stupid default css where zindex on input numbers is 2 ??
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
      <FormInput
          name={fieldName}
          defaultValue={values[fieldName]}
          required={field.required}
          data-track={`Bond Requirements - ${field.display}`}
          hasError={props.hasError(fieldName)}
          onBlur={handleChange}
          data-type={field.type}
          data-inputtype={field.field}
          readOnly={field.field === 'numberOfEmployees'}
          type='number'
          style={{ zIndex: 0 }}
        />
      </>
    );
  }

  return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
        <FormInput
          name={fieldName}
          defaultValue={values[fieldName]}
          required={field.required}
          data-track={`Bond Requirements - ${field.display}`}
          hasError={props.hasError(fieldName)}
          onBlur={handleChange}
          data-type={field.type}
          data-inputtype={field.field}
          readOnly={field.field === 'numberOfEmployees'}
        />
      </>
  );
};

// Custom Field Item
const CustomField = (props) => {
  const { field, i } = props;
  return (
    <div style={{ margin: '6px 0px' }} key={i}>
      <FormLabel>
        {field.display}
        {field.required && <sup>*</sup>}
        <i className="sub-label">{field.description}</i>
      </FormLabel>
      <CustomFieldInput {...props} />
    </div>
  );
};

export default function BondInfo({
  values,
  customFields,
  errors,
  hasError,
  formBlur,
  bondInfo,
}) {
  return (
    <div>
      <SectionHeader>Bond Information</SectionHeader>
      <FormLabel>Bond Type</FormLabel>
      <div style={{ margin: '6px 0px 6px 10px' }}>
        {toFullStateName(bondInfo.state)} {bondInfo.name}
      </div>
      <FormLabel>Bond Amount</FormLabel>
      <div style={{ margin: '6px 0px 6px 10px' }}>{bondInfo.amount}</div>
      <FormLabel>Effective Date</FormLabel>
      {bondInfo.isRenewal || bondInfo.version === 'v1' || bondInfo.fixedStart ? (
        <>
          <div style={{ margin: '6px 0px 6px 10px' }}>{values.effectiveDate}</div>
          <input type="hidden" name="effectiveDate" required value={values.effectiveDate}/>
        </>
      ) : (
        <>
        {errors.effectiveDate && (
          <p className="small-error">{errors.effectiveDate}</p>
        )}
        <MaskedInput
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          placeholder="MM/DD/YYYY"
          name="effectiveDate"
          defaultValue={values.effectiveDate}
          required
          className={`${hasError('effectiveDate') ? 'has-error' : ''} form-input`}
          data-inputtype="date"
          onBlur={formBlur}
          data-track="Bond Requirements - Effective Day"
        />
        </>
      )}

      {customFields
        && customFields.length > 0
        && customFields.map((field, i) => (
          <CustomField
            values={values}
            hasError={hasError}
            handleChange={formBlur}
            field={field}
            errors={errors}
          />
        ))}
    </div>
  );
}
