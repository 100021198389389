import React from 'react';
import { animated, useSpring } from 'react-spring';

export const AltProgressBar = ({ steps, activeStep }) => {
  const divideBy = 100 / (steps - 1);
  const props = useSpring({
    width: activeStep === 1 ? '0%' : `${(activeStep - 1) * divideBy}%`,
  });
  const stepArr = Array(steps)
    .fill(0)
    .map((e, i) => i + 1);
  return (
    <div className="progress-bar">
      {stepArr.map((step) => (
        <>
          <div className={`step ${step === activeStep ? 'active' : ''}`}>
            <div
              style={{ backgroundColor: `${step > activeStep ? 'white' : ''}` }}
              className={`step-icon ${step <= activeStep ? 'fa fa-check' : ''}`}
            />
          </div>
        </>
      ))}
      <animated.div
        style={{
          height: '3px',
          background: '#99c432',
          ...props,
          position: 'absolute',
          zIndex: 1,
        }}
      />
      <div
        style={{
          backgroundColor: '#156c9f',
          position: 'absolute',
          height: '3px',
          width: '100%',
        }}
      />
    </div>
  );
};

export const ProgressBar = ({
  panels, hitVerify, submitted, complete,
}) => {
  let progressWidth = '0%';
  let verifyProgress = '0%';
  if (panels[0].completed) {
    progressWidth = '30%';
  }
  if (panels[1].completed) {
    progressWidth = '80%';
  }
  if (panels[2].completed || hitVerify) {
    progressWidth = '100%';
  }
  if (submitted) {
    verifyProgress = '100%';
  }
  const fillStep = hitVerify ? 'fa fa-check' : 'fa fa-check';
  const verifyStep = hitVerify ? 'fa fa-check' : '';
  const quoteStep = submitted ? 'fa fa-check' : '';

  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar">
        <div
          className={`step completed ${!hitVerify ? 'active' : ''}`}
          id="fillStep"
        >
          <div className="step-name">Fill Application</div>
          <div className={`step-icon ${fillStep}`} />
        </div>
        <div className={`step-line ${hitVerify ? 'completed' : ''}`}>
          <div
            className={'step-line-progress-bar'}
            style={{
              width: progressWidth,
              transition: 'all .5s ease-in-out',
            }}
          />
        </div>

        <div
          className={`step ${hitVerify ? 'completed' : ''} ${
            hitVerify && !submitted ? 'active' : ''
          }
          }`}
          id="verifyStep"
        >
          <div className="step-name">Verify Information</div>
          <div className={`step-icon ${verifyStep}`} />
        </div>
        <div className="step-line" />
        <div
          className={'step-line-progress-bar'}
          style={{
            width: verifyProgress,
            transition: 'all .5s ease-in-out',
          }}
        />

        <div
          className={`step ${submitted ? 'completed active' : ''}`}
          id="quoteStep"
        >
          <div className="step-name">Get A Quote</div>
          <div className={`step-icon ${quoteStep}`} />
        </div>
      </div>
    </div>
  );
};
