import React from 'react';
import moment from 'moment';
import { toMoney, formatPhone, toFullStateName } from '../helpers';

export class ThankYouPage extends React.Component {
  render() {
    const {
      fields, customFields, contacts, bondInfo,
    } = this.props;
    return (
      <div className="center">
        <div className="thank-you-copy" style={{ marginTop: '5px' }}>
          <h1>Your application has been submitted!</h1>
          <h3>
            Your personal surety expert will contact you to review your
            application before we can provide a quote. If you have any questions
            or need to update any information at this time, please call{' '}
            <a href="tel:+18003084358">1 (800) 308-4358</a>.
          </h3>
        </div>
        <div className="thank-you-page">
          <div className="thank-you-heading">
            {!bondInfo.isFederal && (
              <>
                {toFullStateName(fields.bond.state)}
                <br />
              </>
            )}
            {bondInfo.name}
          </div>

          <div className="thank-you-section bond">
            {!!bondInfo.isV2 && !bondInfo.isGeneric && (
              <div className="verify-group tb">
                <div className="black-label">Effective Date</div>
                <div className="value">
                  {moment(fields.bond.effectiveDate).format('MM/DD/YYYY')}
                </div>
              </div>
            )}

            {!bondInfo.isGeneric && !!fields.bond.amount && (
              <div className="verify-group tb">
                <div className="black-label">Amount</div>
                <div className="value">{toMoney(fields.bond.amount)}</div>
              </div>
            )}

            {customFields
              && customFields.length > 0
              && customFields.map((field) => {
                const name = `customFields[${field.field}]`;
                let value = fields.bond[name];
                if (field.type === 'Boolean') {
                  value = value === true ? 'Yes' : 'No';
                }
                if (field.type === 'Date') {
                  value = moment(value).format('MM/DD/YYYY');
                }

                return (
                  <div className="verify-group tb">
                    <div className="black-label">{field.display}</div>
                    <div className="value">{value || 'N/A'}</div>
                  </div>
                );
              })}
          </div>
          <div className="thank-you-middle">
            {/* <div className="bond-title">
        {`${!bondInfo.isFederal && bondInfo.state} ${bondInfo.name}`}
      </div> */}

            <div className="thank-you-heading">Your Company</div>
            <div className="thank-you-section principal">
              <div className="lr">
                <div className="left">
                  <>
                    <div className="black-label">
                      {fields.company.companyName}
                      {fields.company.companyDBA && (
                        <div>
                          <strong> DBA </strong>
                          {fields.company.companyDBA}
                        </div>
                      )}
                    </div>
                  </>
                </div>
                <address className="right verify-address">
                  <div className="value">{fields.company.companyAddress}</div>
                  <div className="value">{fields.company.companyAddress2}</div>
                  <div className="value">
                    {fields.company.companyCity}, {fields.company.companyState}{' '}
                    {fields.company.companyZip}
                  </div>
                </address>
              </div>
              <div className="lr">
                <div className="left black-label">Business Type</div>
                <div className="right value">{fields.company.companyType}</div>
              </div>
            </div>
          </div>

          <div className="thank-you-heading">
            {['sole_proprietorship', 'individual', 'partnership'].includes(
              fields.company.companyType,
            )
              ? 'Your Information'
              : 'Owner Information'}
          </div>

          <div className="thank-you-section owners">
            {contacts.map((c) => {
              const contact = c.fields;
              return (
                <div className="thank-you-contact">
                  <div
                    className="contact-name"
                    style={{ marginBottom: '10px' }}
                  >
                    <div className="black-label">
                      {contact['[name][first]']} {contact['[name][last]']}
                    </div>
                  </div>
                  <div
                    className="thank-you contact-details"
                    style={{ marginBottom: '10px' }}
                  >
                    <div className="value">{formatPhone(contact.phone)}</div>
                    <div className="value">{contact.email}</div>
                    <address className="address">
                      <div className="value">{contact.address}</div>
                      <div className="value">{contact.address2}</div>
                      <div className="value">
                        {contact.city} {contact.state}, {contact.zipcode}
                      </div>
                    </address>
                  </div>
                  <div className="thank-you contact-details">
                    <div className="value">DOB: {contact.dob}</div>
                    <div className="value">SSN: {contact.ssn}</div>
                    <div className="value">
                      Own or Rent Home: {contact.home_type}
                    </div>
                    <div className="value">
                      Years of Experience: {contact.experience || 'N/A'}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <h2 className="thank-you-message">
          Thanks again for choosing SuretyBonds.com. <br />
          We look forward to working with you!
        </h2>
      </div>
    );
  }
}
