import React from 'react';

function reducer(draft, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'SET_STATE_FROM_DB':
      return action.payload;
  }
}

export const TitleContext = React.createContext({ reducer });

export const TitleProvider = TitleContext.Provider;
export const TitleConsumer = TitleContext.Consumer;
