import React from 'react';
import Footer from '../Components/Footer';
import TrustSeals from '../Components/TrustSeals';

import {
  TopBar,
  CheckoutHeader,
  SplitLayout,
  Card,
  AffiliateHeader,
} from '../DesignGuide';

// TopBar for Affiliate Logos
function AffiliateTopBar({ affiliateName }) {
  const name = affiliateName || window.sessionStorage.getItem('affiliateName');
  const logoUrl = window.sessionStorage.getItem('affiliateLogo');
  return (
    <>{name ? <AffiliateHeader name={name} imageUrl={logoUrl} /> : null}</>
  );
}

// Layout for StartApp
export function StartAppLayout(props) {
  return (
    <>
      <TopBar subHeader={true} />
      <AffiliateTopBar affiliateName={props.affiliateName} />
      <div className="container main-container" style={{ minHeight: '80vh' }}>
        <CheckoutHeader>
          Before we begin, let's get some basic information.
        </CheckoutHeader>
        <SplitLayout>
          <div>
            <Card color={'rgb(249, 249, 250)'}>{props.children}</Card>
          </div>

          <div className="right-box">
            <TrustSeals />
          </div>
        </SplitLayout>
      </div>
      <Footer />
    </>
  );
}

export default StartAppLayout;
