import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

const PTWrapper = styled.div`
  font-size:14px;
  max-width:100%;
  @media(max-width:450px){
    font-size:12px;
  }
`;
const PTList = styled.ol`
  font-family: 'Roboto', Arial, sans-serif;
  list-style: none outside;
  margin: 40px auto;
  overflow: hidden;
  padding: 0;
  text-align: center;
  white-space: nowrap;
`;
const PTItem = styled.li`
  display:inline
`;
const PTLink = styled.span`
  background: #d9d9d9;
  color: #fff;
  cursor: default;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0 10px;
  position: relative;
  text-decoration: none;
  :hover{
    color:#fff;
  }
  ::before{
    content: "";
    border-bottom: 15px solid #d9d9d9;
    border-left: 15px solid transparent;
    border-top: 15px solid #d9d9d9;
    height: 0px;
    position: absolute;
    left: -15px;
    top: 0px;
    width: 0px;
  }
  ::after{
    content: "";
    border-bottom: 15px solid transparent;
    border-left: 15px solid #d9d9d9;
    border-top: 15px solid transparent;
    height: 0px;
    position: absolute;
    right: -15px;
    top: 0px;
    width: 0px;
  }
`;

const ProgressTracker = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const step1 = windowWidth > 450 ? 'Enter Your Info' : 'Enter Info';
  const step2 = windowWidth > 450 ? 'Purchase Bond' : 'Purchase';
  const step3 = windowWidth > 450 ? 'Confirmation' : 'Confirm';

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // check props.canEdit and only display links if it's true.
  const setProgressTracker = () => {
    if (props.currentStep === 'Enter Your Info') {
      return (
        <>
          <PTItem>
              <PTLink className='PT-active'>{step1}</PTLink>
          </PTItem>
          {props.canEdit ? (
          <Link to={`/purchase-bond/${props.nonce}/purchase`}>
            <PTItem>
              <PTLink>{step2}</PTLink>
            </PTItem>
          </Link>) : (
            <PTItem>
              <PTLink>{step2}</PTLink>
            </PTItem>
          )
        }
          <PTItem>
            <PTLink>{step3}</PTLink>
          </PTItem>
        </>
      );
    } if (props.currentStep === 'Purchase Bond') {
      return (
        <>
          {props.canEdit ? (
            <Link to={`/application/${props.nonce}/fill?edit=true`}>
              <PTItem>
                  <PTLink className='PT-complete'>{step1}</PTLink>
              </PTItem>
          </Link>) : (
            <PTItem>
              <PTLink className='PT-complete'>{step1}</PTLink>
            </PTItem>
          )}
          <PTItem>
            <PTLink className='PT-active'>{step2}</PTLink>
          </PTItem>
          <PTItem>
            <PTLink>{step3}</PTLink>
          </PTItem>
        </>
      );
    }
    return (
        <>
          <PTItem>
              <PTLink className='PT-complete'>{step1}</PTLink>
          </PTItem>
          <PTItem>
            <PTLink className='PT-complete'>{step2}</PTLink>
          </PTItem>
          <PTItem>
            <PTLink className='PT-active'>{step3}</PTLink>
          </PTItem>
        </>
    );
  };

  return (
    <PTWrapper>
      <PTList>
      {setProgressTracker()}
      </PTList>
    </PTWrapper>
  );
};

export default ProgressTracker;
