import Axios from 'axios';
import React from 'react';
import '../App.css';
import {
  FormInput,
  FormItem,
  FormLabel,
  FormSelect,
  WideSubmitButton,
} from '../ExpressAppUI';
import {
  StateOptions,
  toFullStateName,
  toMoney,
  validateInput,
} from '../helpers';
import { useFormState } from '../hooks';
import { TitleContext } from '../titleContext';

function PrincipalPage({ navigate, nonce }) {
  // Use our global title context for event context
  const {
    state, dispatch, addLog, Mixpanel,
  } = React.useContext(TitleContext);

  // Create a reference to our form to pass to formState hook
  const form = React.useRef(null);

  async function handleSubmit() {
    try {
      const data = await Axios.post(`/title-bond/${nonce}/updatePrincipalInfo`, {
        principal: {
          name: values.companyName,
          address1: values.companyAddress,
          address2: validateInput.companyAddress2,
          city: values.companyCity,
          state: values.companyState,
          zip: values.companyZip,
        },
      });

      dispatch({
        type: 'UPDATE_STATE',
        payload: {
          step: 3,
          company: { ...values },
        },
      });
      navigate('../bond');
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR' });
    }
  }

  // Grab our form state and event handlers
  const [
    values,
    // eslint-disable-next-line no-unused-vars
    touched,
    // eslint-disable-next-line no-unused-vars
    errors,
    formBlur,
    submitForm,
    {
      hasError, isPristine, hasErrors, setValues,
    },
  ] = useFormState(
    { ...state.company, principalType: 'OTHER' },
    [
      'companyName',
      'companyZip',
      'companyState',
      'companyCity',
      'companyAddress',
      'principalType',
    ],
    handleSubmit,
    form,
  );

  function handlePrincipalType(e) {
    const changeType = e.target.value === 'yes' ? 'SELF' : 'OTHER';
    const log = {
      action: 'Bond Information',
      comment: `Customer confirmed that they're trying to title a vehicle that ${
        changeType === 'SELF' ? 'they' : '<strong>SOMEONE ELSE</strong>'
      } own(s).`,
    };
    if (changeType === 'OTHER') {
      Mixpanel.register({ 'Application Type': 'Gatekeeper' });
    }
    setValues({ ...values, principalType: changeType });
    addLog(log, { principalType: changeType }, false);
  }

  // Shorthand function to see if a field has an error

  return (
    <>
      <form onSubmit={submitForm} ref={form}>
        {!isPristine() && hasErrors() && (
          <h6 className="error-text" style={{ textAlign: 'center' }}>
            Please Address Fields in Red
          </h6>
        )}
        <div style={{ textAlign: 'center' }}>
          {state.underwritten && state.bondPremium ? (
            <>
              <div className="panel-title">
                {state.name}, your bond application is subject to underwriting.
              </div>
              <h3 className="helper-text">
                Please continue your {toFullStateName(state.bondState)}{' '}
                {state.bondType} application now to receive a quote.
              </h3>
            </>
          ) : (
            <>
              <div style={{ marginBottom: '10px' }} className="panel-title">
                {state.name}, the cost of your{' '}
                {toFullStateName(state.bondState)} {state.bondType} is:{' '}
              </div>
              <strong
                style={{
                  color: 'green',
                  marginTop: '10px',
                  marginBottom: '10px',
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                {toMoney(state.bondPremium, { noCents: false })}
              </strong>

              <h3 className="helper-text">
                We just need a few more details before you can purchase your
                bond
              </h3>
            </>
          )}
        </div>

        {/* Principal Type Question */}
        <FormItem>
          <FormLabel>
            Are you the owner of the vehicle applying for this title?
            <sup>*</sup>
          </FormLabel>
          <div
            className="radio-wrap"
            style={{ justifyContent: 'center' }}
            onChange={handlePrincipalType}
          >
            <input
              defaultChecked={state.principalType === 'SELF'}
              className="toggle-radio"
              type="radio"
              id="isOwnerYes"
              name="principalType"
              value="yes"
              required
            />
            <label htmlFor="isOwnerYes" className="toggle-label">
              Yes
            </label>
            <input
              className="toggle-radio"
              type="radio"
              id="isOwnerNo"
              name="principalType"
              value="no"
              required
              defaultChecked={state.principalType === 'OTHER'}
            />
            <label htmlFor="isOwnerNo" className="toggle-label">
              No
            </label>
          </div>
        </FormItem>

        {/* Principal Form */}
        <div onBlur={formBlur}>
          <FormItem>
            <FormLabel>
              Principal Name <sup>*</sup>
              <i className="sub-label2">
                {state.principalType === 'SELF'
                  ? 'Please provide your name exactly as it will appear on your title application.'
                  : 'Please provide the vehicle owner\'s name exactly as it will appear on the title application. If you do not have this information on hand, please verify with the applicant before moving forward.'}
              </i>
            </FormLabel>

            <FormInput
              defaultValue={values.companyName}
              name="companyName"
              placeholder="Principal Name"
              required
              hasError={hasError('companyName')}
              data-track="Principal Name"
            />
          </FormItem>
          <FormItem>
            <FormLabel>
              Principal Address
              <sup>*</sup>
              <i className="sub-label2">
                {state.principalType === 'SELF'
                  ? 'Please provide your address exactly as it will appear on your title application.'
                  : 'Please provide the vehicle owner\'s address exactly as it will appear on the title application. If you do not have this information on hand, please verify with the applicant before moving forward.'}
              </i>
            </FormLabel>

            <FormInput
              address
              hasError={hasError('companyAddress')}
              defaultValue={values.companyAddress}
              name="companyAddress"
              data-inputtype={'address'}
              data-track="Principal Address 2"
              placeholder="Street Address"
              required
            />
            <FormInput
              address
              defaultValue={values.companyAddress2}
              data-inputtype="address"
              name={'companyAddress2'}
              placeholder="Suite, Unit, Building, Floor, Etc."
              data-track="Principal Address 2"
              hasError={hasError('companyAddress2')}
            />

            <FormInput
              address
              width={'30%'}
              name={'companyZip'}
              defaultValue={values.companyZip}
              placeholder="Zip"
              data-inputtype="zip"
              required
              data-track="Principal Zip"
              hasError={hasError('companyZip')}
              data-zip
            />
            <FormInput
              address
              width={'48%'}
              margin={'0% 1%'}
              name={'companyCity'}
              placeholder="City"
              defaultValue={values.companyCity}
              required
              data-track="Principal City"
              data-zip2city="zip2city"
              hasError={hasError('companyCity')}
            />
            <FormSelect
              name={'companyState'}
              defaultValue={values.companyState}
              required
              width={'20%'}
              data-track="Principal State"
              hasError={hasError('companyState')}
              data-zip2state
            >
              <option value="" disabled>
                State
              </option>
              {StateOptions.map((option) => (
                <option
                  key={`companyState-${option.value}`}
                  value={option.value}
                >
                  {option.display}
                </option>
              ))}
            </FormSelect>
          </FormItem>
        </div>
        <WideSubmitButton
          onClick={(e) => submitForm(e, () => window.scrollTo(0, 0))}
        >
          Save And Continue
        </WideSubmitButton>
      </form>
    </>
  );
}

export default PrincipalPage;
