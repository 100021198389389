import Axios from 'axios';
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import '../App.css';
import { FormItem, FormLabel, WideSubmitButton } from '../ExpressAppUI';
import { toMoney } from '../helpers';
import { useBondAmountCheck } from '../hooks';
import { TitleContext } from '../titleContext';

const TitleBondStart = React.memo(({
  navigate,
  uri,
  nonce,
}) => {
  // Grab Our Title Context
  const {
    state, dispatch, addLog, Mixpanel,
  } = React.useContext(TitleContext);

  const showBondAmount = state.confirmedObligee;
  // Get Bond Amount and function that checks if it is valid
  const [amount, checkAmount] = useBondAmountCheck({
    value: state.bondAmount,
    min: state.minAmount,
  });

  // Function that handles Obligee selection
  async function handleObligee({ target: { value } }) {
    // Format Comment
    const isConfirm = value === 'Yes';
    const log = {
      comment: `Customer confirmed that they ${
        isConfirm ? 'have' : 'have not'
      } spoken to the Obligee`,
      action: 'Obligee Confirmation',
    };

    Mixpanel.track(
      `Customer confirmed obligee contact: ${value.toUpperCase()}`,
    );
    // this actually updates status and onlineAppStatus
    addLog(log, { confirmedObligee: value === 'Yes' });

    if (!isConfirm) {
      navigate('../contactObligee');
    }
  }

  const termRef = React.useRef();

  // Agree to Terms Callback
  const agreeToTerms = async (e) => {
    // Agreed
    if (e.target.checked) {
      const log = {
        action: 'Customer Accepted Terms',
        comment: `Customer acknowledged the following terms: <br>
          <br> <strong> I acknowledge that if my title application is rejected due to an incorrect bond amount, my bond will not be changed or canceled, and my purchase will not be refunded </strong>`,
      };
      addLog(log, { confirmedTerms: true }, false);

      Mixpanel.track('Customer acknowledged terms & conditions');
    }
  };

  // Submit Form
  const handleSubmit = async (e) => {
    // See if our amount is valid
    if (!state.bondAmount) {
      checkAmount(toMoney(amount.value, { noCents: true }));
      // Move to next if its valid
      if (amount.validAmount) {
        Mixpanel.track('Customer confirmed their bond amount');
        const results = await Axios.post(
          `/title-bond/${nonce}/updateBondAmount`,
          {
            bondAmount: toMoney(amount.value, {
              noCents: false,
            }),
          },
        );
        Mixpanel.register({
          Type: results.data
            ? 'Title Bond - Instant Issue'
            : 'Title Bond - Underwritten',
          Premium: results.data ? results.data.premium : 'underwritten',
        });
        dispatch({
          type: 'UPDATE_STATE',
          payload: {
            step: 2,
            bondAmount: toMoney(amount.value, { noCents: true }).replace(
              '$',
              '',
            ),
            bondPremium: results.data ? results.data.premium : 'Underwritten',
            underwritten: !results.data,
          },
        });
      }
      // skip the lien question if the bond is for California
      state.bondState === 'CA' ? navigate('../principal') : navigate('../confirmLien');
    } else {
      state.bondState === 'CA' ? navigate('../principal') : navigate('../confirmLien');
    }
  };

  return (
    <>
      <div className="panel-title" style={{ textAlign: 'center' }}>
        Before We Continue...
      </div>
      <h3 className="helper-text">
        Before we can provide pricing for your bond, please note the following:
      </h3>
      <br />

      <div style={{ display: 'inline-block', marginTop: '-25px' }}>
        <ul style={{ textAlign: 'left' }} ref={termRef}>
          {state.webNotes.map((note, idx) => {
            const props = {
              dangerouslySetInnerHTML: { __html: note },
            };
            return <li style={{ marginTop: '5px' }} {...props} key={idx} />;
          })}
        </ul>
      </div>

      <FormItem>
        <FormLabel>
          Have you verified every item above with the {state.obligee} regarding
          this bond request?
          <sup>*</sup>
        </FormLabel>
        <div className="radio-wrap" style={{ justifyContent: 'center' }}>
          <input
            defaultChecked={state.confirmedObligee}
            onChange={React.useCallback(handleObligee)}
            className="toggle-radio"
            type="radio"
            id="verifiedToggleYes"
            name="confirmObligee"
            value="Yes"
            required
          />
          <label htmlFor="verifiedToggleYes" className="toggle-label">
            Yes
          </label>
          <input
            onChange={React.useCallback(handleObligee)}
            className="toggle-radio"
            type="radio"
            id="verifiedToggleNo"
            name="confirmObligee"
            value="No"
            required
          />
          <label htmlFor="verifiedToggleNo" className="toggle-label">
            No
          </label>
        </div>
      </FormItem>

      {showBondAmount && (
        <>
          <FormItem>
            <FormLabel>Bond Amount</FormLabel>
            {!state.bondAmount
            || (state.bondAmount && parseInt(state.bondAmount) === 0) ? (
              // If we have no bond amount or amount is ZERO
              // Show Bond Amount Field
              <>
                {amount.error && <p className="small-error">{amount.error}</p>}
                <MaskedInput
                  className={`form-input ${amount.error && 'has-error'}`}
                  mask={createNumberMask({
                    prefix: '$',
                    requireDecimal: true,
                    decimalLimit: 2,
                    guide: true,
                    keepCharPositions: true,
                  })}
                  value={
                    amount.value
                      ? toMoney(amount.value, { noCents: false })
                      : ''
                  }
                  name="amount"
                  data-track="Bond Amount Free Input"
                  placeholder={'Please Enter an amount'}
                  data-inputtype="money"
                  required
                  onBlur={(e) => {
                    checkAmount(e.target.value);
                  }}
                />
              </>
              ) : (
              // Else, we already have an amount, don't allow them to re-enter
              <>
                <p className="helper-text" style={{ marginLeft: '10px' }}>
                  You've already confirmed the amount below. If you need to
                  change the amount please contact us
                </p>
                <div className="form-text">
                  {toMoney(state.bondAmount, { noCents: false })}
                </div>
              </>
              )}
          </FormItem>

          <FormItem style={{ textAlign: 'left' }}>
            <input
              type="checkbox"
              className="custom-checkbox"
              id="confirmBondAmount"
              required
              onChange={(e) => {
                agreeToTerms(e);
              }}
              defaultChecked={state.confirmedTerms}
            />
            <label htmlFor="confirmBondAmount" style={{ marginLeft: '10px' }}>
              <em>
                I acknowledge that if my title application is rejected due to an
                incorrect bond amount, my bond will not be changed or canceled,
                and my purchase will not be refunded.
              </em>
            </label>
          </FormItem>

          {state.confirmedTerms && (
            <WideSubmitButton
              className="aClass"
              disabled={amount.error || !amount.value}
              onClick={handleSubmit}
            >
              Save and Continue
            </WideSubmitButton>
          )}
        </>
      )}
    </>
  );
});

export default TitleBondStart;
