import React from 'react';
import styled from 'styled-components';

const AddressP = styled.p`
  margin-bottom:0;
  margin-top:0;
`;

const ShippingInformation = (props) => (
  props.shippingAddress ? (
    <>
    <AddressP><strong>{props.shippingAddress.name.first}</strong></AddressP>
    <AddressP>{props.shippingAddress.address}</AddressP>
    {props.shippingAddress.address2 === '' ? null : (
      <AddressP>{props.shippingAddress.address2}</AddressP>
    )}
    <AddressP>{`${props.shippingAddress.city} ${props.shippingAddress.state}, ${props.shippingAddress.zipcode}`}</AddressP>
  </>) : <p>Please contact your Account Manager or call 1 (800) 308-4358 to confirm your shipping information.</p>
);

const ShippingSummary = (props) => (
    <>
      <h5 className='panel-title'>Shipping Information</h5>
      {props.selectedShipping === 'Emailed' || props.selectedShipping === 'No Shipping Required' ? (
        <p>A receipt confirming your order has been sent to your email. You will receive a second email with your official bonding confirmation once your order has been processed.</p>
      ) : <ShippingInformation shippingAddress={props.shippingAddress}/>}
    </>
);


export default ShippingSummary;
