import React from 'react';
import { formatPhone } from '../../helpers';

function AccountManagerBox({ salesRep }) {
  const isCustomerCare = salesRep.email === 'customercare@suretybonds.com';
  const image = salesRep.sig_photo || 'customer-care.png';
  return (
   <div>
     <div className="am-box">
       <div className="checkout-form">
         <div className="account-manager">
         <div className="account-manager-picture">
           <img src={`/img/sig_photos/${image}`} alt={`${salesRep.name.first}`}/>
         </div>
         <div className="account-manager-details">
           <div className="name">{isCustomerCare ? 'Contact Team' : `${salesRep.name.first} ${salesRep.name.last}`}</div>
           <div className="detail">Your Account Manager</div>
         </div>
         <div className="account-manager-contact">
           {salesRep.phone && (
             <div className='am-phone-number'>
             <i className="fa fa-phone"></i>
           <a href={`tel:${salesRep.phone}`}>{formatPhone(salesRep.phone)}</a>
           </div>
           )}
           {salesRep.email && (
             <div className="am-email">
             <i className="fa fa-at"></i>
             <a href={`mailto:${salesRep.email}`}>{salesRep.email}</a>
             </div>
           )}
           {salesRep.fax && (
             <div className="am-fax">
               <span className="fax-icon">FAX</span>
               <span>{formatPhone(salesRep.fax)}</span>
             </div>
           )}
         </div>
       </div>
         </div>

     </div>
   </div>
  );
}

export default AccountManagerBox;
