import React from 'react';
import TrustPilotWidget from './TrustPilotWidget';

export default class TrustSeals extends React.PureComponent {
  componentDidMount() {
    setTimeout(() => {
      const trustSeals = document.getElementById('trust-seals-source');
      const target = document.getElementsByClassName('trust-seal-target');
      const cloned = trustSeals.cloneNode(true);

      target[0].appendChild(cloned);
    }, 1000);
  }

  render() {
    return (
    <>
      <div className="trust-seal-target" />
      <TrustPilotWidget></TrustPilotWidget>
    </>
    );
  }
}
