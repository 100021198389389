import React from 'react';
import moment from 'moment';
import { toMoney, formatPhone, isScrolledIntoView } from '../helpers';

import { PageButton, SmartPageButton } from '../ExpressAppUI';

import { Mixpanel } from '../mixpanel';

export class VerifyPage extends React.PureComponent {
  state = {
    submitting: false,
    submitted: true,
    confirmedCreditPull: false,
    isConfirmingCreditPull: false
  };

  doConfirmCreditPull = async () => {

    try {
      const didConfirm = !this.state.confirmedCreditPull;
      this.setState({ ...this.state, isConfirmingCreditPull: true, confirmedCreditPull: didConfirm })
      const opts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          didConfirm
        })
      }
      await window.fetch(`${window.location.pathname}/confirm-credit-check`, opts)
      this.setState((state, props) => {
        return { ...state, isConfirmingCreditPull: false };
      });
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    Mixpanel.track('Verification Submitted');

    this.setState({ submitting: true }, () => {
      const progressBar = document.getElementsByClassName(
        'progress-bar-wrapper',
      )[0];

      setTimeout(() => {
        if (!isScrolledIntoView(progressBar)) {
          window.scrollTo({
            top: document.getElementById('root').offsetTop,
            behavior: 'smooth',
          });
        }

        this.props.submitApplication();
      }, 400);
    });
  };

  render() {
    const {
      fields,
      customFields,
      contacts,
      toggleActivePanel,
      bondInfo,
      isMobile,
    } = this.props;

    return (
      <div className="verify-page">
        <h3 className="helper-text">
          {!isMobile
            ? `Great work! Click the "Submit" button below to complete your application.
            If you would like to edit your information, simply click the corresponding panel on the right.`
            : `To complete your application, click the "Submit" button below.
            To review the information you entered, tap the "edit" on the panel you would like to view.`}
        </h3>
        <div className="verify-section">
          <div className="verify-title">
            Your Bond
            {isMobile && (
              <button
                className="verify-edit-btn"
                onClick={() => toggleActivePanel('bond')}
              >
                Edit
              </button>
            )}
          </div>

          <div className="verify-bond verify-panel">
            {!!bondInfo.isV2 && !bondInfo.isGeneric && (
              <div className="verify-group">
                <div className="verify-label">Effective Date</div>
                <div className="verify-value">
                  {moment(fields.bond.effectiveDate).format('MM/DD/YYYY')}
                </div>
              </div>
            )}

            {!!fields.bond.amount && (
              <div className="verify-group">
                <div className="verify-label">Amount</div>
                <div className="verify-value">
                  {toMoney(fields.bond.amount)}
                </div>
              </div>
            )}

            {customFields
              && customFields.length > 0
              && customFields.map((field) => {
                const name = `customFields[${field.field}]`;
                let value = fields.bond[name];
                if (field.type === 'Boolean') {
                  value = value === true ? 'Yes' : 'No';
                }
                if (field.type === 'Date') {
                  value = moment(value).format('MM/DD/YYYY');
                }

                return (
                  <div className="verify-group">
                    <div className="verify-label">{field.display}</div>
                    <div className="verify-value">{value || 'N/A'}</div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="verify-section">

          <div className="verify-title">
            Your Company
            {isMobile && (
              <button
                className="verify-edit-btn"
                onClick={() => toggleActivePanel('company')}
              >
                Edit
              </button>
            )}
          </div>
          <div className="verify-company verify-panel">
            <div className="company-name">
              <>
                <div className="verify-large">
                  {' '}
                  {fields.company.companyName}
                </div>
                {fields.company.companyDBA && (
                  <div class="verify-sub">
                    <strong> DBA </strong>
                    {fields.company.companyDBA}
                  </div>
                )}
              </>
            </div>
            <address className="verify-address">
              <div className="verify-value">
                {fields.company.companyAddress}
              </div>
              <div className="verify-value">
                {fields.company.companyAddress2}
              </div>
              <div className="verify-value">
                {fields.company.companyCity}, {fields.company.companyState}{' '}
                {fields.company.companyZip}
              </div>
            </address>
          </div>
        </div>

        <div className="verify-section">
          <div className="verify-title">
            {['sole_proprietorship', 'individual'].includes(
              fields.company.companyType,
            )
              ? 'Your Information'
              : 'Owner Information'}
            {isMobile && (
              <button
                className="verify-edit-btn"
                onClick={() => toggleActivePanel('owners')}
              >
                Edit
              </button>
            )}
          </div>
          <div className="verify-owners">
            {contacts.map((c) => {
              const contact = c.fields;
              return (
                <div className="verify-contact verify-panel is-contact">
                  <div className="contact-name">
                    <div className="verify-large">
                      {contact['[name][first]']}
                    </div>
                    <div className="verify-sub strong">
                      {contact['[name][last]']}
                    </div>
                  </div>
                  <div
                    className="push-right"
                    style={{
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div className="contact-details">
                      <div className="verify-value">
                        {formatPhone(contact.phone)}
                      </div>
                      <div className="verify-value">{contact.email}</div>
                      <address className="verify-address">
                        <div className="verify-value">{contact.address}</div>
                        <div className="verify-value">{contact.address2}</div>
                        <div className="verify-value">
                          {contact.city} {contact.state}, {contact.zipcode}
                        </div>
                      </address>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ border: '1px solid gray', fontSize: '14px', padding: '8px' }}>
          <input
            type='checkbox'
            id="confirmedCreditCheck"
            checked={this.state.confirmedCreditPull}
            className='custom-checkbox'
            name='confirmedCreditCheck'
            required
            data-inputtype='radio'
            onClick={this.doConfirmCreditPull}
          />
          <label
            htmlFor={'confirmedCreditCheck'}
            style={{ marginLeft: '5px' }}
          >
            I understand that any person who knowingly presents false information in an application for insurance is guilty of a crime. I authorize SuretyBonds.com to use all submitted information, including consumer credit reports, to determine eligibility for bonding.
          </label>
        </div>

        <div>

          <SmartPageButton buttonText="Submit" isSubmitting={this.state.submitting} isDisabled={!this.state.confirmedCreditPull === true || this.state.isConfirmingCreditPull} verify onClickFunction={this.handleSubmit} />

        </div>
      </div>
    );
  }
}

export default VerifyPage;
