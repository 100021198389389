import Axios from 'axios';
import React from 'react';
import '../App.css';
import {
  FormInput,
  FormItem,
  FormLabel,
  FormSelect,
  WideSubmitButton,
  PageButton,
} from '../ExpressAppUI';
import { useFormState } from '../hooks';
import { TitleContext } from '../titleContext';

// CustomField Component
const CustomField = React.memo(({ field, hasError, shouldRenderVinTool }) => {
  const renderField = (field) => {
    if (field.type.toLowerCase() === 'boolean') {
      return (
        <FormSelect
          value={field.value}
          hasError={hasError(field.field)}
          required={field.required}
          data-inputtype={field.type}
          data-track={`Bond Requirements - ${field.display}`}
          name={field.field}
        >
          <option value="" disabled>
            Please Select An Option
          </option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </FormSelect>
      );
    } if (field.optionList && field.optionList.length) {
      return (
        <FormSelect
          value={field.value}
          hasError={hasError(field.field)}
          required={field.required}
          data-track={`Bond Requirements - ${field.display}`}
          name={field.field}
          data-inputtype={field.type}
        >
          <option value="" disabled>
            Select an Option
          </option>
          {field.optionList.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </FormSelect>
      );
    }
    return (
        <FormInput
          style={{ marginBottom: '0px' }}
          disabled={shouldRenderVinTool && !!field.vin && field.field === 'vin'}
          value={field.value}
          hasError={hasError(field.field)}
          required={field.required}
          data-track={`Bond Requirements - ${field.display}`}
          name={field.field}
          data-inputtype={field.type}
        />
    );
  };

  return (
    <FormItem style={{ marginTop: '10px' }}>
      <FormLabel style={{ marginBottom: '0px' }}>
        <span className="label-text">{field.display}</span>
        {!!field.required && <sup>*</sup>}
        {!!field.description && (
          <i className="sub-label">{field.description}</i>
        )}
      </FormLabel>
      {renderField(field)}
    </FormItem>
  );
});

// Main Page Component
function BondPage({ navigate, uri, nonce }) {
  // Use our global title context for event context
  const { state, dispatch, Mixpanel } = React.useContext(TitleContext);
  // Handle Submit
  const handleSubmit = () => {
    const data = state.customFields.map((f) => ({
      ...f,
      value: values[f.field],
    }));
    dispatch({ type: 'UPDATE_STATE', payload: { saving: true } });
    Axios.post(`/title-bond/${nonce}/updateCustomFields`, {
      fields: data,
    }).then((response) => {
      // Log the action
      Axios.post(`/title-bond/${nonce}/addLog`, {
        action: 'Bond Information Updated',
        comment: `Customer Confirmed their ${
          state.titleType
        }'s information:<br/>${data
          .map(
            (field) => `<br/><strong>${field.display}:</strong> ${field.value}`,
          )
          .join('')}`,
      });
      dispatch({
        type: 'UPDATE_STATE',
        payload: { saving: false },
      });

      Mixpanel.track('Customer confirmed title information');

      if (state.underwritten || state.rejectionLetterRequired) {
        window.location.href = `/title-bond/${nonce}/end`;
      } else {
        window.location.href = `/purchase-bond/${nonce}/purchase`;
      }
    });
  };

  // Create a reference to our form to pass to formState hook
  const form = React.useRef(null);

  const formattedFields = {
    titleType: state.titleType || '',
    customerVin: state.customerVin || '',
  };

  const requiredFields = ['titleType', 'customerVin'];

  state.customFields.forEach((f) => {
    formattedFields[f.field] = f.value || '';
    if (f.required) {
      requiredFields.push(f.field);
    }
  });

  const shouldRenderVinTool = state.bondState !== 'TX';

  const [showVehicleDetails, setShowVehicleDetails] = React.useState(
    (!shouldRenderVinTool) || (state.customFields.filter((v) => !!v.value).length > 0 && !!state.customerVin),
  );

  // Grab our form state and event handlers
  const [
    values,
    // eslint-disable-next-line no-unused-vars
    touched,
    // eslint-disable-next-line no-unused-vars
    errors,
    formBlur,
    submitForm,
    {
      hasError, isPristine, hasErrors, setValues,
    },
  ] = useFormState(formattedFields, requiredFields, handleSubmit, form);

  // Search VIN
  const getVehicleDetails = async () => Axios.get(
    `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${values.customerVin}?format=json`,
  )
    .then(({ data }) => {
      if (
        data.Message.toUpperCase().indexOf('RESULTS RETURNED SUCCESSFULLY')
          >= 0
      ) {
        if (data.Results.length && data.Results.length === 1) {
          // Log succesfull return
          const comment = `Customer confirmed their ${state.titleType}'s VIN: <strong>${values.customerVin}</strong>.`;
          Axios.post(`/title-bond/${nonce}/addLog`, {
            comment,
            action: 'Bond Information Updated',
          });
          return data.Results[0];
        }
      } else {
        // airbrake.notify("Error: NHTSA API failed, falling back to user input.");
        return false;
      }
    })
    .catch((error) => {
      // airbrake.notify("NHSTA API failed. Status: " + error + "Message" + error)
      console.log('ERROR.....');
      console.log(error);
    });

  return (
    <form ref={form} onSubmit={submitForm}>
      <div className="panel-title" style={{ textAlign: 'center' }}>
        Vehicle Information
      </div>

      {!isPristine() && hasErrors() && (
        <p className="error-text" style={{ textAlign: 'center' }}>
          Please Address Fields in Red
        </p>
      )}
      <>
        {state.underwritten ? (
          <>
            <h3 className="helper-text">
              Due to the bond amount provides, your bond application is subject
              to underwriting. Please continue your application now to receive a
              quote
            </h3>
          </>
        ) : (
          <>
            <h3 className="helper-text">
              We just need a few more details before you can purchase your bond.
            </h3>
          </>
        )}
      </>

      <div>
        <FormItem>
          <FormLabel>
            Title Type
            <i className="sub-label">What are you trying to title?</i>
          </FormLabel>
          <FormSelect
            hasError={hasError('titleType')}
            name={'titleType'}
            required
            defaultValue={values.titleType || ''}
            onChange={(e) => {
              formBlur(e);
              const type = e.target.value;
              Mixpanel.register({ 'Title Type': type });
              Axios.post(`/title-bond/${nonce}/addLog`, {
                comment: `Customer confirmed that they are attempting to title a <strong>${type}</strong>`,
                action: 'Bond Information Updated',
              });
              dispatch({
                type: 'UPDATE_STATE',
                payload: { titleType: type },
              });
            }}
          >
            <option value="" disabled>
              Select One
            </option>
            <option value="Car, Truck, or SUV">Car, Truck, or SUV</option>
            <option value="Trailer">Trailer</option>
            <option value="Motorcycle or ATV">Motorcycle or ATV</option>
            <option value="Mobile Home">Mobile Home</option>
          </FormSelect>
        </FormItem>
        {values.titleType && shouldRenderVinTool && (
          <FormItem>
            <FormLabel>
              What is the {values.titleType}'s vehicle identification number
              (VIN)? <sup>*</sup>
            </FormLabel>

            <div className="input-group">
              <FormInput
                className={`form-input ${hasError('customerVin')
                  && 'has-error'}`}
                type="text"
                required
                name={'customerVin'}
                onChange={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                  formBlur(e);
                }}
                defaultValue={values.customerVin}
              />
              <PageButton
                disabled={!values.customerVin}
                onClick={async (e) => {
                  e.preventDefault();
                  if (!values.customerVin) {
                    return false;
                  }
                  dispatch({
                    type: 'UPDATE_STATE',
                    payload: { saving: true },
                  });
                  const vehicle = await getVehicleDetails();
                  Mixpanel.track('Customer entered a VIN.');
                  if (vehicle) {
                    // Set Values
                    setValues({
                      ...values,
                      ...{
                        year: vehicle.ModelYear || '',
                        make: vehicle.Make || '',
                        model: vehicle.Model || '',
                        bodyType: vehicle.BodyClass || '',
                        vin: values.customerVin,
                      },
                    });
                  }
                  dispatch({
                    type: 'UPDATE_STATE',
                    payload: { saving: false, activeStep: 2.5 },
                  });
                  setShowVehicleDetails(true);
                }}
              >
                {showVehicleDetails ? 'Update VIN' : 'Confirm Vin'}
              </PageButton>
            </div>
          </FormItem>
        )}
      </div>
      {showVehicleDetails && (
        <>
          <div
            onBlur={formBlur}
            onChange={(e) => {
              if (e.target.dataset.inputtype !== 'radio') {
                e.preventDefault();
              }
              if (['licensePlate', 'license'].includes(e.target.name)) {
                e.target.value = e.target.value.toUpperCase();
              }
              setValues({ ...values, [e.target.name]: e.target.value });
            }}
          >
            {state.customFields.map((f, i) => {
              const field = {
                ...f,
                value: values[f.field],
                key: i,
                vin: values.customerVin,
              };
              return <CustomField hasError={hasError} key={i} field={field} shouldRenderVinTool={shouldRenderVinTool} />;
            })}
          </div>

          <WideSubmitButton
            onClick={(e) => submitForm(e, () => window.scrollTo(0, 0))}
          >
            Save and Continue
          </WideSubmitButton>
        </>
      )}
    </form>
  );
}

export default BondPage;
