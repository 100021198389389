import { Link } from '@reach/router';
import React from 'react';
import '../App.css';
import { toFullStateName } from '../helpers';
import { TitleContext } from '../titleContext';

export default function ContactObligee() {
  const { state } = React.useContext(TitleContext);

  return (
    <div>
      <h1
        className="error-text"
        style={{ textAlign: 'center', fontSize: '22px' }}
      >
        <i className="fa fa-exclamation" style={{ marginRight: '10px' }} />
        VERIFICATION REQUIRED
      </h1>
      <h3 className="helper-text">
        All Application information for your {toFullStateName(state.bondState)}{' '}
        {state.bondType} <strong>must be verified</strong> with the{' '}
        {toFullStateName(state.bondState)} {state.obligee} before pricing can be
        approved.
      </h3>
      <h5 className="helper-text">
        Here are some things to know before you get started:
      </h5>
      <br />
      <div style={{ display: 'inline-block', marginTop: '-25px' }}>
        <ul style={{ textAlign: 'left' }}>
          {state.webNotes.map((note, idx) => {
            const props = {
              dangerouslySetInnerHTML: { __html: note },
            };
            return <li {...props} key={idx} />;
          })}
          <li>
            Contact the {state.obligee} to verify that a bond is necessary to get
            your title.
          </li>
          <li>
            Return to{' '}
            <Link to={`/title-bond/${state.nonce}/start`}>SuretyBonds.com</Link>{' '}
            to continue your application.
          </li>
        </ul>
        <p className="helper-text">
          Verifying this information now means your bond can be issued quickly
          and accurately. We look forward to working with you!
        </p>
      </div>
    </div>
  );
}
