import { Mixpanel } from '../mixpanel';
import { useParams } from "@reach/router";
import { useLead } from "../client";
import { getRequiresShipping, getTrackingPremium } from "../utils/business-logic";
import React from "react";

export const useTrackPurchasePage = (pageTitle) => {
  const {invoice, lead} = useLead();
  const {nonce} = useParams();
  const leadId = lead?._id
  React.useEffect(() => {
    if(lead?._id) {
      const bond = lead.bond.application.suretyBond
      Mixpanel.identify(nonce);
      window.dataLayer = window.dataLayer || [];
      const term = lead.app && lead.app.multiTerm;
      const mixpanelTrack = {
        'Nonce ID': nonce,
        'Bond ID': bond._id.toString(),
        'Bond Type': bond.name,
        'Bond Amount': lead.bond.amount,
        'Bond State': lead.bond.state,
        'Bond Version': bond.version,
        'Term Length': term,
        'Bond Premium': getTrackingPremium(invoice),
        'Shipping Required': getRequiresShipping(lead)
      };
      Mixpanel.register(mixpanelTrack);
      Mixpanel.track(pageTitle || 'Purchase Page');

      // In order for our chat widget to track variables -- we pass them to the global layer
      window.leadId = lead._id;
      window.bondType = bond.name;
      window.bondState = lead.bond.state;
      window.bondAmount = bond.amount;
      const dataLayerTrack = {
        leadId: lead._id,
        userId: lead._id,
        bondId: bond._id.toString(),
        bondType: bond.name,
        bondState: bond.state,
        bondAmount: lead.bond.amount,
        bondVersion: bond.version,
        nonceId: nonce,
        isRenewal: !!lead.prevPolicy,
        ecData: {
          email: lead.email || '',
          phone_number: lead.phone || '',
        },
        event: 'Data Layer Ready',
      };
      window.dataLayer.push(dataLayerTrack);
    }
  }, [lead?._id])

};

export const initTrackConfirmationPage = (nonce, data) => {
  // Init mixpanel and datalayer
  Mixpanel.identify(nonce);
  window.dataLayer = window.dataLayer || [];

  const mixpanelTrack = {
    'Nonce ID': nonce,
    'Bond ID': data.bond.id,
    'Bond Type': data.bond.name,
    'Bond Amount': data.bond.amount,
    'Bond State': data.bond.state,
    'Bond Version': data.bond.version,
    Renewal: data.isRenewal,
    'Bond Premium': getTrackingPremium(data.invoice),
    'Shipping Required': data.bond.originalRequired,
  };
  Mixpanel.register(mixpanelTrack);
  // In order for our chat widget to track variables -- we pass them to the global layer
  window.leadId = data.leadId;
  window.bondType = data.bond.name;
  window.bondState = data.bond.state;
  window.bondAmount = data.bond.amount;
  Mixpanel.track('Bond Purchase Complete');
  const dataLayerTrack = {
    leadId: data.leadId,
    userId: data.leadId,
    bondId: data.bond.id,
    bondType: data.bond.name,
    bondState: data.bond.state,
    bondAmount: data.bond.amount,
    bondVersion: data.bond.version,
    nonceId: nonce,
    isRenewal: data.isRenewal,
    ecData: {
      email: data.lead?.email || '',
      phone_number: data.lead?.phone || '',
    },
    event: 'Data Layer Ready',
  };
  window.dataLayer.push(dataLayerTrack);
};

export const trackBondDocs = (autoRenewalReady) => {
  let message = 'Customer Downloaded Bond Documents';
  if (autoRenewalReady) message = 'Customer Downloaded Continuation Certificate';

  Mixpanel.track(message);
  // TODO: CN-1329 Add datalayer eventc
};
export const trackTermChange = ({ term, invoice }) => {
  Mixpanel.track(`Term changed to ${term}`);
  // TODO: Update bond premium super prop
  const newPremium = invoice.charges.filter((c) => c.category === 'Bond Premium')[0].amount;
  Mixpanel.register({ 'Bond Premium': newPremium, 'Term Length': term });
};

export const trackAgreeToTerms = (checked) => {
  Mixpanel.track(`${checked ? 'Checked' : 'Unchecked'} Terms`);
};

export const trackShippingChange = (shippingSelection) => {
  Mixpanel.track(`Shipping Changed to ${shippingSelection}`);
};

export const trackFormSubmitted = () => {
  Mixpanel.track('Purchase Form Submitted');
};

export const trackPaymentFailed = () => {
  Mixpanel.track('Payment Error');
};

export const trackFedexValidationSelection = (isFedExValidated, showSuggested) => {
  let trackingString = '';
  if(isFedExValidated) trackingString = 'Customer selected a FedEx verified address';
  if(!isFedExValidated) {
    // they had options
    if(showSuggested) trackingString = 'Customer confirmed use of an unverifiable address';
    // fedex failed to validate it
    else {trackingString = 'Customer confirmed use of an unverifiable address with no alternative suggestions';}
  }
  Mixpanel.track(trackingString);
}
