import React from 'react';

import { SlidingMenu, ModalHeader, ModalBody } from "../components/SlidingMenu";
import { ValidateAddress } from "./ValidateAddress";
import { AddressForm } from "./AddressForm";
export function AddressModal({ setActiveAddress, activeAddress }) {
  function setIsOpen() {
    setActiveAddress({ address: false, viewMode: false, canEdit: false });
  }
  return (
    <SlidingMenu isOpen={activeAddress.address} setIsOpen={setIsOpen}>
      <ModalHeader
        title={"Shipping Address"}
        icon={
          <svg
            style={{ height: "1.5rem", marginRight: "5px" }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        }
      />
      {/* The Modal/Menu Body */}
      <ModalBody>
        <div style={{ margin: "0", padding: "1rem" }}>
          {activeAddress.viewMode === "edit" && (
            <AddressForm
              activeAddress={activeAddress}
              setActiveAddress={setActiveAddress}
            />
          )}
          {activeAddress.viewMode === "validate" && (
            <ValidateAddress
              activeAddress={activeAddress}
              setActiveAddress={setActiveAddress}
            />
          )}
        </div>
      </ModalBody>
    </SlidingMenu>
  );
}
