import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { FormItem, FormLabel } from '../../ExpressAppUI';
import { toMoney } from '../../helpers';

const RangedAmountInput = ({
  amount, errors, formBlur, hasError,
}) => (
  <FormItem>
    <FormLabel>
      <span className="label-text">Bond Amount</span>
      <i className="sub-label">
        Amounts range from {toMoney(amount.low)} to {toMoney(amount.high)}.
      </i>
    </FormLabel>

    {errors.bondAmount && <p className="small-error">{errors.bondAmount}</p>}

    <MaskedInput
      data-inputtype={'ppk'}
      data-inputlow={amount.low}
      data-inputhigh={amount.high}
      hasError={hasError('bondAmount')}
      name="bondAmount"
      onBlur={formBlur}
      required
      mask={createNumberMask({
        prefix: '$',
        requireDecimal: true,
        decimalLimit: 2,
        guide: true,
        keepCharPositions: true,
      })}
      placeholder={'Please Enter an amount'}
      defaultValue=''
      className={`form-input ${hasError('bondAmount') ? 'has-error' : ''}`}
    />
  </FormItem>
);

const AmountSelectionCard = ({
  amount: {
    low, high, priceTag, type,
  }, idx, id, handleChange, checked, showPricing,
}) => {
  // Creates our money formatted label for amount i.e. $XX.XX (if range $XX.XX - $XX.XX)
  let label;
  switch(type){
    case 'fixed':
      label = toMoney(low);
      break;
    case 'unknown':
      label = 'I don\'t know my bond amount.'
      break;
    default:
      label = `${toMoney(low)} - ${toMoney(high)}`;
      break;
  }


  return (
      <>
        <input
          type="radio"
          className="custom-checkbox"
          defaultChecked={
            checked
          }
          value={low}
          id={id}
          data-index={idx}
          name="bondAmount"
          onChange={handleChange}
          required
          data-inputtype="radio"
        />
        <label
          className={`toggle-label price-box ${!showPricing ? 'all-uw' : ''}`}
          htmlFor={id}
        >
          <div className="price-box__label">{label}</div>
          <div className="price-box__details">
            {priceTag !== 'Varies' && (
              <>
              <i className="fa fa-cart-plus" style={{ marginRight: '5px', color: '#F89406' }}></i>
              <>Buy Now
              </>
              </>
            )}
          </div>
          <div className="price-box__premium">
            {priceTag !== 'Varies' ? (
              <>
                <div className="amount ip">
                  {toMoney(priceTag, { noCents: false })}
                </div>
              </>
            ) : (
              <div className="amount uw"> Price Varies </div>
            )}
          </div>
        </label>
      </>
  );
};

export function BondAmounts({
  amounts,
  currentAmount,
  formBlur,
  hasError,
  errors,
  isTitleBond,
  resetBondAmount,
}) {

  // Grab the matching amount from amounts array
    const matchedAmount = amounts.find((a) => currentAmount >= a.low && currentAmount <= a.high);
  // If there was a match and it is PPK, preset that amount so the the UI
  // will ask the user to specify their amount within the range
  const [needsSpecificAmount, setNeedsSpecificAmount] = React.useState(matchedAmount && matchedAmount.type === 'range' && matchedAmount);

  const hasRangedAmount = amounts.filter((a) => a.priceTag === 'Varies').length > 0;


  const MultipleAmounts = ({ amounts, currentAmount }) => {
    const allUnderwritten = React.useRef(
      amounts.filter((a) => a.priceTag !== 'Varies').length === 0,
    );

    const handleAmountSelection = (e) => {
      const selectedAmount = amounts[e.target.dataset.index];
      if (selectedAmount.type === 'range') {
        setNeedsSpecificAmount(selectedAmount);
      } else {
        formBlur(e);
      }
    };

    return (
      <>
        <div
          className="radio-wrap"
          style={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            border: `${hasError('bondAmount') ? '2px solid crimson' : 'none'}`,
          }}
        >
          {amounts.map((a, idx) => {
            const isChecked = !needsSpecificAmount && (matchedAmount && matchedAmount.low.toString() === a.low.toString());
            const key = `bondAmountSelection-${idx}`;
            return (
              <AmountSelectionCard
                amount={a}
                key={key}
                id={key}
                idx={idx}
                handleChange={handleAmountSelection}
                checked={isChecked}
                showPricing={!allUnderwritten.current}
              />
            );
          })}
          {(hasRangedAmount &&
          <AmountSelectionCard
                amount={{low:'0', high:0, priceTag:'Varies', type:'unknown'}}
                key={`bondAmountSelection-${amounts.length}`}
                id={`bondAmountSelection-${amounts.length}`}
                idx={amounts.length}
                handleChange={handleAmountSelection}
                checked={true}
                showPricing={false}
              />)}

        </div>
      </>
    );
  };

  const renderAmountSelect = (amounts) => {
    if (isTitleBond || !amounts.length) {
      return (
          <input
            type="hidden"
            name="bondAmount"
            value={0}
            required
          />
      );
    }
    if (amounts.length === 1) {
      if (amounts[0].type === 'range') {
        return (
          <RangedAmountInput
            amount={amounts[0]}
            errors={errors}
            formBlur={formBlur}
            hasError={hasError}
          />
        );
      }
      return (
          <input
            type="hidden"
            name="bondAmount"
            value={currentAmount}
            required
          />
      );
    }
    return (
        <>
          {needsSpecificAmount ? (
            <div className="bondSelectionSlider">
              <RangedAmountInput
                amount={needsSpecificAmount}
                errors={errors}
                formBlur={formBlur}
                hasError={hasError}
              />
              <button
                class=""
                type="button"
                onClick={ () => {
                  setNeedsSpecificAmount(false);
                  resetBondAmount();
                }}
              >
                Choose different amount
              </button>
            </div>
          ) : (
            <FormItem>
              <FormLabel>Bond Amount</FormLabel>
              {errors.bondAmount && (
                <p className="small-error">{errors.bondAmount}</p>
              )}
              <MultipleAmounts
                amounts={amounts}
                currentAmount={currentAmount}
              />
            </FormItem>
          )}
        </>
    );
  };

  return renderAmountSelect(amounts);
}
