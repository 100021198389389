import Axios from 'axios';
import React, { useEffect } from 'react';
import { FormItem, FormLabel, WideSubmitButton } from '../../ExpressAppUI';
import { useFormState } from '../../hooks';
import { Mixpanel } from '../../mixpanel';

import './SelectBond.css';

import { BondAmounts } from './BondAmounts';
import { BondSelection } from './BondSelection';

export function SelectBond(props) {
  //* * UI AND NON-FORM STATE **/
  // Create our form Ref
  const form = React.useRef(false);

  // Holds bond docs as fetched from API
  const [bonds, setBonds] = React.useState(false);

  // Flag used in UI to render certain elements.
  const allIp = React.useRef(null);

  // Used to display bond type of resolved bonds
  const bondType = React.useRef(null);

  // UI STATE for form Submitting
  const [submitting, setSubmitting] = React.useState(false);

  //* * EVENT HANDLERS */

  // Our Submit Function we pass to form hook
  async function handleSubmit() {
    // Update our UI State
    setSubmitting(true);

    // Update's lead and returns which path to send to
    const result = await Axios.post(
      `/start-application/${props.nonce}/routeLead`,
      {
        bondId: values.selectedBond,
        bondAmount: values.bondAmount,
      },
    );

    // Determine where we send client (Express App, Title Bond, IP)
    if (result.data.type === 'application') {
      // Since IP is not React app, change URL to fill app path
      window.location = result.data.url;
    } else {
      // Use Reach Router baked in navigation to navigate to Title-Bonds or EA
      props.navigate(result.data.url);
    }
  }

  // Handler for Bond Selection
  const handleBondChange = async (value, state, props) => {
    // Selected Bond
    const selectedId = value.value;

    // If we don't have a bond, reset all of our fields.
    if (selectedId) {
      setErrors({ ...errors, selectedBond: null, bondAmount: null });
    }

    // If not Sure, we need to default bond amount to zero.
    if (selectedId === 'Not Sure') {
      setValues({
        ...values,
        selectedBond: selectedId,
        bondAmount: '0',
      });
    } else {
      // Otherwise grab our selectedBond document.
      const selectedBond = bonds.filter((b) => b.id === selectedId)[0];

      // Only one amount? -- assume that amount
      if (selectedBond.amounts.length === 1) {
        setValues({
          ...values,
          bondAmount: selectedBond.amounts[0].low,
          selectedBond: selectedId,
        });
      } else {
        // Multiple amounts? -- set bond Amount to empty to prompt selection from user
        setValues({ ...values, bondAmount: '', selectedBond: selectedId });
      }
    }
  };

  //* * SET UP OUR FORM STATE */
  const [
    values,
    // eslint-disable-next-line no-unused-vars
    touched,
    errors,
    formBlur,
    submitForm,
    { hasError, setValues, setErrors },
  ] = useFormState(
    {
      selectedBond: '',
      bondAmount: '',
    },
    ['bondAmount', 'selectedBond'],
    handleSubmit,
    form,
  );

  //* * On Mount Only (See empty arr as second arg) run This Effect */
  // Grabs our needed data from API and unblocks render after setting bonds
  useEffect(() => {
    // Fetches our resolve Bonds and lead info from API
    async function getResolveInfo() {
      const { data } = await Axios.post(
        `/start-application/${
          props.nonce
        }/get-resolve-bonds/?timestamp=${new Date().getTime()}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );

      // Update our UI Refs
      bondType.current = data.type;
      allIp.current = data.allIp;

      // If we no longer need to resolve our lead, refetch the page -- which triggers our redirect middleware on the Express Server
      if (!data.needsResolve) {
        window.location = `/start-application/${
          props.nonce
        }/?timestamp=${new Date().getTime()}`;
      }
      // Otherwise continue on with Start App
      // We only have one bond? TODO: Trigger this
      else if (data.bonds.length === 1) {
        if (data.bonds[0].allUW) {
          alert(
            'We can go straight into express app!! All amounts are underwritten',
          );
        } else {
          alert('We need to select an amount');
          setBonds(data.bonds);
          setValues({ ...values, selectedBond: data.bonds[0] });
        }
      } else {
        // Otherwise set our bonds to trigger render of component.
        setBonds(data.bonds);
      }
      // Identify mixpanel
      Mixpanel.identify(props.nonce);

      const trackProps = {
        'Lead ID': data.leadId,
        'Nonce ID': props.nonce,
        'Bond Type': data.type,
        'Bond State': data.state,
        Type: 'Instant Issue',
      };

      Mixpanel.register(trackProps);

      Mixpanel.track('Bond Selection');

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        leadId: data.leadId,
        userId: data.leadId,
        bondType: data.type,
        bondState: data.state,
        nonceId: props.nonce,
        allInstantPurchase: data.allIp,
      });

      window.dataLayer.push({
        event: 'SelectBondEvent',
        eventCategory: 'Start App Pageview',
        eventAction: 'Start App Lead Creation',
        eventLabel: `${data.state} - ${data.type}`,
        eventValue: data.leadId,
      });
    }

    // Get Bonds
    getResolveInfo();
  }, []);
  return (
    <>
      {!bonds ? null : (
        <form ref={form}>
          <div className="panel-title text-center"> Bond Selection </div>
          <FormItem>
            <FormLabel>Which {bondType.current} ?</FormLabel>
            {hasError('selectedBond') && (
              <p className="small-error">Please Select A Bond</p>
            )}
            <BondSelection
              changeHandler={handleBondChange}
              values={values}
              bonds={bonds}
              error={hasError('bondAmount')}
              allIp={allIp.current}
            />
            <input
              type="hidden"
              name="selectedBond"
              required
              value={values.selectedBond}
            />
          </FormItem>
          {values.selectedBond && (
            <BondAmounts
              bonds={bonds}
              values={values}
              hasError={hasError}
              errors={errors}
              formBlur={formBlur}
              setValues={setValues}
            />
          )}
          <WideSubmitButton
            onClick={(e) => submitForm(e, () => window.scrollTo(0, 0))}
            disabled={submitting}
          >
            {submitting ? (
              <>
                {'Saving     '}
                <i class="fa fa-spinner fa-spin" />
              </>
            ) : (
              `${allIp.current ? 'Buy Now' : 'Save And Continue'}`
            )}
          </WideSubmitButton>
        </form>
      )}
    </>
  );
}
