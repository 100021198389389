import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import Layout from './Layout';
import PurchaseSummary from './PurchaseSummary';
import ShippingSummary from './ShippingSummary';
import BondInformation from './BondInformation';
import PrincipalInformation from './PrincipalInformation';
import TransactionSummary from './TransactionSummary';
import { initTrackConfirmationPage, trackBondDocs } from '../tracking';

const LineBreak = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

const PageTitle = ({ contact, policyReady, autoRenewalReady, switchingToHCCReady, originalRequired }) => {
  let message = 'Your order has been submitted!';

  if (switchingToHCCReady) {
    message = 'Your official bond documents are ready!';
  } else if (autoRenewalReady) {
    message = 'Your official continuation certificate is ready!'
  } else if (policyReady) {
    if (originalRequired) message = 'A copy of your official bond documents is ready!';
    else message = 'Your official bond documents are ready!';
  } else if (contact.firstName) {
    message = `Your order has been submitted, ${contact.firstName}!`;
  }

  return message;
};
// TODO: Finish the originalRequired wording changes here.

const PageMessage = ({ policyReady, originalRequired, autoRenewalReady, switchingToHCCReady, nonce }) => {
  // If the policy's not ready, dip out
  if (!policyReady && !autoRenewalReady && !switchingToHCCReady) {
    return (
      <>
        Please note that all documentation must be approved before you receive your bond confirmation via email.
      </>
    );
  }

  let message = '';
  let buttonText = '';

  if (switchingToHCCReady) {
    message = <>
      Once you’ve <strong style={{ color: '#0a6ba1' }}>downloaded</strong> your renewal bond, review the document for accuracy. To complete the renewal process, print and sign the renewal bond before submitting it to the obligee to keep your account in good standing
    </>;

    buttonText = 'Download My Renewal Bond';
  } else if (autoRenewalReady) {
    message = <>
      Download your <strong style={{ color: '#0a6ba1' }}>continuation certificate</strong> for your reference.
    </>;

    buttonText = 'Download My Certificate';
  } else if (policyReady && !originalRequired) {
    // Base vars on "digital delivery" bonds
    message = <>
      Once you've <strong style={{ color: '#0a6ba1' }}>downloaded</strong> your bond, review the document for accuracy. To complete the bonding process, print and sign the bond before submitting it to the obligee
    </>;

    buttonText = 'Download My Bond';

    // If we've got a Surety180 bond, swap out some text
  } else if (policyReady && originalRequired) {
    message = <>
      Download a <strong style={{ color: '#0a6ba1' }}>copy of your bond</strong> for your reference. Your official bond, along with filing instructions, will be delivered by FedEx.
    </>;

    buttonText = 'Download A Copy Of My Bond';
  }

  // Send back that sweet, sweet JSX.
  return (
    <>
      <form action={`/purchase-bond/${nonce}/get-bond-doc`} method='post'>
        <p className='hide-on-print'>
          <input type="hidden" name="autoRenewalReady" value={autoRenewalReady} />
          <button type="submit" onClick={(e) => trackBondDocs(autoRenewalReady)}>{buttonText}<i className="fa fa-download" style={{ marginLeft: '5px' }}></i></button>
        </p>
      </form>
      <p>{message}</p>
    </>
  );
};

const Confirmation = (props) => {
  const [state, setState] = useState({ loading: true });
  useEffect(() => {
    // check if user should be here

    // The function that gets our state
    async function getAppState() {
      const { data } = await Axios.get(
        `/purchase-bond/${props.nonce
        }/get-app-state/?timestamp=${new Date().getTime()}&page=paid`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );

      initTrackConfirmationPage(props.nonce, data);

      // Dispatch that we can update our state with data
      setState({ loading: false, ...data });
    }

    // Set our title
    document.title = 'Surety Bonds | Purchase Your Bond';

    // Get our state from nonce and set it by dispatching INIT
    getAppState();
  }, []);

  const CheckoutTitle = styled.div`
    color: #fff;
    background-color: #0d8bd1;
    padding: 15px 20px;
    border-radius: 8px 8px 0px 0px;
    height: 30px;
  `;
  const TitleH3 = styled.h3`
    display: inline;
    color: #fff;
    font-weight: 300;
  `;

  return (
    <>
      {state.loading ? null : (
        <>
          <Layout
            title={
              <PageTitle contact={state.contact} autoRenewalReady={state.autoRenewalReady} policyReady={state.policyReady} switchingToHCCReady={state.switchingToHCCReady} originalRequired={state.shipping.showShipping} />
            }
            message={
              <PageMessage autoRenewalReady={state.autoRenewalReady} policyReady={state.policyReady} switchingToHCCReady={state.switchingToHCCReady} originalRequired={state.shipping.showShipping} nonce={props.nonce} />
            }
            rightBox={{
              bond: state.bond,
              charges: state.invoice.charges,
              balance: state.balance,
            }}
            isIP={state.isIP}
            salesRep={state.salesRep}
            noPadding
          >
            <CheckoutTitle>
              <i className='icon icon-file-text' style={{ fontSize: '2em', marginRight: '10px' }}></i>
              <div className='hide-on-print' style={{ display: 'inline' }}><TitleH3>Invoice</TitleH3></div>
              <button style={{ float: 'right', padding: '0', background: 'none' }} onClick={() => window.print()}>
                <i className='icon icon-printer btn-icon' style={{ float: 'right', fontSize: '2em' }}></i>
              </button>
            </CheckoutTitle>
            <div style={{ padding: '30px' }}>
              <PurchaseSummary
                balance={state.balance}
                selectedShipping={state.shipping.selectedShipping}
                MYDLength={state.myd.value}
                isMYD={state.myd.isMYD}
                charges={state.invoice.charges}
                bondName={state.bond.name}
                bondState={state.bond.state}
                isConfirmationPage={true}
              >
              </PurchaseSummary>
              <TransactionSummary
                charges={state.invoice.charges}
              >

              </TransactionSummary>
              <LineBreak />
              <ShippingSummary
                shippingAddress={state.shippingAddress}
                selectedShipping={state.shipping.selectedShipping}
              >
              </ShippingSummary>
              <LineBreak />
              <BondInformation
                type={state.bond.name}
                state={state.bond.state}
                coverageAmount={state.bond.amount}
                term={`${state.bond.effectiveDate} - ${state.bond.expirationDate}`}
              >
              </BondInformation>
              <LineBreak />
              <PrincipalInformation
                company={state.company}
              >

              </PrincipalInformation>
            </div>

          </Layout>
        </>
      )}
    </>
  );
};

export default Confirmation;
