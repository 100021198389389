import React from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useParams } from "@reach/router";
import { useLead } from "../client";

import "../App.css";

import {
  RefactoredPurchaseBondLayout,
  LoadingShell,
} from "./components/Layout";
import { LineBreak } from "../ExpressAppUI";
import { CardFields } from "../common/forms/fields";
import { MydSelection } from "./components/MultiYearDiscount";
import { NewPurchaseSummary } from "./components/PurchaseSummary";
import { TermsAndConditions } from "./components/TermsAndConditions";
import { APIClient } from "../client";
import { useTrackPurchasePage } from "./tracking";
import { usePurchaseBondForm } from "./purchase-bond-form";
import { ShippingAddress } from "./AddressSelection";
import { AddressModal } from "./AddressSelection/AddressModal";
import { ShippingMethod } from "./components/ShippingMethod";
import { useIsMutating } from "react-query";

function GetPurchaseBondShell(props) {
  const { lead, invoice } = useLead();
  if (!lead) return <LoadingShell title="Secure Checkout" />;
  else return <PurchaseBond lead={lead} invoice={invoice} />;
}

function PurchaseBond({ lead, invoice }) {
  useTrackPurchasePage();
  useHandleRedirects(lead, invoice);
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    error,
  } = usePurchaseBondForm();
  const askForShipping = Object.keys(initialValues).includes(
    "selectedShippingMethod"
  );
  const [activeAddress, setActiveAddress] = React.useState({
    address: false,
    viewMode: false,
    canEdit: false,
  });
  const askForMYD = Object.keys(initialValues).includes("multiTerm");
  const isMutating = useIsMutating({ mutationKey: "lead" })

  return (
    <RefactoredPurchaseBondLayout>
      <CheckoutTitle>
        <i
          className="icon icon-credit-card"
          style={{ fontSize: "2em", marginRight: "10px" }}
        ></i>
        <div style={{ display: "inline" }}>
          <TitleH3>Finalize Purchase</TitleH3>
        </div>
      </CheckoutTitle>

      {askForShipping && (
        <AddressModal
          activeAddress={activeAddress}
          setActiveAddress={setActiveAddress}
        />
      )}

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form style={{ padding: "30px" }}>
              {error && (
                <div className="tab-div warning invalid active">
                  <i className="icon icon-low-credit icon-warning"></i>
                  <p className="tab-content">
                    {error}
                    <br />
                    Give us a call at{" "}
                    <a href="tel:+18003084358">1(800)308-4358</a> if you would
                    like to talk to a representative
                  </p>
                </div>
              )}
              {askForMYD && <MydSelection />}
              {askForShipping && (
                <>
                  <ShippingAddress
                    activeAddress={activeAddress}
                    setActiveAddress={setActiveAddress}
                  ></ShippingAddress>
                  <LineBreak />
                  <ShippingMethod />
                </>
              )}
              {!askForShipping && (
                <>
                  <h5 className="panel-title">
                    {lead.prevPolicy
                      ? "Your renewal does not require new physical documents."
                      : "Your official bond documents will be emailed"}
                  </h5>
                  {!lead.prevPolicy && (
                    <p className="checkout-explanation smaller">
                      When you receive your bond, please review the document for
                      accuracy. To complete the bonding process, you must print
                      and sign the bond before submitting it to the obligee.
                    </p>
                  )}
                </>
              )}
              <NewPurchaseSummary />
              <CardFields />
              <TermsAndConditions />
              <PurchaseButton
                style={{ marginTop: "1rem" }}
                disabled={props.isSubmitting || isMutating > 0}
                type="submit"
              >
                {props.isSubmitting ? <i className="fa fa-spinner fa-spin" /> : 'Complete Purchase'}
              </PurchaseButton>
            </Form>
          );
        }}
      </Formik>
    </RefactoredPurchaseBondLayout>
  );
}

function useHandleRedirects(lead, invoice) {
  const { nonce } = useParams();
  // fetch customer to see if there is claim
  React.useEffect(() => {
    if (lead.customer) {
      async function checkForClaim(customerId) {
        const { data } = await APIClient(
          `customers/${customerId}/check-for-claim`,
          { method: 'POST' }
        );
        if (data?.hasClaim) {
          window.location = `/purchase-bond/${nonce}/customer-has-claim`;
        }
      }
      checkForClaim(lead.customer.toString());
    }
  }, [lead.customer, nonce]);

  // check for paid invoice and rejected leads
  React.useEffect(() => {
    const invoicePaidStatus =
      lead.status === "Invoice Paid" ||
      lead.statusHistory.includes("Invoice Paid");
    if (invoice.balance === 0 && invoicePaidStatus) {
      window.location = `/purchase-bond/${nonce}/paid`;
    }

    if (lead.status === "Rejected") {
      window.location = `/purchase-bond/${nonce}/invoice-not-ready`;
    }
  }, [lead.status, invoice.balance, lead.statusHistory, nonce]);
}

const CheckoutTitle = styled.div`
  color: #fff;
  background-color: #0d8bd1;
  padding: 15px 20px;
  border-radius: 8px 8px 0px 0px;
  height: 30px;
`;

const TitleH3 = styled.h3`
  display: inline;
  color: #fff;
  font-weight: 300;
`;

const PurchaseButton = styled.button`
  height: 50px;
  margin-top: 25px;
  background-color: #99c432;
  font-weight: 100;
  font-size: 18px;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  &:disabled {
    background-color: #b5b5b7;
    cursor: default;
    &:hover {
      background-color: #b5b5b7;
    }
  }
  &:hover,
  :active {
    background-color: #a7da00;
  }
`;

export default GetPurchaseBondShell;
