import React from 'react';

// import styles from './Header.module.css'; // Import css modules stylesheet as styles

export function Header() {
  return (
    <header className="Header_container">
      <div className="Header_wrapper">
      <div
        href="/"
        className={"Header_logo"}
      >
        <img className="hide-on-print" style={{width: '100%'}} src='/img/brand/logo-color-white.svg'></img>
        <img id="print-only-logo" style={{width: '100%'}} src='/img/brand/logo-color-black.svg'></img>
      </div>
      <div className={"Header_hours-and-phone"}>
        <a
          href="tel:+18003084358"
          className={"Header_phone"}
        >
          {/* <PhoneIcon className="w-4" /> */}
          <span>1 (800) 308-4358</span>
        </a>
        <div className={"Header_hours"}>
          Mon-Fri 7am-7pm CST
        </div>
      </div>
      </div>
     
    </header>
  )
}



export default Header;
