import { Component } from 'react';

export default class ScrollTo extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.submitted) {
      window.scrollTo({
        top: document.getElementById('root').offsetTop,
        behavior: 'smooth',
      });
    } else if (
      this.props.activePage !== prevProps.activePage
      || this.props.activeContact !== prevProps.activeContact
    ) {
      if (this.props.activePage === 'verify') {
        window.scrollTo({
          top: document.getElementById('root').offsetTop,
        });
      } else if (this.props.type === 'mobile') {
        const activePage = document.getElementById(this.props.activePage).offsetTop + -170;
        window.scrollTo({
          top: activePage,
          behavior: 'smooth',
        });
      }
    }
  }

  render() {
    return this.props.children;
  }
}
