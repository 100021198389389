import React from 'react';
import '../App.css';
import { TitleContext } from '../titleContext';

const BONDS_REQUIRING_EXPLANATION = ['4edc577f7c03738a9d57bacf', '51228e50ffd4f20b070095d7'];

function ExplanationList({ bondId }) {
  if (BONDS_REQUIRING_EXPLANATION.includes(bondId)) { // Special explanation for certain title bonds
    return (
      <li>Please send your pre-filled bond form to <a class="email" href="mailto:customercare@suretybonds.com">customercare@suretybonds.com</a> to finalize your bond request. You can also discuss your bonding needs over the phone at <a href="tel:+18003084358"> 1 (800) 308-4358</a> with our surety specialists.</li>
    )
  } else { // Default explanation for all other rejection letter title bonds
    return (
      <>
        <li>Your personal surety expert will contact you shortly to explain what is needed to finalize your application.</li>
        <li>Once your application has been approved, you’ll receive an emailed invoice so you can purchase your bond online quickly and easily.</li>
        <li>If you have any questions, please call <a href="tel:+18003084358"> 1 (800) 308-4358</a> at any time.</li>
      </>
    )
  }
} 

const EndPage = () => {
  const { state } = React.useContext(TitleContext);
  return (
  <>
    <h3 className="panel-title" style={{ textAlign: 'center' }}>
      Thanks for submitting your Bond!
    </h3>
    <p className="helper-text">
      { state.rejectionLetterRequired ? 'We cannot issue this bond online because your state requires additional documentation.' : 'Due to the bond amount you provided, we cannot issue this bond online.'}
    </p>
    <p className="helper-text">What's Next?</p>
    <ul style={{ textAlign: 'left' }}>
      <ExplanationList bondId={state.bondId} />
    </ul>
    <p className="helper-text">
      Thanks again for choosing SuretyBonds.com. We look forward to working with
      you!
    </p>
  </>
  );
};

export default EndPage;
