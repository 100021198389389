import React from 'react';
import Axios from 'axios';
import Layout from '../purchase-bond/components/Layout';
import ContactPanel from './components/panels/ContactPanel';
import BondPanel from './components/panels/BondPanel';
import PrincipalPanel from './components/panels/PrincipalPanel';
import { InstantPurchaseProvider } from './InstantPurchaseContext';
import { getQueryVariable } from '../helpers';
import { initTrackInstantPurchase } from './tracking';
// TODO: CN-1498 use company collection from lead starting line 26
const InstantPurchaseReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return { ...state, ...action.payload, loading: false };
    case 'CHANGE ACTIVE STEP':
      return { ...state, activePanel: action.activePanel };
    case 'UPDATE CONTACT INFO':
    {
      const leadUpdates = { name: { first: action.firstName, last: action.lastName }, email: action.email, phone: action.phone };
      return { ...state, lead: { ...state.lead, ...leadUpdates }, activePanel: 'Principal' };
    }
    case 'UPDATE PRINCIPAL INFO':
    {
      const leadUpdates = {
        company_name: action.companyName, company_dba: action.companyDBA, company_address: action.companyAddress, company_address2: action.companyAddress2, company_state: action.companyState, company_zip: action.companyZip, company_city: action.companyCity,
      };
      return { ...state, lead: { ...state.lead, ...leadUpdates }, activePanel: 'Bond' };
    }
    case 'UPDATE BOND INFO':
    {
      return { ...state, activePanel: 'Complete' };
    }
    case 'UPDATE INVOICE AND BALANCE':
    {
      return { ...state, invoice: action.invoice, balance: action.balance };
    }
    default:
      throw new Error();
  }
};

function InstantPurchase(props) {
  // Set up app state and dispatcher function
  const [state, dispatch] = React.useReducer(InstantPurchaseReducer, {
    nonce: props.nonce,
    loading: true,
    activePanel: 'Contact',
    isComplete: false,
    isEdit: getQueryVariable('edit') || false,
  });
  // On Mount we need to get our app state
  React.useEffect(() => {
    // The function that gets out state
    async function getAppState() {
      const search = window.location.search ? `${window.location.search}&timestamp=${new Date().getTime()}` : `?timestamp=${new Date().getTime()}`;
      const { data } = await Axios.get(
        `/application/${
          props.nonce
        }/get-app-state/${search}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );

      // Go to purchase page if complete and not edit mode
      if (!state.isEdit && data.isComplete) {
        window.location = `/purchase-bond/${props.nonce}`;
      }

      const { lead, balance } = data;

      if (balance === 0 && (lead.status === 'Invoice Paid' || (lead.statusHistory.indexOf('Invoice Paid') >= 0))) {
        // return res.redirect(`/purchase-bond/${req.params.nonce}/paid`);
        props.navigate(`/purchase-bond/${props.nonce}/paid`);
      }

      if (lead.status === 'Rejected') {
        window.location = `/purchase-bond/${props.nonce}/invoice-not-ready`;
        // return res.redirect(`/purchase-bond/${req.params.nonce}/invoice-not-ready`);
      }

      // Update our page title
      document.title = 'Surety Bonds | Purchase Your Bond';

      // Track our stuff!
      initTrackInstantPurchase(props.nonce, { ...data, isEdit: state.isEdit });

      // Dispatch that we can update our state with data
      dispatch({ type: 'INIT', payload: data });
    }

    // Get our state from nonce and set it by dispatching INIT
    getAppState();
  }, []);

  React.useLayoutEffect(() => {
    async function handleCompletedApp() {
      // If we are "complete"
      if (state.activePanel === 'Complete') {
        // If we are not editing, we need to set our verification flags
        if (!state.isEdit) {
          const { data } = await Axios.get(
            `/application/${
              props.nonce
            }/check-for-completion`,
            {
              headers: {
                'Cache-Control': 'no-cache',
              },
            },
          );
        }
        // Regardless, we replace our browser history with the edit version of the page and navigate to the purchase page
        window.history.replaceState({}, '', `/application/${props.nonce}/fill?edit=${true}`);
        window.location = `/purchase-bond/${props.nonce}`;
      }
    }

    handleCompletedApp();
  }, [state.activePanel]);
  return (
   <InstantPurchaseProvider>
     {state.loading ? null : (
       <Layout
        noPadding
        isIP={true}
        color={'none'}
        progressTracker={true}
        currentStep='Enter Your Info'
        canEdit={state.isComplete}
        nonce={props.nonce}
        rightBox={{
          bond: state.bond,
          charges: state.invoice.charges,
          balance: state.balance,
        }}>
         <ContactPanel multiTerm={state.multiTerm} bondInfo={{ bondAmount: state.bond.amount, bondId: state.bond.id, bondState: state.bond.state }} nonce={props.nonce} isActive={state.activePanel === 'Contact'} dispatch={dispatch} lead={state.lead} >
         </ContactPanel>
         <PrincipalPanel nonce={props.nonce} isActive={state.activePanel === 'Principal'} dispatch={dispatch} lead={state.lead} bond={state.bond} ></PrincipalPanel>
         <BondPanel nonce={props.nonce} dispatch={dispatch} lead={state.lead} bond={state.bond} isActive={state.activePanel === 'Bond'}></BondPanel>
       </Layout>
     )}
   </InstantPurchaseProvider>
  );
}

export default InstantPurchase;
