import React from 'react';
import Select from 'react-select';
import { toMoney } from '../../helpers';

export const BondSelection = ({
  bonds,
  values,
  changeHandler,
  required,
  allIp,
}) => {
  // if all amounts on all bonds are single amounts -- we want to show the amount in the dropdown
  const allSingleAmount = bonds && bonds.filter((b) => b.amounts.length !== 1).length === 0;
  // Function used to render how selection appears in dropdown
  const getLabel = (bond) => {
    let preface = '';
    if (allSingleAmount) {
      // choose only amount
      const amount = bond.amounts[0];
      // render range
      if (amount.high && amount.low && amount.high !== amount.low) {
        preface = `${toMoney(amount.low)} - ${toMoney(amount.high)} `;
      } else {
        // render low amount
        preface = `${toMoney(amount.low)} `;
      }
    }
    return `${preface}${bond.name}`;
  };
  const renderSelection = (bond) => {
    const label = getLabel(bond);
    const priceTag = bond.amounts.length === 1 && bond.amounts[0].priceTag !== 'Varies'
      ? toMoney(bond.amounts[0].priceTag)
      : 'Price Varies';
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className="bond-name">
          {label}
        </div>
        {priceTag === 'Price Varies' ? (
          <div
            className="bond-premium"
            style={{
              color: '#777',
              borderRadius: '5px',
              width: '90px',
              textAlign: 'right',
              fontWeight: '500',
            }}
          >
            {priceTag}
          </div>
        ) : (
          <div
            className="bond-premium"
            style={{
              padding: '3px',
              color: 'white',
              background: '#f89406',
              border: '2px solid #f89406',
              borderRadius: '5px',
              width: '60px',
              textAlign: 'center',
              fontWeight: '600',
            }}
          >
            {priceTag}
          </div>
        )}
      </div>
    );
  };
  // Create our option array for our dropdown
  const options = bonds.map((b) => ({
    label: renderSelection(b),
    value: b.id,
    searchText: getLabel(b),
    amounts: b.amounts,
    state: b.state,
  }));
  // Add Not Sure option if we are not all instant purchase
  const customOptions = !allIp
    ? [...options, { value: 'Not Sure', label: 'Not Sure', searchText: 'Not Sure' }]
    : options;
  // Custom Filter function used in dropdown when search is used
  const filterOptions = (candidate, input) => {
    if (candidate.value === 'Not Sure') {
      return true;
    }
    if (input) {
      if (candidate.data.searchText.toLowerCase().includes(input.toLowerCase())) {
        return true;
      }
      return false;
    }
    return true;
  };
  // Finally Render our component
  return (
    <Select
      classNamePrefix="bond-selection"
      options={customOptions}
      filterOption={filterOptions}
      onChange={changeHandler}
      defaultValue={values.selectedBond}
    />
  );
};
