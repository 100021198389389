import { Mixpanel } from '../mixpanel';
import { getQueryVariable } from '../helpers';

export const trackLeadMade = ({ leadId, email, phone_number, bondAmount, bondId, bondState }) => {
  Mixpanel.register({ 'Lead ID': leadId });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    leadId,
  });

  // trigger AW conversion
  window.dataLayer.push({
    event: 'Instant Purchase Form Complete',
    bondId,
    bondAmount,
    bondState,
    ecData: {
      email,
      phone_number,
    },
  });

  window.dataLayer.push({
    event: 'IPEvent',
    eventCategory: 'IP Lead Created',
    eventAction: 'IP Contact Info Saved',
  });
};
export const initTrackInstantPurchase = (nonce, data) => {
  // Init mixpanel and datalayer
  Mixpanel.identify(nonce);
  window.dataLayer = window.dataLayer || [];
  const isRemarket = getQueryVariable('isRemarket');
  let term = '1';
  if (data.noLead && getQueryVariable('term')) {
    term = getQueryVariable('term');
  } else {
    term = data.lead.app && data.lead.app.multiTerm;
  }
  const mixpanelTrack = {
    'Nonce ID': nonce,
    'Bond ID': data.bond.id,
    'Bond Type': data.bond.name,
    'Bond Amount': data.bond.amount,
    'Bond State': data.bond.state,
    'Bond Version': data.bond.version,
    Type: 'Instant Issue',
    'Term Length': term,
    'Bond Premium': data.invoice && data.invoice.charges && data.invoice.charges.filter((c) => c.category === 'Bond Premium')[0].amount || 'N/A',
    'Shipping Required': data.bond.originalRequired,
  };
  if (isRemarket) {
    mixpanelTrack.isRemarket = true;
  }
  Mixpanel.register(mixpanelTrack);
  Mixpanel.track(data.isEdit ? 'Edit Application Page' : 'Application Page');
  // In order for our chat widget to track variables -- we pass them to the global layer
  window.leadId = data.lead._id;
  window.bondType = data.bond.name;
  window.bondState = data.bond.state;
  window.bondAmount = data.bond.amount;
  const dataLayerTrack = {
    leadId: data.lead._id,
    userId: data.lead._id,
    bondId: data.bond.id,
    bondType: data.bond.name,
    bondState: data.bond.state,
    bondAmount: data.bond.amount,
    bondVersion: data.bond.version,
    nonceId: nonce,
    ecData: {
      email: data.lead.email || '',
      phone_number: data.lead.phone || '',
    },
    event: 'Data Layer Ready',
  };
  if (isRemarket) {
    dataLayerTrack.isRemarket = true;
  }
  window.dataLayer.push(dataLayerTrack);
};

export const updateBondState = (bondState) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ bondState });
  Mixpanel.register({ 'Bond State': bondState });
};

export const trackPanelSave = (panelName) => {
  Mixpanel.track(`${panelName} - Save`);
};
