/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { FormItem, FormLabel } from '../../ExpressAppUI';
import { toMoney } from '../../helpers';

// Multiple Amounts -- user needs to select one
const MultipleAmounts = ({
  amounts,
  selectedAmount,
  handleChange,
  hasError,
}) => {
  // Are all bonds amounts underwritten? -- used for UI purposes (show price vs dont show price)
  const allUnderwritten = React.useRef(
    amounts.filter((a) => a.priceTag !== 'Varies').length === 0,
  );
  // Are all bonds amounts Instant Purchase? -- used for UI purposes (show price vs dont show price)
  const allIp = React.useRef(
    amounts.filter((a) => a.priceTag === 'Varies').length === 0,
  );
  return (
    <div
      className='radio-wrap'
      style={{
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        border: `${hasError('bondAmount') ? '2px solid crimson' : 'none'}`,
      }}
    >
      {amounts.map((a, idx) => {
        const isChecked = selectedAmount && selectedAmount.toString() === a.low.toString();
        const key = `bondAmountSelection-${idx}`;
        return (
          <AmountSelectionCard
            amount={a}
            key={key}
            id={key}
            idx={idx}
            handleChange={handleChange}
            checked={isChecked}
            showPricing={!allUnderwritten.current}
            allIp={allIp.current}
          />
        );
      })}
    </div>
  );
};
// Clickable 'radio' button that displays amount / price
const AmountSelectionCard = ({
  amount: {
    low, high, priceTag, type,
  },
  idx,
  id,
  checked,
  showPricing,
  allIp,
  handleChange,
}) => {
  // Creates our money formatted label for amount i.e. $XX.XX (if range $XX.XX - $XX.XX)
  const label = type === 'fixed' ? toMoney(low) : `${toMoney(low)} - ${toMoney(high)}`;
  return (
    <>
      <input
        type='radio'
        className='custom-checkbox'
        defaultChecked={checked}
        value={low}
        id={id}
        data-index={idx}
        name='bondAmount'
        required
        data-inputtype='radio'
        onChange={handleChange}
      />
      <label
        className={`toggle-label price-box ${!showPricing ? 'all-uw' : ''} ${
          allIp ? 'all-ip' : ''
        }`}
        htmlFor={id}
      >
        <div className='price-box__label'>{label}</div>
        <div className='price-box__details'>
          {priceTag !== 'Varies' && !allIp ? (
            <>
              <i
                className='fa fa-cart-plus'
                style={{ marginRight: '5px', color: '#F89406' }}
              />
              <>Buy Now</>{' '}
            </>
          ) : (
            <>
              {priceTag !== 'Varies' && (
                <div className='cart-item'>
                  <i
                    className='fa fa-cart-plus'
                    style={{ marginRight: '5px', color: '#F89406' }}
                  />
                  <div className='cart-item-text' style={{ color: '#444' }}>
                    Buy Now
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className='price-box__premium'>
          {priceTag !== 'Varies' ? (
            <>
              <div className='amount ip'>
                {toMoney(priceTag, { noCents: false })}
              </div>
            </>
          ) : (
            <div className='amount uw'> Price Varies </div>
          )}
        </div>
      </label>
    </>
  );
};
// Input Rendered when selected amount is an IP PPK range
const RangedAmountInput = ({
  amount, errors, formBlur, hasError,
}) => (
  <FormItem>
    <FormLabel>
      <span className='label-text'>Bond Amount</span>
      <i className='sub-label'>
        Amounts range from ${amount.low} to ${amount.high}.
      </i>
    </FormLabel>

    {errors.bondAmount && <p className='small-error'>{errors.bondAmount}</p>}

    <MaskedInput
      data-inputtype={'ppk'}
      data-inputlow={amount.low}
      data-inputhigh={amount.high}
      hasError={hasError('bondAmount')}
      name='bondAmount'
      onBlur={formBlur}
      required
      mask={createNumberMask({
        prefix: '$',
        requireDecimal: true,
        decimalLimit: 2,
        guide: true,
        keepCharPositions: true,
      })}
      placeholder={'Please Enter an amount'}
      defaultValue=''
      className={`form-input ${hasError('bondAmount') ? 'has-error' : ''}`}
    />
  </FormItem>
);
export const BondAmounts = ({
  bonds,
  values,
  hasError,
  errors,
  formBlur,
  setValues,
}) => {
  // UI state for showing bond specific webnotes
  const [showWebNotes, toggleWebNotes] = React.useState(false);
  // kick out and return hidden input when Not Sure is selected
  if (values.selectedBond === 'Not Sure') {
    return (
        <input
          type='hidden'
          name='bondAmount'
          required
          value={values.bondAmount}
        />
    );
  }

  // Get Our Selected Bond and Amount(s)
  const selectedBond = bonds.filter(
    (b) => b.id.toString() === values.selectedBond.toString(),
  )[0];
  // And our Selected Amount
  const selectedAmounts = selectedBond.amounts;
  const selectedAmount = selectedAmounts.filter(
    (a) => a.low.toString() === values.bondAmount.toString(),
  )[0] || '';
  // Method Called when a bond amount is selected
  const handleAmountChange = (e) => {
    const newAmount = selectedBond.amounts[e.target.dataset.index];
    if (!selectedAmount.isPPK) {
      formBlur(e);
    } else {
      setValues({ ...values, bondAmount: newAmount });
    }
  };
  // Wrapper for Bond Amount Input
  const BondInputWrapper = ({ children }) => (
    <FormItem>
      <FormLabel>
        Bond Amount
        {selectedAmount && selectedAmount.isPPK && (
          <i className='sub-label'>
            Amounts range from ${selectedAmount.low} to ${selectedAmount.high}.
          </i>
        )}
      </FormLabel>
      {hasError('bondAmount') && (
        <p className='small-error'>{errors.bondAmount}</p>
      )}
      {children}
    </FormItem>
  );
  // Renders our needed bond Input(s)
  const renderBondInputs = () => {
    // One amount that is PPK? -- render ranged amount input
    if (selectedAmounts.length === 1 && selectedAmounts[0].isPPK) {
      return (
        <BondInputWrapper>
          <RangedAmountInput
            amount={selectedAmounts[0]}
            formBlur={formBlur}
            hasError={hasError}
          />
        </BondInputWrapper>
      );
    } if (selectedAmounts.length > 1) {
      // Multiple Amounts, but selectedAmount is PPK? -- render ranged amount input w/ reset button
      if (selectedAmount && selectedAmount.isPPK) {
        return (
          <BondInputWrapper>
            <div className='bondSelectionSlider'>
              <RangedAmountInput
                amount={selectedAmount}
                formBlur={formBlur}
                hasError={hasError}
              />
              <button
                class=''
                type='button'
                onClick={() => {
                  setValues({ ...values, bondAmount: '' });
                }}
              >
                Choose different amount
              </button>
            </div>
          </BondInputWrapper>
        );
      }
      // Otherwise render our Multiple Amounts Component
      return (
          <BondInputWrapper>
            {selectedBond.websiteNotes && selectedBond.websiteNotes.length > 0 && (
              <>
                <div className='label' style={{ marginLeft: '5px' }}>
                  <i>
                    If you are unsure of your bond amount, please refer to{' '}
                    {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href='#' onClick={() => toggleWebNotes(!showWebNotes)}>
                      this information
                    </a>
                  </i>
                </div>
                {showWebNotes && (
                  <ul>
                    {selectedBond.websiteNotes.map((note, idx) => {
                      const props = {
                        dangerouslySetInnerHTML: { __html: note },
                      };
                      return <li {...props} key={idx} />;
                    })}
                  </ul>
                )}
              </>
            )}
            <MultipleAmounts
              amounts={selectedAmounts}
              selectedAmount={values.bondAmount}
              handleChange={handleAmountChange}
              hasError={hasError}
            />
          </BondInputWrapper>
      );
    }
    return (
        <input
          type='hidden'
          name='bondAmount'
          required
          value={values.bondAmount}
        />
    );
  };
  return renderBondInputs();
};
