import React from 'react';
import { FormInput, FormLabel, FormSelect } from '../../ExpressAppUI';
import { StateOptions } from '../../helpers';
import { SectionHeader } from './Styles';

export default function PrincipalInfo({
  values,
  formBlur,
  errors,
  hasError,
  principalType,
}) {
  return (
    <div className="black-label">
      <hr />
      <SectionHeader>Principal Information</SectionHeader>
      <FormLabel>Name</FormLabel>
      {errors.companyName && (
        <p className="small-error">{errors.companyName}</p>
      )}
      <FormInput
        required
        autocomplete="new-password"
        onBlur={formBlur}
        name="companyName"
        defaultValue={values.companyName}
        hasError={hasError('companyName')}
        data-track={'Principal Info - Name'}
      />
      {principalType !== 'individual' && (
        <>
          <FormLabel>DBA</FormLabel>
          <FormInput
            autocomplete="new-password"
            onBlur={formBlur}
            name="companyDBA"
            defaultValue={values.companyDBA}
            hasError={hasError('companyDBA')}
            data-track={'Principal Info - DBA'}
          />
        </>
      )}
      <address>
        <FormLabel>Address</FormLabel>
        {errors.companyAddress && (
          <p className="small-error">{errors.companyAddress}</p>
        )}
        <FormInput
          placeholder="Street Address"
          data-inputtype="address"
          required
          autocomplete="new-password"
          onBlur={formBlur}
          name="companyAddress"
          defaultValue={values.companyAddress}
          hasError={hasError('companyAddress')}
          onBlur={formBlur}
          data-track={'Principal Info - Address'}
        />
        <FormInput
          placeholder="Suite, Unit, Building, Floor, Etc."
          data-inputtype="address"
          name="companyAddress2"
          autocomplete="new-password"
          onBlur={formBlur}
          defaultValue={values.companyAddress2}
          hasError={hasError('companyAddress2')}
          onBlur={formBlur}
          data-track={'Principal Info - Address 2'}
        />

        {errors.companyCity || errors.companyZip || errors.companyState ? (
          <p className="small-error">
            Please enter a valid zipcode, city, and state
          </p>
        ) : (
          ''
        )}
        <div className="value">
          <FormInput
            width={'30%'}
            name={'companyZip'}
            placeholder="Zip"
            defaultValue={values.companyZip}
            autocomplete="new-password"
            data-inputtype="zip"
            required
            hasError={hasError('companyZip')}
            data-zip
            onBlur={formBlur}
            data-track={'Principal Info - Zip'}
            data-cancelautofill={true}
          />
          <FormInput
            width={'48%'}
            margin={'0% 1%'}
            name={'companyCity'}
            placeholder="City"
            defaultValue={values.companyCity}
            autocomplete="new-password"
            required
            data-zip2city
            onBlur={formBlur}
            hasError={hasError('companyCity')}
            data-track={'Principal Info - City'}
          />
          <FormSelect
            name={'companyState'}
            defaultValue={values.companyState || ''}
            required
            autocomplete="new-password"
            width={'20%'}
            hasError={hasError('companyState')}
            data-zip2state
            onChange={formBlur}
            data-track={'Principal Info - State'}
          >
            <option value="" disabled>
              State
            </option>
            {StateOptions.map((option) => (
              <option
                key={`principalState-${option.value}`}
                value={option.value}
              >
                {option.display}
              </option>
            ))}
          </FormSelect>
        </div>
      </address>
    </div>
  );
}
