import React from 'react';
import styled from 'styled-components';

export const PanelWrapper = styled.div`
background: ${(props) => (props.active ? '#f9f9fa' : 'none')};

`;
export const PanelHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .panel-type{
    display: flex;
    align-items: center
  };

`;
export const PanelTitle = styled.div`
display: flex;
align-items: center;
`;

export const PanelName = styled.div`
font-size: 20px;
font-weight: 400;
color: #0d8bd1;
`;
export const PanelIcon = ({ icon, complete }) => (
  <div style={{ marginRight: '10px' }}>
    <span className="fa-stack fa-lg">
      <i className="fa fa-circle fa-stack-2x" style={{ color: complete ? 'rgb(153, 196, 50)' : '#0d8bd1' }}></i>
      <i className={`fa ${complete ? 'fa-check' : `fa-${icon}`} fa-stack-1x fa-inverse`}></i>
    </span>
  </div>
);

export const PanelDetails = styled.div`
font-size: '14px';
font-weight: '600';
 @media(max-width:420px){
   display:none;
}
max-width:45%;
div {
  text-overflow:ellipsis;
  white-space: nowrap;
  overflow:hidden;
  font-size: 12px;
  font-weight: 600;
}

`;
export const MobilePanelDetails = styled.div`

p {
  margin: 5px 0 5px 0;
  text-align:center;
}
@media(min-width:421px){
  display:none;
}
`;

export const PanelEditButton = styled.button`
    display: block;
    min-width:30px;
    line-height: initial;
    text-transform: none;
    padding: 0px;
    font-size: 1.2em;
    font-weight: 600;
    background: none;
    color: #0d8bd1;
`;
