import React from 'react';
import styled from 'styled-components';
import { toFullStateName, toMoney } from '../../helpers';

const Wrapper = styled.div`
  background-color:#f9f9fa;
  border-radius: 5px;
  padding: 30px;
  @media(max-width:799px){
    display:none
  }
`;
const PanelTitle = styled.h5`
  text-align:center;
  //border-bottom:1px solid rgba(175, 175, 175, 0.34);
  padding-bottom:10px;
  font-size:1.5em;
  font-weight:400;
`;

const BondImage = styled.img`
  width:120px;
  margin-right:10px;
  height: 120px;
`;
const BondInfo = styled.div`
  display:flex;
  flex-direction:row;
  padding-top:15px;
  color:#444;
  p,h5 {
    color:#444;
    margin: 0px;
  }
  h5{
    font-weight:400;
    font-size: 20px;
  }
`;

const Charges = styled.div`
  padding: 15px 15px;
  border-bottom:1px solid rgba(175, 175, 175, 0.34);
`;
const ChargeCategory = styled.span`
  color:#0d8bd1;
`;
const ChargeAmount = styled.span`
  float:right;
`;

const Total = styled.div`
  font-size:18px;
  font-weight: 800;
  padding:10px 10px 0 10px;
`;

const BondSummary = (props) => (
    <Wrapper>
    <div className="checkout-panel-heading">
      <PanelTitle className="panel-title">Bond Summary</PanelTitle>
      <div>
        <BondInfo>
          <BondImage src='/img/bond-mock.png' alt='bond-img'></BondImage>
          <div>
            <p>{toMoney(props.rightBox.bond.amount, { noCents: false })}</p>
            <h5>{toFullStateName(props.rightBox.bond.state)}</h5>
            <p>{props.rightBox.bond.name}</p>
          </div>
        </BondInfo>
        <Charges>
          {props.rightBox.charges.map((charge, i) => <div key={i}>
                <ChargeCategory key={`cat${i}`}>{charge.category}</ChargeCategory>
                <ChargeAmount key={`amount${i}`}>{toMoney(charge.amount, { noCents: false })}</ChargeAmount>
              </div>)}
        </Charges>
        {/* id attr required for E2E tests */}
        <Total id='order-total'>
          <ChargeCategory >ORDER TOTAL</ChargeCategory>
          <ChargeAmount >{toMoney(props.rightBox.balance, { noCents: false })}</ChargeAmount>
        </Total>
      </div>
    </div>
  </Wrapper>
);

export default BondSummary;
