import { Router } from '@reach/router';
import React, { Component } from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ExpressApp from './ExpressApp';
import TitleBonds from './title-bonds/TitleBonds';
import StartApp from './start-app/StartApp';
import { ErrorBoundary } from './Airbrake';
import InstantPurchase from './instant-purchase/InstantPurchase';
import PurchaseRouter from './purchase-bond/PurchaseRouter';
import Engage from './engage';
import Verification from './verification';
import Cslb from "./cslb"

const queryClient = new QueryClient();
export default class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== "production" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
          <Router style={{ height: "100%", minHeight: "100%" }}>
            <InstantPurchase path="application/:nonce/*"></InstantPurchase>
            <StartApp path="start-application/*"></StartApp>
            <ExpressApp path="express-application/:nonce" />
            <TitleBonds path="title-bond/:nonce/*" />
            <PurchaseRouter path="purchase-bond/:nonce/*"></PurchaseRouter>
            <Engage path="engage/:nonce/*"></Engage>
            <Verification path="verify/:nonce/*"></Verification>
            <Cslb path="cslb/:nonce/*" />
          </Router>
        </QueryClientProvider>
      </ErrorBoundary>
    );
  }
}
