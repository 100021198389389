import styled from 'styled-components';
import React, { useState } from 'react';
import { pure } from 'recompose';
import LoadingOverlay from 'react-loading-overlay';
import { Heading } from './Components/Panels';

export const Wrapper = styled.div`
         background-color: #f9f9fa;
         padding: 30px;
         transition: all 0.3s ease-in;
         background-color: #f9f9fa;
         padding: 30px;
         height: 100%;
         width: ${(props) => (props.isMobile ? '100%' : '55%')};
         transition: all 0.2s ease-in;
       `;

export const Accordian = pure(
  ({
    panels, bond, company, owners, verify, toggle, activePanel,
  }) => (
    <div
      className={`mobile-view ${
        activePanel === 'verify' ? 'verify-yes' : 'verify-no'
      }`}
    >
      <div className={'accordian'}>
        <div
          id="bond"
          className={`accordian-item ${
            activePanel === 'bond'
              ? 'active flip-horizontal-bottom'
              : 'inactive'
          }`}
        >
          <Heading toggle={toggle} panel={panels[0]} type="mobile" />
          <div
            className={`accordian-body ${
              activePanel === 'bond' ? 'active flip-2-ver-right-1' : 'inactive'
            }`}
          >
            {bond}
          </div>
        </div>

        <div
          id="company"
          className={`accordian-item ${
            activePanel === 'company'
              ? 'active flip-horizontal-bottom'
              : 'inactive'
          }`}
        >
          <Heading toggle={toggle} panel={panels[1]} type="mobile" />
          <div
            className={`accordian-body ${
              activePanel === 'company'
                ? 'active flip-2-ver-right-1'
                : 'inactive'
            }`}
          >
            {company}
          </div>
        </div>
        <div
          id="owners"
          className={`accordian-item ${
            activePanel === 'owners' ? 'active' : 'inactive'
          }`}
        >
          <Heading toggle={toggle} panel={panels[2]} type="mobile" />
          <div
            className={`accordian-body ${
              activePanel === 'owners' ? 'active' : 'inactive'
            }`}
          >
            {owners}
          </div>
        </div>
      </div>
      <div className="verify-mobile accordian-item active">{verify}</div>
    </div>
  ),
);

export const MainPage = pure(({
  panelInfo, fields, panels, toggle,
}) => (
  <Wrapper className="main-page">
    <Heading toggle={toggle} panel={panelInfo} type="main-panel" />
    <div className="page-body">{fields}</div>
  </Wrapper>
));

export const RadioToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  input.toggle-radio {
    display: none;
  }
  label.toggle-label {
    background: #efefef;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2),
      inset 0 -3px 0 rgba(0, 0, 0, 0.22);
    transition: 0.3s;
    border-radius: 0.25em;
    padding: 1em 2em;
    font-size: 14px;
  }
`;

export const PageHeading = pure(styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(175, 175, 175, 0.34);
  .checkout-panel-type {
    display: flex;
    align-items: center;
  }
  .title {
    color: #0d8bd1;
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px;
  }
`);

export const FormItem = pure(styled.div`
  margin-top: 20px;
`);

export const FormLabel = pure(styled.label`
  display: block;
  color: #0d8bd1;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 5px;

  > sup {
    color: crimson;
  }
`);

export const FormInput = pure(styled.input`
  width: 100%;
  -webkit-appearance: none;
  border-radius: 4px !important;
  box-sizing: border-box;
  word-break: normal;
  font-size: 15px;
  color: #333;
  font-weight: 400;
  height: 40px;
  padding: 6px 10px;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.2s ease;
  border: 1px solid #d9d9d9;
  /* box-shadow: 0 0 0 1px #d9d9d9;
  -webkit-box-shadow: 0 0 0 1px #d9d9d9;
  -moz-box-shadow: 0 0 0 1px #d9d9d9; */
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0'};
  && {
    border: ${(props) => (props.hasError ? '2px solid crimson' : '1px solid #d9d9d9')};
  }
  margin-bottom: 10px;
  &:focus {
    box-shadow: 0 0 0 2px #0d8bd1;
  }
  &:disabled {
    background-color: #ececec;
    cursor: not-allowed
  }
       `);

export const ZipInput = styled(FormInput)`
  width: 30%;
`;

export const CityInput = styled(FormInput)`
  width: 48%;
  margin: 0% 1%;
`;

export const FormSelect = styled.select`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0'};
  font-size: 15px;
  height: 40px;
  padding: 6px 10px;
  background-color: #fff;
  border: 0;
  box-sizing: border-box;
  transition: all 0.2s ease;
  box-shadow: 0 0 0 1px #d9d9d9;
  border: ${(props) => (props.hasError ? '2px solid crimson' : 'none')};
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  &:focus {
    box-shadow: 0 0 0 2px #0d8bd1;
  }
  background-color: #fff;
`;

export const StateInput = styled(FormSelect)`
  width: 20%;
`;

export const PageButton = pure(styled.button`
  font-size: 13px;
  margin-top: 30px;
  background-color: #f89406;
  max-height:39px;
  position:relative;

  width: 190px;
  &:active,
  &:hover:not(:disabled) {
    background-color: #f89406;
  }
  :disabled{
    background-color: #b5b5b7;
    cursor: default;
    &:hover{
      background-color: #b5b5b7;
    }
  }
`);

export const SmartPageButton = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.onClickFunction(e);
  };

  return (
  <PageButton
    onClick={(e) => handleClick(e)}
    disabled={props.isSubmitting || props.isDisabled}
    className={props.className}
  >
    {props.isSubmitting ? <>Saving ... <img style={{ height: '17px' }} src='/img/connect/spinner.gif' alt='saving'></img></> : props.buttonText}
  </PageButton>
  );
};

export const WideSubmitButton = styled(PageButton)`
  width: 100%;
  max-width: '400px';
`;

export const Overlay = styled(LoadingOverlay)`
  ._loading_overlay_wrapper {
    width: 100%;
  }
  ._loading_overlay_overlay {
    width: ${(props) => (props.isMobile ? '100%' : '55%')};
    background-color: #f9f9fa;
  }
  ._loading_overlay_spinner {
    svg {
      circle {
        stroke: #0d8bd1 !important;
      }
    }
  }
`;
export const PanelHeading = ({ panelInfo }) => <div className="page-heading" />;

export const ErrorMessage = styled.div`
  box-sizing:border-box;
  color:crimson;
  padding:15px;
  overflow:hidden;
  font-size:14px;
  width:100%;
`;

export const LineBreak = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1.2em 0;
  padding: 0;
`;
