import React from 'react';

export const Panels = ({ panels, toggle }) => {
  panels = panels.filter((p) => p.name !== 'verify');

  return (
    <div className="panels">
      {panels.map((panel) => (
        <Panel panel={panel} toggle={toggle} />
      ))}
    </div>
  );
};

export const Heading = ({ panel, toggle, type }) => (
  <>
    <div
      className={`panel-heading ${
        panel.active ? 'active' : 'inactive'
      } panel-${type}`}
      id={panel.name}
    >
      <div className={'panel-type'}>
        <div className="panel-icon">
          {type === 'warning' ? (
            <i className="fa fa-exclamation-triangle fa-lg warning-icon" />
          ) : (
            <span className="fa-stack fa-lg">
              <i
                className="fa fa-circle fa-stack-2x"
                style={{
                  color: `${panel.completed ? '#99c432' : '#156C9F'}`,
                }}
              />
              <i
                className={`fa ${
                  panel.completed ? 'fa-check' : `${panel.icon}`
                } fa-stack-1x fa-inverse`}
              />
            </span>
          )}
        </div>
        <div className={'panel-title'}>{panel.title}</div>
      </div>
      {type === 'mobile' && !panel.active && !panel.disabled && (
        <>
          <button className="panel-edit-btn" onClick={() => toggle(panel.name)}>
            edit
          </button>
        </>
      )}
    </div>

    <div className="panel-details" style={{ marginTop: '10px' }}>
      {panel.details
        .filter((p) => !!p.value)
        .map((detail) => (
          <>
            <div
              className="panel-detail-item"
              style={{
                display: `${panel.active ? 'none' : 'flex'}`,
                width: '100%',
                justifyContent: 'center',
                opacity: '.25',
                textAlign: `${type === 'mobile' ? 'center' : 'left'}`,
              }}
            >
              {detail.hasError && <i className="fa fa-times" />}
              {!!detail.name && (
                <div className="panel-detail-label">{detail.name}</div>
              )}
              <div className="panel-detail-value">{detail.value}</div>
            </div>
          </>
        ))}
    </div>
  </>
);

export const Panel = ({ panel, toggle, type }) => (
  <div className={`panel ${panel.active && 'active'} ${type}`}>
    <div className="panel-icon">
      <span className="fa-stack fa-lg">
        <i
          className="fa fa-circle fa-stack-2x"
          style={{
            color: `${panel.completed ? '#99c432' : '#156C9F'}`,
          }}
        />
        <i
          className={`fa ${
            panel.completed ? 'fa-check' : `${panel.icon}`
          } fa-stack-1x fa-inverse`}
        />
      </span>
    </div>
    <div
      className={`panel-content ${
        panel.completed ? 'completed' : 'no-details'
      }`}
    >
      <div className="panel-top">
        <div className="panel-title">{panel.title}</div>
        {!panel.disabled && (
          <div className="panel-actions">
            <button
              className="panel-edit-btn"
              onClick={() => toggle(panel.name)}
            >
              edit
            </button>
          </div>
        )}
      </div>
      <div className={`panel-bottom ${!panel.disabled ? 'show' : 'hide'}`}>
        <div className="panel-details">
          {panel.details
            .filter((p) => !!p.value)
            .map((detail) => (
              <div className="panel-detail-item">
                {detail.hasError && <i className="fa fa-times" />}
                {!!detail.name && (
                  <div className="panel-detail-label">{detail.name}</div>
                )}
                <div className="panel-detail-value">{detail.value}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  </div>
);
