import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useTransition, useSpring, animated } from 'react-spring';
import ChangeTable from './ChangeTable';

const GreyBackground = styled(animated.div)`
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  overflow: scroll;
  padding-top: 100px;
  top: 0;
  left: 0;
  box-sizing: border-box;
`;
const ModalBox = styled(animated.div)`
  width: 1000px;
  max-width: 85%;
  height: auto;
  background: white;
  z-index: 999;
  position: relative;
  /* padding: 40px; */
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 auto;
  border-radius: 8px;
  @media (max-width: 700px) {
    /* padding: 5px; */
    max-width: 95%;
  }
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const ModalBody = styled.div`
padding: 40px;
@media (max-width: 700px) {
    padding: 5px;
    /* max-width: 95%; */
}
`;
const CloseButton = styled.div`
  text-align: center;
  color: #222;
  cursor: pointer;
  transition: ease all 0.3s;
  font-size: 1.5rem;
  position: absolute;
  top: 3px;
  right: 1rem;
  :hover {
    transform: scale(1.2);
    color: red;
  }
`;
const Heading = styled.h1`
  color: #0d8bd1;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0px;

`;
const YesButton = styled.button`
  background: #0d8bd1;
  font-size: 13px;
  max-height: 39px;
  width: 190px;
`;
const CancelButton = styled.button`
  color: red;
  background: white;
  border: 1px solid red;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 13px;
  max-height: 39px;
  width: 190px;
  :hover {
    background: red;
    color: white;
  }
`;

const ChangeLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
`;

const ChangeValue = styled.strong`
  color: ${(props) => (props.old ? 'red' : 'green')};
`;

const ChangeBox = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
function ChangedField({ changedField, labelList }) {
  const { name, oldVal, newVal } = changedField;

  return (
    <div style={{ marginBottom: '5px' }}>
      <ChangeLabel>{labelList[name]}</ChangeLabel>
      <div style={{ display: 'flex' }}>
        <ChangeBox>
          <ChangeValue old={true}>Before</ChangeValue>
          {oldVal}
        </ChangeBox>
        <ChangeBox>
          <ChangeValue>Now</ChangeValue>
          {newVal}
        </ChangeBox>
      </div>
    </div>
  );
}

export default function ConfirmModal({
  values,
  originalValues,
  modalOpen,
  toggleModal,
  labelList,
  changes,
  handleSubmit,
}) {
  React.useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [modalOpen]);

  const modalRoot = document.getElementById('modal-root');
  const fadeIn = useSpring({
    config: { duration: 150 },
    to: { opacity: modalOpen ? 1 : 0 },
    from: { opacity: 0 },
  });

  const expand = useSpring({
    config: { duration: 150 },
    scale: modalOpen ? 1 : 0,
    from: { scale: 0 },
    delay: 150,
  });

  return ReactDOM.createPortal(
    <>
      <GreyBackground style={fadeIn}>
        <ModalBox
          style={{
            transform: expand.scale.interpolate((scale) => `scale(${scale})`),
          }}
        >
          <ModalHeader>
            <CloseButton onClick={() => toggleModal(false)}>
              &times;
            </CloseButton>
          </ModalHeader>
          <ModalBody>
          <Heading>To proceed, please confirm your updated information below</Heading>
          <ChangeTable changes={changes} />
          <ButtonWrapper>
            <YesButton onClick={handleSubmit}>Confirm</YesButton>
            <CancelButton onClick={() => toggleModal(false)}>
              Cancel
            </CancelButton>
          </ButtonWrapper>
          </ModalBody>

        </ModalBox>
      </GreyBackground>
    </>,
    modalRoot,
  );
}
