import { Mixpanel } from '../mixpanel';

export const initVerifyTracking = (nonce, data) => {
  // Init mixpanel and datalayer
  Mixpanel.identify(nonce);
  window.dataLayer = window.dataLayer || [];

  const mixpanelTrack = {
    'Nonce ID': nonce,
    'Lead ID': data.leadId,
    'Bond ID': data.bondInfo.id,
    'Bond Type': data.bondInfo.name,
    'Bond Amount': data.bondInfo.amount,
    'Bond State': data.bondInfo.state,
    'Bond Version': data.bondInfo.version,
    Renewal: data.bondInfo.isRenewal,
  };
  Mixpanel.register(mixpanelTrack);
  Mixpanel.track('Verification Page');
  // In order for our chat widget to track variables -- we pass them to the global layer
  window.leadId = data.leadId;
  window.bondType = data.bondInfo.name;
  window.bondState = data.bondInfo.state;
  window.bondAmount = data.bondInfo.amount;
  const dataLayerTrack = {
    leadId: data.leadId,
    userId: data.leadId,
    bondId: data.bondInfo.id,
    bondType: data.bondInfo.name,
    bondState: data.bondInfo.state,
    bondAmount: data.bondInfo.amount,
    bondVersion: data.bondInfo.version,
    nonceId: nonce,
    isRenewal: data.bondInfo.isRenewal,
    ecData: {
      email: data.email,
      phone_number: data.phone,
    },
    event: 'Data Layer Ready',
  };
  window.dataLayer.push(dataLayerTrack);
};

export const trackSubmit = () => {
  Mixpanel.track('Verification Form Submitted');
};

export const trackFailure = () => {
  Mixpanel.track('Verification Form Submission Failed!');
};
