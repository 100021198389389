import React from 'react';
import styled from 'styled-components';
import Footer from '../../Components/Footer';
import TrustSeals from '../../Components/TrustSeals';
import AccountManagerBox from '../../purchase-bond/components/AccountManagerBox';

import {
  TopBar,
  CheckoutHeader,
  SplitLayout,
  Card,
} from '../../DesignGuide';

const MessageP = styled.p`
  /* font-size: 1.2em; */
  /* margin-top: -20px; */
  text-align: center;
`;

/*
PROPS FOR LAYOUT
  message
  salesRep
  title
*/

// Layout for Verification
export function VerificationLayout(props) {
  return (
    <>
      <TopBar title="Verify Information" subHeader={true} />
      <div className="container main-container" style={{ minHeight: '80vh' }}>
        <CheckoutHeader>
          <div className="hide-on-print instant-purchase-heading">
           {props.title || 'Verify Your Information'}
          </div>
        </CheckoutHeader>
        {props.message ? <MessageP>{props.message}</MessageP> : null}
        <SplitLayout>
          <div>
            <Card color={'rgb(249, 249, 250)'}>{props.children}</Card>
          </div>
          <div className="right-box hide-on-print">
            {props.salesRep && (

            <AccountManagerBox salesRep={props.salesRep} />
            )}
            <TrustSeals />
          </div>
        </SplitLayout>
      </div>
      <Footer />
    </>
  );
}

export default VerificationLayout;
