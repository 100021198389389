import { Link } from '@reach/router';
import React, { useState } from 'react';
import '../App.css';
import { toFullStateName } from '../helpers';
import { TitleContext } from '../titleContext';
import { FormItem, FormLabel } from '../ExpressAppUI';

export default function ConfirmLien({ navigate }) {
  const {
    state, dispatch, addLog, Mixpanel,
  } = React.useContext(TitleContext);
  const [showLienExplanation, setShowLienExplanation] = useState(false);

  async function confirmLienHandler({ target: { value } }) {
    // Format Comment
    const isLien = value === 'Yes';
    let comment = '';
    if (isLien) {
      comment = 'Customer confirmed there <strong>is a lien</strong> on the vehicle.';
    } else {
      comment = 'Customer confirmed there is <strong>no lien</strong> on the vehicle.';
    }
    const log = {
      comment,
      action: 'Vehicle Lien Confirmation',
    };

    Mixpanel.track(
      comment,
    );
    addLog(log, {}, false);
    if (!isLien) {
      dispatch({
        type: 'UPDATE_STATE',
        payload: {
          confirmedNoLien: true,
        },
      });
      navigate('../principal');
    } else {
      setShowLienExplanation(true);
    }
  }

  return (
    <div className='panel-title' style={{ textAlign: 'center' }}>
    {showLienExplanation ? (
      <>
        <h1
          className='error-text'
          style={{ textAlign: 'center', fontSize: '22px' }}
        >
          <i className='fa fa-exclamation' style={{ marginRight: '10px' }} /> LIEN RELEASE REQUIRED </h1>
        <h3 className='helper-text'>
          We’re unable to provide a title bond for vehicles with an active lien. Please contact your local DMV to determine next steps.
        </h3>
        <h3 className='helper-text'>
          <em>Disclaimer: Providing false information to obtain a bond is insurance fraud. </em>
        </h3>
      </>
    )
      : (
      <>
          Before We Continue...
      <FormItem>
        <FormLabel>
          Is there a lien on this vehicle?
          <sup>*</sup>
        </FormLabel>
        <div className='radio-wrap' style={{ justifyContent: 'center' }}>
          <input
            onChange={confirmLienHandler}
            className='toggle-radio'
            type='radio'
            id='confirmLienYes'
            name='confirmLien'
            value='Yes'
            required
          />
          <label htmlFor='confirmLienYes' className='toggle-label'>
            Yes
          </label>
          <input
            onChange={confirmLienHandler}
            className='toggle-radio'
            type='radio'
            id='confirmLienNo'
            name='confirmLien'
            value='No'
            required
          />
          <label htmlFor='confirmLienNo' className='toggle-label'>
            No
          </label>
        </div>
      </FormItem>
      </>
      )}
  </div>
  );
}
