import { useParams } from '@reach/router';
import React from 'react';
import { useQuery } from 'react-query';
import { useLead } from '../client';
import { fetchLicenseInfo } from './License';

export const LICENSE_NUMBER_FETCH_FAIL_ATTEMPTS = 2
export const PROCESSING_TASK_FAIL_ATTEMPTS = 12
export const PROCESSING_TASK_INTERVAL = 5000
export const DOCUSIGN_TASK_FAIL_ATTEMPS = 5
export const DOCUSIGN_FAIL_INTERVAL = 7000
export function useLicenseInfo() {
  const { lead } = useLead();
  const { licenseNumber } = useParams();
  const query = useQuery(
    ['licenseNumber', licenseNumber],
    () => fetchLicenseInfo(licenseNumber, lead),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !!lead,
      retry: (failureCount, err) => {
        if (failureCount < LICENSE_NUMBER_FETCH_FAIL_ATTEMPTS) return true;
        return false;
      },
    },
  );
  const licenseData = query?.data || null;

  return { licenseData, ...query };
}

export function useProcessingTask() {
  const { nonce } = useParams();
  const [timesTried, setTimesTried] = React.useState(0);
  const query = useQuery(
    ['processingTask', nonce],
    () =>
      window
        .fetch(`/cslb/${nonce}/check-for-quote`, {
          method: 'GET',
        })
        .then((res) => res.json()),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchInterval: (data) =>
        !data || (data.task.status === 'pending' && timesTried < PROCESSING_TASK_FAIL_ATTEMPTS)
          ? PROCESSING_TASK_INTERVAL
          : undefined,
      onSuccess: (data) => {
        if (data.task.status !== 'complete') {
          setTimesTried(timesTried + 1);
        }
      },
    },
  );

  return { ...query, task: query?.data?.task || null, timesTried };
}

export function useDocusignSigTask() {
  const { nonce } = useParams();
  const [timesTried, setTimesTried] = React.useState(0);
  const query = useQuery(
    ['docusign-docs-task', nonce],
    () =>
      window
        .fetch(`/cslb/${nonce}/get-docusign-docs`, {
          method: 'GET',
        })
        .then((res) => res.json()),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchInterval: (data) => {
        const interval = DOCUSIGN_FAIL_INTERVAL;
        if (timesTried > DOCUSIGN_TASK_FAIL_ATTEMPS) return undefined;
        const docs = data?.docuSignDocs;
        if (!docs) return interval;
        if (docs.length <= 0) {
          return undefined;
        }
        const pendingDocs = docs.filter((doc) => !doc.completed);
        if (pendingDocs.length > 0) return interval;
        else if (pendingDocs.length === 0) return undefined;
      },

      onSuccess: (data) => {
        const pendingDocs = data.docuSignDocs.filter((doc) => !doc.completed);
        if (pendingDocs.length > 0) {
          setTimesTried(timesTried + 1);
        }
      },
    },
  );

  const docs = query?.data?.docuSignDocs;
  const completedDocs = docs?.filter((doc) => !!doc.completed);

  const allDocsCompleted =
    docs?.length > 0 && docs?.length === completedDocs.length;

  return { docs, allDocsCompleted, ...query };
}
