import React, { useState } from 'react';
import Axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import Layout from './components/Layout';
import { useFormState } from '../hooks';

import PrincipalInfo from './components/PrincipalInfo';
import BondInfo from './components/BondInfo';
import ConfirmModal from './components/ConfirmModal';
import { PageButton, ErrorMessage } from '../ExpressAppUI';
import { initVerifyTracking, trackSubmit, trackFailure } from './tracking';

const formatForm = (state) => {
  const labelList = {
    effectiveDate: 'Effective Date',
    companyName: 'Company Name',
    companyDBA: 'Company DBA',
    companyAddress: 'Address',
    companyAddress2: 'Address 2',
    companyState: 'State',
    companyZip: 'Zip',
    companyCity: 'City',
  };

  const formattedFields = {
    effectiveDate: moment(state.bondInfo.effectiveDate).format(
      'MM/DD/YYYY',
    ),
    companyName: state.principalInfo.name,
    companyDBA: state.principalInfo.dba || '',
    companyAddress: state.principalInfo.address,
    companyAddress2: state.principalInfo.address2,
    companyState: state.principalInfo.state,
    companyZip: state.principalInfo.zip,
    companyCity: state.principalInfo.city,
  };

  const requiredFields = [
    'effectiveDate',
    'companyAddress',
    'companyState',
    'companyZip',
    'companyCity',
  ];

  const customFieldsThatAffectQuote = [];

  // Handle custom field stuff
  state.bondInfo.customFields.forEach((f) => {
    const fieldName = `customFields[${f.field}]`;
    if (f.type === 'Boolean') {
      if (f.value === true) formattedFields[fieldName] = 'true';
      else if (f.value === false) formattedFields[fieldName] = 'false';
      else formattedFields[fieldName] = '';
    } else if (f.type === 'Date') {
      formattedFields[fieldName] = moment(f.value).format('MM/DD/YYYY');
    } else {
      formattedFields[fieldName] = f.value || '';
    }
    labelList[fieldName] = f.display;
    if (f.required) {
      requiredFields.push(fieldName);
    }
    if (!f.doesNotAffectQuote) {
      customFieldsThatAffectQuote.push(fieldName);
    }
  });

  return { formattedFields, requiredFields, labelList, customFieldsThatAffectQuote };
};

function getChangeCategory(name) {
  if (name === 'effectiveDate' || name.includes('customField')) {
    return 'Bond Info';
  }

  if (['companyName', 'companyDBA', 'companyAddress', 'companyAddress2', 'companyState', 'companyZip', 'companyCity'].includes(name)) {
    return 'Principal Info';
  }
}
function buildChanges(originalValues, values, labelList, customFieldsThatAffectQuote) {
  const changedFields = [];
  for (const key in originalValues) {
    if (originalValues[key].toString().trim() !== values[key].toString().trim()) {
      changedFields.push({
        oldVal: originalValues[key],
        newVal: values[key],
        name: key,
        display: labelList[key],
        category: getChangeCategory(key),
        // for custom field changes only
        doesNotAffectQuote: !customFieldsThatAffectQuote.includes(key),
      });
    }
  }

  return changedFields;
}

const VerificationForm = ({ state, dispatch, nonce }) => {
  const [modalOpen, toggleModal] = useState(false);
  // show error when submit breaks on backend
  const [showError, setShowError] = useState(false);
  const submitForValidation = () => {
    if (changes.length) {
      toggleModal(true);
    } else {
      handleSubmit();
    }
  };

  const formRef = React.useRef(false);

  // Build form body (json object of key:value => input:value)
  const { formattedFields, requiredFields, labelList, customFieldsThatAffectQuote } = formatForm(state);
  const [
    values,
    touched,
    errors,
    formBlur,
    submitForm,
    { hasError },
  ] = useFormState(formattedFields, requiredFields, submitForValidation, formRef);

  const originalValues = formattedFields;

  const changes = buildChanges(originalValues, values, labelList, customFieldsThatAffectQuote);

  const handleSubmit = async (e) => {
    try {
      trackSubmit();
      const formattedData = qs.stringify({ ...values, changes: changes || [] });
      const { data } = await Axios.post(`/verify/${nonce}/confirm`, formattedData);
      window.location = data.redirect;
    } catch (error) {
      trackFailure();
      toggleModal(false);
      setShowError(true);
      window.scrollTo({
        top: document.getElementById('root').offsetTop,
        behavior: 'smooth',
      });
    }
  };
  return (
    <form ref={formRef}>
      { showError
        && <ErrorMessage>
          An error has occurred when trying to save your information. Please reload the page and try again. If you keep getting this error message, please give us a call at <a href='tel:+18003084358'> 1(800)308-4358 </a>
        </ErrorMessage>
      }
      { modalOpen && <ConfirmModal changes={changes} labelList={labelList} modalOpen={modalOpen} toggleModal={toggleModal} values={values} originalValues={originalValues} handleSubmit={handleSubmit}></ConfirmModal>}
      <BondInfo customFields={state.bondInfo.customFields} bondInfo={state.bondInfo} values={values} formBlur={formBlur} errors={errors} hasError={hasError}></BondInfo>
      <PrincipalInfo values={values} principalType={state.principalInfo.principalType} formBlur={formBlur} errors={errors} hasError={hasError}></PrincipalInfo>
      <PageButton type='submit' onClick={submitForm}>Submit</PageButton>
    </form>
  );
};

export default function Verification(props) {
  // TODO: Call to get our data and set into state
  const reducer = (state, action) => {
    switch (action.type) {
      case 'INIT':
        return { ...state, ...action.payload, loading: false };

      default:
        throw new Error('no case found!');
    }
  };

  // Initial State
  const [state, dispatch] = React.useReducer(reducer, {
    nonce: props.nonce,
    loading: true,
    salesRep: false,
  });

  React.useEffect(() => {
    async function initApp() {
      // that thing we do to always make a new request, and don't let the browser cache a response
      const search = window.location.search ? `${window.location.search}&timestamp=${new Date().getTime()}` : `?timestamp=${new Date().getTime()}`;
      // get the data
      const { data } = await Axios.get(
        `/verify/${
          props.nonce
        }/get-app-state/${search}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );

      if (data.redirect) {
        window.location = data.redirect;
      } else {
        initVerifyTracking(props.nonce, data);
        dispatch({ type: 'INIT', payload: data });
      }
    }
    initApp();
  }, []);

  // TODO: Handle submit function
  return (
    <Layout title={'Verify Information'} salesRep={state.salesRep} message={'Bonds are legal documents, so all information must be fully accurate before the obligee will accept it. Please verify all information to avoid delays'}>
      {state.loading ? null : (
        <VerificationForm state={state} dispatch={dispatch} nonce={props.nonce} />
      )}
    </Layout>
  );
}
