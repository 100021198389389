import React from 'react'
import {RefactoredPurchaseBondLayout} from './Layout'
import styled from 'styled-components'
import Axios from 'axios'
import { useParams } from "@reach/router"
import { useAsync } from '../../client'
import { Loading } from './Loading'
import { useTrackPurchasePage } from '../tracking'
import { Mixpanel } from '../../mixpanel'

function MissingSignatures(){
  const { nonce } = useParams()
  const { data, error} = useAsync(() => Axios.get(`/purchase-bond/${nonce}/missing-signatures-data`));
  const { data:resendResponse, doFetch, isLoading, error:resendError } = useAsync()

  useTrackPurchasePage('Missing Signatures Page')
  
  if(error) return (
      <RefactoredPurchaseBondLayout>
        <p>An error has occurred Please reload the page and try again. If you keep getting this error message, please give us a call at <a href='tel:+18003084358'> 1(800)308-4358 </a></p>
      </RefactoredPurchaseBondLayout>
    )

  if(!data) return <Loading isLoading={true} />

  // no envelopes? go to purchase
  if(!data?.data?.envelopes.length) window.location = `/purchase-bond/${nonce}/`

  function handleResend(){
    Mixpanel.track('Resent Docusign Email')
    doFetch(() => Axios({
      method: 'post',
      url: `/purchase-bond/${nonce}/resend-docusign`,
      data: {envelope: data.data.envelopes[0]},
    }))
  }

  return (
    <RefactoredPurchaseBondLayout>
        <CheckoutTitle>
          <i
            className="icon icon-low-credit"
            style={{ fontSize: "2em", marginRight: "10px" }}
            ></i>
          <h3 style={{
            display: 'inline',
            color: '#fff',
            fontWeight: '300',
          }}
          >
            Missing Signatures
          </h3>
        </CheckoutTitle>
        <div style={{
          padding: '40px',
        }}
        >
          <p style={{
            fontSize: '1.4em',
            fontWeight: '100',
            marginTop:'-20px',
          }}>
            Looks like you're ready to purchase your surety bond, but before you can proceed, all individuals listed below must review and sign the indemnity agreement from DocuSign.
          </p>
          <div style={{
            background: '#ececec',
            padding: '20px 40px 10px 40px',
            marginLeft: '-40px',
            marginRight: '-40px',
          }}>
            {data.data.envelopes.map(envelope => envelope.recipients.map(recipient => <RecipientMessage key={`recipient-${recipient._id}`} recipient={recipient}/> ))}
          </div>
          <ResendDocusignEmail data={data} isLoading={isLoading} handleResend={handleResend} resendResponse={resendResponse} resendError={resendError}/>
          <MissingSignaturesFAQS />
        </div>
    </RefactoredPurchaseBondLayout>
  )
}

function ResendDocusignEmail({isLoading, data, handleResend, resendResponse, resendError}){
  const [minutesUntilResend, setMinutesUntilResend] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  React.useEffect(checkIfDisabled, [data.data, isLoading])

  function checkIfDisabled(){
    if(!data?.data) setIsDisabled(true)
    const sentLast = data?.data?.envelopes.map(envelope => {
      if (envelope.changedOn > envelope.dateSent) return envelope.changedOn
      else return envelope.dateSent
    }).sort((a,b) => new Date(b.date) - new Date(a.date))[0];
    const now = new Date()
    const anHourAgo = new Date(now.setHours(new Date().getHours() - 1))
    if (new Date(sentLast) > anHourAgo){
      const differenceInMinutes = parseInt(Math.abs(now.getTime() - new Date(sentLast).getTime()) / (1000 * 60) % 60);
      setMinutesUntilResend(differenceInMinutes)
      setIsDisabled(true)
    }
    else {
      setIsDisabled(false)
    }
  }

  if(resendResponse?.status === 200) {
    return (
      <div>
        <h3 style={{marginTop:'1em'}}><i className='icon icon-check' style={{color: '#99c432'}}/> Docusign Email Resent</h3>
        <p style={{fontSize:'1.4em', fontWeight:'100'}}>All recipients who have not signed will receive a new email from Docusign. Please ensure these recipients complete the signature, which will allow you to continue through the purchase process.</p>
      </div>
    )
  }
  if(resendResponse?.status > 399 || resendError) {
    return (
      <div>
        <h3 style={{marginTop:'1em'}}><i className='icon icon-low-credit' style={{color: '#ee7b00'}}/> Something Went Wrong</h3>
        <p style={{fontSize:'1.4em', fontWeight:'100'}}>The docusign emails failed to resend. Please refresh your page and try again, or reach out to your account manager for assistance.</p>
      </div>
    )
  }
  return (
    <>
     <ResendButton disabled={isLoading || isDisabled} onClick={handleResend} > RESEND DOCUSIGN EMAIL </ResendButton>
     {minutesUntilResend &&  (
       <p style={{fontSize:'1.4em'}}>Please wait {minutesUntilResend} minutes before resending docusign emails</p>
     )}
    </>
  )
}

function MissingSignaturesFAQS(){
  return (
    <div style={{marginTop:'20px'}}>
      <h4 style={{fontWeight: '500', color: '#0d8bd1', marginBottom:'5px'}}>WHO NEEDS TO SIGN?</h4>
      <p style={{fontSize: '1.4em', fontWeight:'100', marginTop: '0'}}>
        All applicants must review and sign the agreement. A surety bond cannot be issued until all required individuals have completed the signature process in DocuSign.
      </p>
      <h4 style={{fontWeight: '500', color: '#0d8bd1', marginBottom:'5px'}}>WHAT IS DOCUSIGN?</h4>
      <p style={{fontSize: '1.4em', fontWeight:'100', marginTop: '0'}}>
        DocuSign is a service SuretyBonds.com uses so clients can electronically sign documents quickly and easily on any device from anywhere.
      </p>
      <h4 style={{fontWeight: '500', color: '#0d8bd1', marginBottom:'5px'}}>WHAT COMES NEXT?</h4>
      <div style={{
          display:'grid',
          gridTemplateColumns: '70px 1fr',
          gridGap: '10px',
          alignItems: 'center',
        }}
      >
        <i className='icon icon-credit-card' style={{color:'#0d8bd1', fontSize:'48px',}} /> <p style={{fontSize:'1.4em', fontWeight:'100'}}>Purchase Your Bond</p>
        <i className='icon icon-file-text' style={{color:'#0d8bd1', fontSize:'48px',}} /> <p style={{fontSize:'1.4em', fontWeight:'100'}}>Receive Bond Confirmation</p>
        <i className='icon icon-checkmark' style={{color:'#0d8bd1', fontSize:'48px',}} /> <p style={{fontSize:'1.4em', fontWeight:'100'}}>Share Your Experience</p>
      </div>
    </div>
  )
}

function RecipientMessage({recipient}){
  function getStatusMessage() {
    switch(recipient.status) {
      case 'created' :
        return <><i className='icon icon-low-credit' style={{color: '#ee7b00', fontSize:'2em'}}/><p style={{margin:'0'}}>The DocuSign email was created but not sent. Please contact your Account Manager for further details.</p></>
      case 'sent' :
        return <><i className='icon icon-low-credit' style={{color: '#ee7b00', fontSize:'2em'}}/><p style={{margin:'0'}}><strong>{recipient.name}</strong> ({recipient.email}) received the DocuSign email but has not signed.</p></>
      case 'delivered' :
        return <><i className='icon icon-low-credit' style={{color: '#ee7b00', fontSize:'2em'}}/><p style={{margin:'0'}}><strong>{recipient.name}</strong> ({recipient.email}) viewed the DocuSign email but has not signed.</p></>
      case 'signed' :
        return <><i className='icon icon-low-credit' style={{color: '#ee7b00', fontSize:'2em'}}/><p style={{margin:'0'}}><strong>{recipient.name}</strong> ({recipient.email}) signed the document but has not submitted it.</p></>
      case 'declined' :
        return <><i className='icon icon-low-credit' style={{color: '#ee7b00', fontSize:'2em'}}/><p style={{margin:'0'}}><strong>{recipient.name}</strong> ({recipient.email}) declined to sign the document.</p></>
      case 'completed' :
        return <><i className='icon icon-check' style={{color: '#0d8bd1', fontSize:'2em'}}/><p style={{margin:'0'}}><strong>{recipient.name}</strong> ({recipient.email}) sucessfully submitted a DocuSign signature.</p></>
      case 'autoresponded' :
        return <><i className='icon icon-low-credit' style={{color: '#ee7b00', fontSize:'2em'}}/><p style={{margin:'0'}}><strong>{recipient.name}</strong> ({recipient.email}) has an invalid email address. Please contact your Account Manager.</p></>
      default :
        return <><i className='icon icon-low-credit' style={{color: '#ee7b00', fontSize:'2em'}}/><p style={{margin:'0'}}><strong>{recipient.name}</strong> ({recipient.email}) did not complete the DocuSign process. Please contact your Account Manager for further assistance.</p></>
    }
  }

  return (
    <div
      style={{
        display:'grid',
        gridTemplateColumns: '30px 1fr',
        alignItems: 'top',
        gridGap: '10px',
        marginBottom:'10px',
      }}
    >
      {getStatusMessage()}
    </div>
  )
}

const CheckoutTitle = styled.div`
  color: #fff;
  background-color: #0d8bd1;
  padding: 15px 20px;
  border-radius: 8px 8px 0px 0px;
  height: 30px;
`;

const ResendButton = styled.button`
  font-weight: 400;
  height: 50px;
  font-size: 1em;
  background: #99c432;
  margin-top: 20px;
`

export default MissingSignatures;