import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import Axios from 'axios';
import moment from 'moment';
import qs from 'qs';

import {
  PanelHeading,
  PanelWrapper,
  PanelTitle,
  PanelName,
  PanelIcon,
  PanelDetails,
  MobilePanelDetails,
  PanelEditButton,
} from './PanelStyles';
import { useFormState } from '../../../hooks';
import {
  FormInput,
  FormItem,
  FormLabel,
  SmartPageButton,
  FormSelect,
  ErrorMessage,
} from '../../../ExpressAppUI';
import { trackPanelSave } from '../../tracking';

const CustomFieldInput = ({
  field, values, handleChange, ...props
}) => {
  const fieldName = `customFields[${field.field}]`;
  if (field.type.toLowerCase() === 'date') {
    return (
      <>
        {props.errors[fieldName] && <p className="small-error">{props.errors[fieldName]}</p>}

        <MaskedInput
          className={`form-input ${props.hasError(fieldName) ? 'has-error' : ''}`}
          name={fieldName}
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          placeholder="MM/DD/YYY"
          defaultValue={moment(values[fieldName]).format('MM/DD/YYYY')}
          required={field.required}
          data-inputtype={'date'}
          onBlur={handleChange}
          data-iscommissiondate={field.field === 'commissionDate'}
          data-track={`Bond Requirements - ${field.display}`}
        />
      </>
    );
  } if (field.type.toLowerCase() === 'boolean') {
    return (
      <>
        {props.errors[fieldName] && <p className="small-error">{props.errors[fieldName]}</p>}
        <div className="radio-wrap">
          <input
            data-inputtype="checkbox"
            type="checkbox"
            name={fieldName}
            defaultChecked={
              values[fieldName] === true || values[fieldName] === 'true'
            }
            onBlur={handleChange}
            data-track={`Bond Requirements - ${field.display}`}
          />
          <small className="checkbox-description" style={{ marginLeft: '10px' }}>
            {field.description}
          </small>
        </div>
      </>
    );
  } if (field.options && field.options.length > 1) {
    return (
      <>
        {props.errors[fieldName] && <p className="small-error">{props.errors[fieldName]}</p>}
        <FormSelect
          name={fieldName}
          defaultValue={values[fieldName]}
          hasError={props.hasError(fieldName)}
          required={field.required}
          onChange={handleChange}
          data-track={`Bond Requirements - ${field.display}`}
        >
          <option value="" disabled>
            Select an Option
          </option>
          {field.options.map((option, i) => (
            <option key={i} value={option}>{option}</option>
          ))}
        </FormSelect>
      </>
    );
  } if (field.field === 'phone') {
    return (
      <>
        {props.errors[fieldName] && <p className="small-error">{props.errors[fieldName]}</p>}
        <MaskedInput
          mask={[
            '(',
            /\d/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          className={`${props.hasError(fieldName) ? 'has-error' : ''} form-input`}
          name={fieldName}
          defaultValue={values[fieldName]}
          required
          width="100%"
          placeholder="(000) 000-0000"
          data-inputtype="phone"
          onBlur={handleChange}
          data-track={`Bond Requirements - ${field.display}`}
        />
      </>
    );
  }
  return (
      <>
        {props.errors[fieldName] && <p className="small-error">{props.errors[fieldName]}</p>}
        <FormInput
          name={fieldName}
          defaultValue={values[fieldName]}
          required={field.required}
          data-track={`Bond Requirements - ${field.display}`}
          hasError={props.hasError(fieldName)}
          onBlur={handleChange}
          data-type={field.type}
          data-inputtype={field.field}
          readOnly={field.field === 'numberOfEmployees'}
        />
      </>
  );
};

function BondPanel({
  lead, bond, dispatch, ...props
}) {
  const [complete, setComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await Axios.post(
        `/application/${props.nonce}/new-save-bond-requirements`,
        qs.stringify(values),
      );
      trackPanelSave('Bond Info');
      dispatch({ type: 'UPDATE BOND INFO', ...values });
      setComplete(true);
    } catch {
      setShowError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const form = React.useRef(null);
  const { isActive } = props;
  const formattedFields = {
    effectiveDate: moment(bond.effDate).format('MM/DD/YYYY'),
    expirationDate: bond.expDate,
  };
  const requiredFields = ['effectiveDate', 'expirationDate'];
  const isBsb = /business service/.test(bond.name.toLowerCase());
  bond.customFields.forEach((f) => {
    const fieldName = `customFields[${f.field}]`;
    if (isBsb && f.field === 'numberOfEmployees') {
      formattedFields[fieldName] = bond.name.match(/\(([^)]+)\)/)[0];
    } else {
      formattedFields[fieldName] = f.value;
    }
    if (f.required) {
      requiredFields.push(fieldName);
    }
  });
  // Grab our form state and event handlers
  const [
    values,
    touched,
    errors,
    formBlur,
    submitForm,
    { hasError },
  ] = useFormState(formattedFields, requiredFields, handleSubmit, form);

  React.useLayoutEffect(() => {
    async function getPremium() {
      try {
        const { data } = await Axios.post(`/application/${props.nonce}/update-effective-date`, {
          effectiveDate: values.effectiveDate,
        });

        dispatch({ type: 'UPDATE INVOICE AND BALANCE', ...data });
      } catch (error) {
        setShowError(true);
      }
    }
    if (
      values.effectiveDate
      && touched.effectiveDate
      && !errors.effectiveDate
    ) {
      getPremium();
    }
  }, [values.effectiveDate]);

  return (
    <PanelWrapper
      className={`is-active-${isActive} instant-purchase-panel`}
      active={isActive}
    >
      <PanelHeading>
        <PanelTitle>
          <PanelIcon complete={complete} icon="clipboard"></PanelIcon>
          <PanelName>Bond Info</PanelName>
        </PanelTitle>
        {!isActive && complete ? (
          <>
            <PanelDetails>
              <div>
                <span>
                  <strong>Exp. Date</strong> {values.effectiveDate}
                </span>
              </div>
              {bond.customFields.map((field, i) => {
                const key = `customFields[${field.display}]`;
                return (
                  !!values[key] && !errors[key] ? (
                      <div key={i}>
                        <span>
                          <strong>{field.display}</strong>
                          {values[key]}
                        </span>
                      </div>
                  ) : null
                );
              })}
            </PanelDetails>
            <PanelEditButton
              onClick={() => {
                dispatch({ type: 'CHANGE ACTIVE STEP', activePanel: 'Bond' });
              }}
            >
              Edit
            </PanelEditButton>
          </>
        ) : null}
      </PanelHeading>
      <MobilePanelDetails>
        <div>
            <p>Effective {values.effectiveDate}</p>
            {bond.customFields.map((field, i) => {
              const key = `customFields[${field.display}]`;
              return (
                !!values[key] && !errors[key] ? (
                      <p key={i}>
                        {field.display}: {values[key]}
                      </p>
                ) : null
              );
            })}
        </div>
      </MobilePanelDetails>
      {isActive && (
        <form action="" ref={form}>
          <FormItem>
            <p>When do you want your bond to start?</p>
            {errors.effectiveDate && <p className="small-error">{errors.effectiveDate}</p>}
            <MaskedInput
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="MM/DD/YYYY"
              name="effectiveDate"
              defaultValue={values.effectiveDate}
              required
              className={`${
                hasError('effectiveDate') ? 'has-error' : ''
              } form-input`}
              data-inputtype="date"
              onBlur={formBlur}
              data-track="Bond Requirements - Effective Day"
              data-last-valid-expiration={bond.lastValidExpiration}
            />
          </FormItem>
          {bond.customFields && bond.customFields.length > 0 && (
            <FormItem>
              <p >
                Your {bond.name} has some has some additional requirements that
                need to be filled out before the bond can be issued.
              </p>
              {bond.customFields.map((field, i) => (
                <FormItem key={i}>
                  <FormLabel>
                    {field.display}
                    {field.required && <sup>*</sup>}
                    <i className="sub-label">{field.description}</i>
                  </FormLabel>
                  {errors.phone && <p className="small-error">{errors.phone}</p>}
                  <CustomFieldInput
                    values={values}
                    hasError={hasError}
                    handleChange={formBlur}
                    field={field}
                    errors={errors}
                  ></CustomFieldInput>
                </FormItem>
              ))}
            </FormItem>
          )}
          <input
            required
            type="hidden"
            name="expirationDate"
            value={values.expirationDate}
          />
          <SmartPageButton
            className='instant-purchase-panel-button'
            buttonText='Save & Continue'
            onClickFunction={submitForm}
            isSubmitting={submitting}
            track="Bond Requirements - Save"
          >
          </SmartPageButton>
          {showError ? (
            <ErrorMessage>
              An error has occurred when trying to save your information. Please reload the page and try again. If you keep getting this error message, please give us a call at <a href='tel:+18003084358'> 1(800)308-4358 </a>
            </ErrorMessage>
          ) : null}
        </form>
      )}
    </PanelWrapper>
  );
}

export default BondPanel;
