import Axios from 'axios';
import React, { useEffect } from 'react';
import { FormItem, FormLabel, FormSelect } from '../../ExpressAppUI';
import { toFullStateName } from '../../helpers';
import { StartAppContext } from '../StartAppContext';
import { Mixpanel } from '../../mixpanel';

export function Affiliate(props) {
  const { state, updateState } = React.useContext(StartAppContext);
  const [bonds, setBonds] = React.useState(false);

  function handleBondSelect(e) {
    props.navigate(
      `/start-application/?bondId=${e.target.value}&partnerId=${
        props.partnerID
      }`,
    );
  }

  useEffect(() => {
    async function getAffiliateInfo(affiliateID) {
      const { data } = await Axios.get(
        `/start-application/get-partner-bonds/${affiliateID}`,
      );
      Mixpanel.register({ partner: data.name });
      window.sessionStorage.setItem('affiliateName', data.name);
      if (data.logoUrl) {
        window.sessionStorage.setItem('affiliateLogo', data.logoUrl);
      }
      updateState({ ...state, affiliateName: data.name, loading: false });
      if (data.bonds.length === 1) {
        props.navigate(
          `/start-application/?bondId=${data.bonds[0].id}&partnerId=${
            props.partnerID
          }`,
        );
      } else {
        Mixpanel.track('Partner Lead - Bond Selection');
        setBonds(data.bonds);
      }
    }
    getAffiliateInfo(props.partnerID);
  }, []);

  return (
    <>
      {!bonds ? null : (
        <>
          <div class="panel-title text-center">Bond Selection</div>
          <FormItem>
            <FormLabel>Which Bond?</FormLabel>
            <FormSelect name="bondAmount" required onChange={handleBondSelect}>
              <option value="" selected disabled>
                Please Select a Bond
              </option>
              {bonds.map((bond, idx) => (
                <option value={bond.id} key={`bond-option-${idx}`}>
                  {bond.state.length === 1
                    ? toFullStateName(bond.state[0])
                    : ''}{' '}
                  {bond.name}
                </option>
              ))}
            </FormSelect>
          </FormItem>
        </>
      )}
    </>
  );
}
