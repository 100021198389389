import React from 'react';
import Axios from 'axios';
import qs from 'qs';

import { validateInput, getCityState, shouldAskStartDate } from '../helpers';

import { CompanyFields } from './CompanyFields';

import { Mixpanel } from '../mixpanel';
import moment from "moment"
export default class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    let principalOptions = [];
    const { principalType } = props;

    const initProps = { ...props };
    let initIncomplete = initProps.incompleteFields;
    let initFields = { ...initProps.fields };

    if (principalType.toLowerCase() === 'both') {
      principalOptions.push({ value: 'individual', display: 'Individual' });
    }

    if (principalType.toLowerCase() !== 'individual') {
      principalOptions = [
        ...principalOptions,
        ...[
          {
            value: 'sole_proprietorship',
            display: 'Sole Proprietorship',
          },
          {
            value: 'partnership',
            display: 'Partnership',
          },
          {
            value: 'corporation',
            display: 'Corporation',
          },
          {
            value: 'llc',
            display: 'Limited Liability Company (LLC)',
          },
          {
            value: 'llp',
            display: 'Limited Liability Partnership (LLP)',
          },
        ],
      ];
    } else {
      principalOptions.push({ value: 'individual', display: 'Individual' });
    }

    // do we need to ask for company start date?
    const askStartDate = shouldAskStartDate(initFields.companyType, principalType);
    if (!askStartDate) {
      // remove the required field
      initIncomplete = initIncomplete.filter(field => field !== 'companyStarted')
    } else {
      if (!initFields.companyStarted && !initIncomplete.includes('companyStarted')) { // add the required field if not present
        initIncomplete.push('companyStarted')
      }
    }
    // if we need to ask for start date, account for that in how many required fields there are
    const numberOfRequiredFields = askStartDate ? 7 : 6;
    this.state = {
      autoSave: initIncomplete.length !== 0,
      touched: initIncomplete.length < numberOfRequiredFields ? Object.keys(initFields) : [],
      fields: initFields,
      // remove the fields that we don't need to ask for
      incompleteFields: askStartDate ? initIncomplete : initIncomplete.filter(field => field !== 'companyStarted'),
      errors:
        initIncomplete.length > 0 && initIncomplete.length !== numberOfRequiredFields
          ? { missingFields: true }
          : {},
      principalOptions,
      submitting: false,
    };
  }

  setStartDateToday = (e) => {
    e.preventDefault();

    const { fields, errors, incompleteFields } = this.state;

    errors.companyStarted = false;

    fields.companyStarted = moment(new Date()).format('MM/DD/YYYY');

    if (incompleteFields.includes('companyStarted')) {
      incompleteFields.splice(incompleteFields.indexOf('companyStarted'), 1);
    }

    this.setState({ fields, errors, incompleteFields });
  };



  localBlur = async (e) => {
    e.preventDefault();

    // mixpanel Blur
    const {
      name,
      value,
      required,
      dataset: { inputtype = '', track },
    } = e.target;

    Mixpanel.track(track);

    const {
      touched, incompleteFields, fields, errors,
    } = this.state;

    let newIncomplete = [...incompleteFields];

    if (!touched.includes(name)) {
      touched.push(name);
    }

    // If the user selected individual company type, we dont need to ask companyStarted question
    if (name === 'companyType') {
      if (value === 'individual') {
        newIncomplete = newIncomplete.filter(field => field !== 'companyStarted');
      }
      else if (!newIncomplete.includes('companyStarted')) {
        newIncomplete.push('companyStarted');
      }
    }

    if (name.toLowerCase() === 'has-dba') return false;

    // ValidateField -- returns  {newValue: newVal || '', error: errorMsg || '' }
    const { newValue, error } = validateInput(name, value, inputtype);

    const isSame = newValue === fields[name];

    // If we have an
    if (newValue === '' && required) {
      errors.missingFields = true;
      e.target.value = '';
    }

    if (error) {
      errors.name = true;
    }

    // track field in
    const isMissing = newIncomplete.includes(name);

    if (isMissing && required && !!newValue) {
      newIncomplete.splice(newIncomplete.indexOf(name), 1);
    } else if (!isMissing && required && !newValue) {
      newIncomplete.push(name);
    }

    if (newIncomplete.length === 0) {
      errors.missingFields = false;
    }

    fields[name] = newValue;

    if (name === 'companyZip' && !!newValue) {
      const cityState = await getCityState(newValue);
      // Add the city and state values with their scoped names so we can check against them
      cityState.companyState = cityState.state;
      cityState.companyCity = cityState.city;
      if (cityState) {
        ['companyState', 'companyCity'].forEach((f) => {
          if (cityState[f]) {
            fields[f] = cityState[f];
          }
          if (newIncomplete.includes(f) && !!cityState[f]) {
            newIncomplete.splice(newIncomplete.indexOf(f), 1);
          }
          if (!touched.includes(f) && !!cityState[f]) {
            touched.push(f);
          }
        });
      }
    }


    this.setState(
      {
        fields,
        incompleteFields: newIncomplete,
        touched,
        errors,
      },
      function () {
        if (!isSame && ((!!required && !!newValue) || !required)) {
          if (this.state.autoSave) {
            // Post to DB

            // TODO: ADD SAVE FIELDS

            Axios.post(
              `${window.location.pathname}/company${window.location.search}`,
              qs.stringify({ [name]: newValue, lastStep: false }),
            );
          }
          // Post to DB
        }
      },
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const data = { ...this.state.fields, lastStep: true };

    const { incompleteFields } = this.state;

    if (incompleteFields.length === 0) {
      this.setState({
        submitting: true,
        errors: { ...this.state.errors, missingFields: false },
      });
      // this.props.saveForm(data)
      Axios.post(
        `${window.location.pathname}/company${window.location.search}`,
        qs.stringify(data),
      ).then(() => {
        Mixpanel.track('Company Information Submitted');
        this.setState({ submitting: false });
        this.props.updateFields(data, 'company');
      });
    } else if (incompleteFields.length > 0) {
      this.setState({
        errors: { ...this.state.errors, missingFields: true },
        touched: Object.keys(this.state.fields),
      });
      return false;
    }
  };

  hasError = (field) => {
    const { touched, incompleteFields } = this.state;

    if (incompleteFields.includes(field) && touched.includes(field)) {
      return true;
    }
  };

  localChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    const { fields } = this.state;

    fields[name] = value;

    this.setState({ fields });
  };

  render() {
    return (
      <CompanyFields
        principalOptions={this.state.principalOptions}
        principalType={this.props.principalType}
        fields={this.state.fields}
        handleBlur={this.localBlur}
        handleChange={this.localChange}
        handleSubmit={this.handleSubmit}
        incompleteFields={this.state.incompleteFields}
        hasError={this.hasError}
        errors={this.state.errors}
        submitting={this.state.submitting}
        setStartDateToday={this.setStartDateToday}
      />
    );
  }
}
