import React from 'react';
import moment from 'moment';

const TransactionSummary = (props) => {
  const transactionDate = () => {
    const date = new Date(props.charges.slice(-1)[0].created) || new Date();
    const dateStr = moment(date).format('MM/DD/YYYY - h:mm a');
    return dateStr;
  };
  return (
    <>
      <p style={{ marginBottom: '8px' }}><strong>Transaction #:</strong> {props.charges.slice(-1)[0].transactionId}</p>
      <p style={{ marginTop: 0 }}><strong>Transaction Submitted:</strong> {transactionDate()}</p>
    </>
  );
};

export default TransactionSummary;
