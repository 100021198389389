import React from 'react';

const TrustPilotWidget = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div style={{ padding: '15px' }}>
      <div
        ref={ref} // We need a reference to this element to load the TrustBox in the effect.
        className="trustpilot-widget" // Renamed this to className.
        data-locale="en-US"
        data-template-id="539ad0ffdec7e10e686debd7"
        data-businessunit-id="55394aa90000ff00057ef804"
        data-style-height="350px"
        data-style-width="100%"
        data-theme="light"
        data-tags="SiteReview"
        data-stars="4,5"
      >
        <a href="https://www.trustpilot.com/review/suretybonds.com " target="_blank" rel="noopener"> Trustpilot
        </a>
      </div>
    </div>
  );
};
export default TrustPilotWidget;
