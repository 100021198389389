import moment from 'moment';
import Axios from 'axios';

// Function uses regex to detect card type.
// Returns name of card in all caps
// Shamelessly stolen from here: http://stackoverflow.com/a/19138852/23875
export function detectCardType(number) {
  var re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  if (re.electron.test(number)) {
    return 'ELECTRON';
  } if (re.maestro.test(number)) {
    return 'MAESTRO';
  } if (re.dankort.test(number)) {
    return 'DANKORT';
  } if (re.interpayment.test(number)) {
    return 'INTERPAYMENT';
  } if (re.unionpay.test(number)) {
    return 'UNIONPAY';
  } if (re.visa.test(number)) {
    return 'VISA';
  } if (re.mastercard.test(number)) {
    return 'MASTERCARD';
  } if (re.amex.test(number)) {
    return 'AMEX';
  } if (re.diners.test(number)) {
    return 'DINERS';
  } if (re.discover.test(number)) {
    return 'DISCOVER';
  } if (re.jcb.test(number)) {
    return 'JCB';
  } else {
    return undefined;
  }
}

export async function getCityState(zip) {
  const location = {
    city: null,
    state: null,
  };

  const res = await Axios.post(
    `https://maps.googleapis.com/maps/api/geocode/json?components=country:US|postal_code:${zip}&key=AIzaSyAJZIocwFpCD8Nu2QNG6Jyg0VWZ9JH3JP8`,
  );

  if (res.data.results.length <= 0) {
    return location;
  }
  const thisLocation = res.data.results[0].address_components;

  thisLocation.forEach((addressBit) => {
    addressBit.types.forEach((type) => {
      if (type === 'locality') {
        location.city = addressBit.short_name;
      } else if (type === 'administrative_area_level_1') {
        location.state = addressBit.short_name;
      }
    });
  });

  return location;
}

export function getQueryVariable(variable) {
  const query = decodeURIComponent(window.location.search.substring(1));
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

export function isScrolledIntoView(el) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  // Only completely visible elements return true:
  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export const validateInput = (name, value, inputType = '', otherData = {}) => {
  const validated = { newValue: value, error: '' };

  if (inputType === 'mailingAddress' || inputType === 'mailingAddress2') {
    const poBoxRegex = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/gi;
    if (value.match(poBoxRegex)) {
      validated.newValue = '';
      validated.error = 'Bond documents cannot be sent to a PO Box.';
    }
  }

  if (inputType === 'money') {
    validated.newValue = value.substr(1);
  }

  if (inputType === 'zip') {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    if (!isValidZip) {
      validated.newValue = '';
      validated.error = 'Please enter a valid zip code';
    }
  }

  if (inputType.toLowerCase() === 'ssn') {
    value = value.replace(/\D/g, '').length !== 9 ? '' : value;

    validated.newValue = value;
    validated.error = !value
      ? 'Please enter a valid 9 Digit Social Security Number'
      : '';
  } else if (inputType.toLowerCase() === 'phone') {
    const phoneRe = /^(.)\1{9}|^(..)\2{4}|^((.)..)\3{2}\4|(1234567890|0123456789|0987654321|9876543210)|^(555)|^(0)|^(1)|^(.11)|^\d\d\d555/;
    value = value.replace(/\D/g, '');
    value = phoneRe.test(value) || value.length !== 10 ? '' : value;
    validated.newValue = value;
    validated.error = !value
      ? 'Please enter a valid 10 Digit US Phone Number'
      : '';
  } else if (inputType === 'email') {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(value).toLowerCase())) {
      validated.newValue = '';
      validated.error = 'Please enter a valid Email Address';
    } else {
      validated.error = '';
      validated.newValue = value;
    }
  } else if (inputType.toLowerCase() === 'date') {
    value = value.replace(/\D/g, '').length !== 8 ? '' : value;

    const date = moment(value, 'MM/DD/YYYY');

    // Valid Date
    if (!date.isValid()) {
      validated.newValue = '';
      validated.error = 'Please enter a valid Date as MM/DD/YYYY';
    } else if (name.toLowerCase() === 'dob') {
      const start = moment('01/01/1850', 'MM/DD/YYYY');
      const end = moment(new Date());

      if (!date.isBetween(start, end)) {
        validated.newValue = '';
        validated.error = "Are you sure that's your birthday?";
      }
    } else if (!!value && (name.toLowerCase() === 'effectivedate' || otherData.iscommissiondate === 'true')) {
      const today = moment(new Date());
      const past = moment(today).subtract(30, 'days');
      const future = moment(today).add(90, 'days');

      if (!date.isBetween(past, future)) {
        validated.error = `Please enter a date no earlier than ${past.format(
          'MMMM DD, YYYY',
        )} or no later than ${future.format('MMMM DD, YYYY')}`;
        validated.newValue = '';
      }

      if (otherData.lastValidExpiration) {
        const lastValidExpiration = moment(otherData.lastValidExpiration, 'MM/DD/YYYY');
        if (date.isBefore(lastValidExpiration)) {
          validated.error = `Invalid effective date: This bond may not start before ${otherData.lastValidExpiration}`;
          validated.newValue = '';
        }
      }
    }
  } else if (inputType.toLowerCase() === 'ppk') {
    // Cast to numbers so we can accurately compare
    const low = parseInt(otherData.inputlow, 10);
    const high = parseInt(otherData.inputhigh, 10);
    // Strip our formatting so we have a number only
    value = value.substr(1).replace(',', '');
    value = parseInt(value, 10);

    validated.newValue = value;
    validated.error = '';

    if ((value < low) || (value > high)) {
      validated.newValue = '';
      validated.error = `Please enter a bond amount above $${low} and below $${high}`;
    }
  }

  return validated;
};

export function formatPhone(phoneNumber) {
  phoneNumber = phoneNumber.toString();
  let numStr = phoneNumber.replace(/[^0-9]/g, '');
  let result = '';

  while (numStr.length < 10) {
    numStr += '_';
  }

  result += `(${numStr.substring(0, 3)})`;
  result += `${numStr.substring(3, 6)}-`;
  result += numStr.substring(6, 10);

  if (numStr.length > 10) {
    result += ` x${numStr.substring(10)}`;
  }

  return result;
}

export function toFullStateName(state) {
  const stateList = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  };

  return stateList[state];
}

export function getTypeLabel(type) {
  const busType = {
    corporation: 'Corporation',
    individual: 'Invididual',
    llc: 'Limited Liability Company (LLC)',
    llp: 'Limited Liability Partnership (LLP)',
    partnership: 'Partnership',
    sole_proprietorship: 'Sole Proprietorship',
  };

  return busType[type];
}

function getDecimal(amount) {
  const n = Math.abs(amount);
  let dec = n - Math.floor(n);
  dec = (Math.round(dec * 100) / 100).toString();

  if (dec.split('.').length) return dec.split('.')[1];

  return '';
}

export function toMoney(amount, { noCents = true } = {}) {
  let integer = amount.toString().split('.')[0];
  let decimal = getDecimal(amount);

  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (noCents) return `$${integer}`;

  if (!decimal || !decimal.length) decimal = '00';
  else if (decimal.length === 1) decimal += '0';
  else if (decimal.length > 2) decimal = decimal.substr(0, 2);

  return `$${integer}.${decimal}`;
}

export const StateOptions = [
  { value: 'AL', display: 'Alabama' },
  { value: 'AK', display: 'Alaska' },
  { value: 'AZ', display: 'Arizona' },
  { value: 'AR', display: 'Arkansas' },
  { value: 'CA', display: 'California' },
  { value: 'CO', display: 'Colorado' },
  { value: 'CT', display: 'Connecticut' },
  { value: 'DE', display: 'Delaware' },
  { value: 'DC', display: 'District' },
  { value: 'FL', display: 'Florida' },
  { value: 'GA', display: 'Georgia' },
  { value: 'HI', display: 'Hawaii' },
  { value: 'ID', display: 'Idaho' },
  { value: 'IL', display: 'Illinois' },
  { value: 'IN', display: 'Indiana' },
  { value: 'IA', display: 'Iowa' },
  { value: 'KS', display: 'Kansas' },
  { value: 'KY', display: 'Kentucky' },
  { value: 'LA', display: 'Louisiana' },
  { value: 'ME', display: 'Maine' },
  { value: 'MD', display: 'Maryland' },
  { value: 'MA', display: 'Massachusetts' },
  { value: 'MI', display: 'Michigan' },
  { value: 'MN', display: 'Minnesota' },
  { value: 'MS', display: 'Mississippi' },
  { value: 'MO', display: 'Missouri' },
  { value: 'MT', display: 'Montana' },
  { value: 'NE', display: 'Nebraska' },
  { value: 'NV', display: 'Nevada' },
  { value: 'NH', display: 'New Hampshire' },
  { value: 'NJ', display: 'New Jersey' },
  { value: 'NM', display: 'New Mexico' },
  { value: 'NY', display: 'New York' },
  { value: 'NC', display: 'North Carolina' },
  { value: 'ND', display: 'North Dakota' },
  { value: 'OH', display: 'Ohio' },
  { value: 'OK', display: 'Oklahoma' },
  { value: 'OR', display: 'Oregon' },
  { value: 'PA', display: 'Pennsylvania' },
  { value: 'RI', display: 'Rhode Island' },
  { value: 'SC', display: 'South Carolina' },
  { value: 'SD', display: 'South Dakota' },
  { value: 'TN', display: 'Tennessee' },
  { value: 'TX', display: 'Texas' },
  { value: 'UT', display: 'Utah' },
  { value: 'VT', display: 'Vermont' },
  { value: 'VA', display: 'Virginia' },
  { value: 'WA', display: 'Washington' },
  { value: 'WV', display: 'West Virginia' },
  { value: 'WI', display: 'Wisconsin' },
  { value: 'WY', display: 'Wyoming' },
];

export const contact = {
  isPrimary: false,
  fields: {
    '[name][first]': '',
    '[name][last]': '',
    dob: '',
    ssn: '',
    phone: '',
    email: '',
    address: '',
    address2: '',
    zipcode: '',
    city: '',
    state: '',
    home_type: '',
    experience: '',
  },
  incompleteFields: [
    '[name][first]',
    '[name][last]',
    'dob',
    'ssn',
    'phone',
    'email',
    'address',
    'zipcode',
    'city',
    'state',
    'home_type',
  ],
};

export function getFields(fields, category) {
  const filteredFields = {};
  const fieldNames = Object.keys(fields);

  fieldNames.forEach((name) => {
    if (fields[name].category === category) {
      filteredFields[name] = fields[name];
    }
  });

  return filteredFields;
}

export function getCustomFields(fields) {
  const customFields = [];
  const bondFields = getFields(fields, 'bond');

  Object.keys(bondFields).forEach((f) => {
    if (f.includes('customField')) {
      customFields.push(fields[f]);
    }
  });

  return customFields;
}

export function formatAmountAsNumber(amount) {
  const asNumber = Number(amount.replace(/[^0-9.-]+/g, ''));
  return asNumber;
}

export function formatDateToMMDDYYYY(date) {
  date = new Date(date);
  if (isNaN(date.getTime())) return NaN;
  const month = `${date.getMonth() + 1}`;
  return `${month.length == 1 ? `0${month}` : month}/${date.getDate()}/${date.getFullYear().toString()}`;
}

export function shouldAskStartDate(leadCompanyType, bondPrincipalType) {
  if (leadCompanyType?.toLowerCase() === 'individual') {
    return false;
  }
  else if (bondPrincipalType === 'individual') return false;
  return true
}
