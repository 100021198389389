import Axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from '@reach/router';
import React from 'react';

export async function APIClient(endpoint, { data, method, ...customConfig } = {}) {
  const config = {
    method: method || (data ? 'POST' : 'GET'),
    data: data || {},
    ...customConfig,
    url: `${process.env.REACT_APP_API_URL}/api/${endpoint}`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await Axios(config);
    return response
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    }
    if (error.request) {
      const objectError = JSON.parse(JSON.stringify(error));
      return { error: objectError };
    }
  }
}

export function useLead() {
  const { nonce } = useParams();
  const query = useQuery('lead', () => APIClient(`nonces/${nonce}/get-lead`), {staleTime: 500});

  const lead = query.data && query.data.data && query.data.data.lead;
  const invoice = query.data && query.data.data && query.data.data.invoice;
  if (invoice) {
    invoice.balance = getTotalBalance(invoice.charges);
  }
  return { ...query, lead: lead || null, invoice: invoice || null };
}

export function useUpdateLead(options) {
  const { lead } = useLead();
  const queryClient = useQueryClient();
  const leadId = lead._id.toString();
  return useMutation(
    (leadUpdate) => {
      const url = `leads/${leadId}`;
      return APIClient(url, {
        method: 'PUT',
        data: leadUpdate,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('lead');
      },
      ...options,
    },
  );
}

export function useAsync(fetcher) {
  const [{ status, data, error }, setState] = React.useState(
    { status: 'idle', data: null, error: null },
  );

  function doFetch(fetchFunction) {
    setState({ status: 'pending', data, error });
    return fetchFunction().then((response) => {
      if(response.error) throw new Error(response.error)
      setState({ status: 'resolved', data: response, error: null });
    }).catch((e) => {
      setState({ status: 'rejected', error: e, data });
    });
  }

  React.useEffect(() => {
    if (fetcher) {
      doFetch(fetcher);
    }
  }, []);

  return {
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    error,
    status,
    data,
    doFetch,
  };
}

function getTotalBalance(charges) {
  let totalBalance = 0;
  if (charges.length) {
    charges.forEach((charge) => {
      totalBalance += charge.amount;
    });
  }
  return totalBalance;
}
