import React from 'react';

export default function Footer() {
  return (
    <footer className="page-footer">
      <div className="container" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{maxWidth: '225px', width: '100%'}}>
          <img
            src="/img/brand/logo-color-white.svg"
            alt="SuretyBonds.com"
          />
        </div>
        <div >
          <img src="/img/logos/ssl.png" alt="SSL Secured" />
        </div>
      </div>
    </footer>
  );
}
