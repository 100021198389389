import { Mixpanel } from '../mixpanel';

export const initEngagedTracking = (nonce, data) => {
  // Init mixpanel and datalayer
  Mixpanel.identify(nonce);
  window.dataLayer = window.dataLayer || [];

  const { lead } = data;
  const { suretyBond } = lead.bond.application;
  const { bond } = lead;

  const mixpanelTrack = {
    'Nonce ID': nonce,
    'Bond ID': suretyBond._id.toString(),
    'Bond Type': suretyBond?.name || bond.type,
    'Bond Amount': bond.amount,
    'Bond State': bond.state,
    'Bond Version': bond.version,
    Type: 'Re-market',
    'Term Length': lead.app.multiTerm,
    isRemarket: true,
  };
  Mixpanel.register(mixpanelTrack);
  Mixpanel.track(data.isConfirmPage ? 'Re-market Confirm' : 'Re-market Market');
  // In order for our chat widget to track variables -- we pass them to the global layer

  window.leadId = lead._id.toString();
  window.bondType = suretyBond?.name || bond.type;
  window.bondState = bond.state;
  window.bondAmount = bond.amount;
  const dataLayerTrack = {
    leadId: lead._id.toString(),
    userId: lead._id.toString(),
    bondId: suretyBond,
    bondType: suretyBond?.name || bond.type,
    bondState: bond.state,
    bondAmount: bond.amount,
    bondVersion: bond.version,
    nonceId: nonce,
    event: 'Data Layer Ready',
    ecData: {
      email: lead.email || '',
      phone_number: lead.phone || '',
    },
    isRemarket: true,
  };
  window.dataLayer.push(dataLayerTrack);
};
