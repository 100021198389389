import React, { useEffect, useState, useReducer } from 'react';
import Axios from 'axios';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import qs from 'qs';
import {
  toFullStateName,
  toMoney,
  formatPhone,
  StateOptions,
} from '../helpers';
import Layout from './Layout';
import {
  FormInput,
  FormItem,
  FormLabel,
  SmartPageButton,
  FormSelect,
  ErrorMessage,
} from '../ExpressAppUI';
import { useFormState } from '../hooks';
import { initEngagedTracking } from './tracking';

const HeaderCopy = ({ remarket }) => (
      <>
        <h1>
        Verify your information
        </h1>

        <h3>Before submitting your application, please ensure the details we have on file are up-to-date.If you need assistance updating your information, contact a surety expert between 7 AM-7 PM Monday-Friday at  <a href="tel:+18003084358">1 (800) 308-4358</a>.</h3>
      </>
);

const CustomFieldInput = ({
  field,
  values,
  handleChange,
  ...props
}) => {
  const fieldName = `customFields[${field.field}]`;
  if (field.type.toLowerCase() === 'date') {
    return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}

        <MaskedInput
          className={`form-input ${
            props.hasError(fieldName) ? 'has-error' : ''
          }`}
          name={fieldName}
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          placeholder="MM/DD/YYY"
          defaultValue={moment(values[fieldName]).format('MM/DD/YYYY')}
          required={field.required}
          data-inputtype={'date'}
          onBlur={handleChange}
          data-iscommissiondate={field.field === 'commissionDate'}
          data-track={`Bond Requirements - ${field.display}`}
        />
      </>
    );
  } if (field.type.toLowerCase() === 'boolean') {
    return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
        <div className="radio-wrap">
          <input
            data-inputtype="checkbox"
            type="checkbox"
            name={fieldName}
            defaultChecked={
              values[fieldName] === true || values[fieldName] === 'true'
            }
            onBlur={handleChange}
            data-track={`Bond Requirements - ${field.display}`}
          />
          <small
            className="checkbox-description"
            style={{ marginLeft: '10px' }}
          >
            {field.description}
          </small>
        </div>
      </>
    );
  } if (field.optionList && field.optionList.length > 1) {
    return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
        <FormSelect
          name={fieldName}
          defaultValue={values[fieldName]}
          hasError={props.hasError(fieldName)}
          required={field.required}
          onChange={handleChange}
          data-track={`Bond Requirements - ${field.display}`}
        >
          <option value="" selected={!field.value} disabled>
            Select an Option
          </option>
          {field.optionList.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </FormSelect>
      </>
    );
  } if (field.field === 'phone') {
    return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
        <MaskedInput
          mask={[
            '(',
            /\d/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          className={`${
            props.hasError(fieldName) ? 'has-error' : ''
          } form-input`}
          name={fieldName}
          defaultValue={values[fieldName]}
          required
          width="100%"
          placeholder="(000) 000-0000"
          data-inputtype="phone"
          onBlur={handleChange}
          data-track={`Bond Requirements - ${field.display}`}
        />
      </>
    );
  }
  return (
      <>
        {props.errors[fieldName] && (
          <p className="small-error">{props.errors[fieldName]}</p>
        )}
        <FormInput
          name={fieldName}
          defaultValue={values[fieldName]}
          required={field.required}
          data-track={`Bond Requirements - ${field.display}`}
          hasError={props.hasError(fieldName)}
          onBlur={handleChange}
          data-type={field.type}
          data-inputtype={field.field}
        />
      </>
  );
};

const CustomField = (props) => {
  const { field, i } = props;
  if (field.required) {
    return (
      <div style={{ margin: '6px 0px' }} key={i}>
       <FormLabel>
          {field.display}
          {field.required && <sup>*</sup>}
          <i className="sub-label">{field.description}</i>
          </FormLabel>
        <CustomFieldInput {...props} />
      </div>
    );
  } return (
      <input name={`customFields[${field.field}]`} value="" type="hidden" />
  );
};
// TODO: CN-1498 use company collection from lead starting line 291
const Engaged = ({
  state, dispatch, nonce, ...props
}) => {
  const form = React.useRef(false);
  const formattedFields = {
    effectiveDate: moment(state.lead.bond.application.effectiveDate).format(
      'MM/DD/YYYY',
    ),
    companyName: state.lead.company_name,
    companyDBA: state.lead.company_dba || '',
    companyAddress: state.lead.company_address,
    companyAddress2: state.lead.company_address2,
    companyState: state.lead.company_state,
    companyZip: state.lead.company_zip,
    companyCity: state.lead.company_city,
  };

  const requiredFields = [
    'effectiveDate',
    'companyAddress',
    'companyState',
    'companyZip',
    'companyCity',
  ];

  state.lead.customFields.forEach((f) => {
    const fieldName = `customFields[${f.field}]`;
    formattedFields[fieldName] = f.value || '';
    if (f.required) {
      requiredFields.push(fieldName);
    }
  });

  const [isSubmitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await Axios.post(`/engage/${nonce}/market/confirm`, qs.stringify(values));
      props.navigate(`/engage/${nonce}/market/confirm`);
    } catch (error) {
      setSubmitting(false);
      setError(true);
      // TODO: Show error!
    }
  };
  // Grab our form state and event handlers
  const [
    values,
    touched,
    errors,
    formBlur,
    submitForm,
    { hasError },
  ] = useFormState(formattedFields, requiredFields, handleSubmit, form);

  return (
    <Layout>
      <form ref={form} className="center">
        {error && (
          <p className="error-message">
            An error has occurred when trying to save your information. Please reload the page and try again. If you keep getting this error message, please give us a call at <a href='tel:+18003084358'> 1(800)308-4358 </a>
          </p>
        )}
        <div className="thank-you-copy" style={{ marginTop: '20px' }}>
          <HeaderCopy/>
        </div>
        <div className="thank-you-page">
          <div className="thank-you-heading">Bond Information</div>
          <hr />
          <div className="black-label">
            {toFullStateName(state.lead.bond.state)}
            <br />
            {state.lead.bond?.application?.suretyBond?.name || state.lead.bond.type}
          </div>
          <div className="thank-you-section">
            <div style={{ margin: '6px 0px' }}>
              <FormLabel>Bond Amount</FormLabel>
              <div className="value">{state.lead.bond.amount}</div>
            </div>
            <div style={{ margin: '6px 0px' }}>
              <FormLabel>Effective Date</FormLabel>
              {errors.effectiveDate && (
                <p className="small-error">{errors.effectiveDate}</p>
              )}
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="MM/DD/YYYY"
                name="effectiveDate"
                defaultValue={values.effectiveDate}
                required
                className={`${
                  hasError('effectiveDate') ? 'has-error' : ''
                } form-input`}
                data-inputtype="date"
                onBlur={formBlur}
                data-track="Bond Requirements - Effective Day"
              />
            </div>

            {state.lead.customFields
              && state.lead.customFields.length > 0
              && state.lead.customFields.map((field, i) => (
                <CustomField
                  values={values}
                  hasError={hasError}
                  handleChange={formBlur}
                  field={field}
                  errors={errors}
                />
              ))}
          </div>
          <div className="thank-you-middle">
            <div className="thank-you-heading">Principal Information</div>
            <div className="thank-you-section principal">
              <div className="black-label">
                <FormLabel>Name</FormLabel>
                {errors.companyName && (
                  <p className="small-error">{errors.companyName}</p>
                )}
                <FormInput
                  required
                  autocomplete="new-password"
                  onBlur={formBlur}
                  name="companyName"
                  defaultValue={values.companyName}
                  hasError={hasError('companyName')}
                  data-track={'Principal Info - Name'}
                />
                {state.principalType !== 'individual' && (
                  <>
                    <FormLabel>DBA</FormLabel>
                    <FormInput
                      autocomplete="new-password"
                      onBlur={formBlur}
                      name="companyDBA"
                      defaultValue={values.companyDBA}
                      hasError={hasError('companyDBA')}
                      data-track={'Principal Info - DBA'}
                    />
                  </>
                )}
                <address>
                  <FormLabel>Address</FormLabel>
                  {errors.companyAddress && (
                    <p className="small-error">{errors.companyAddress}</p>
                  )}
                  <FormInput
                    placeholder="Street Address"
                    data-inputtype="address"
                    required
                    autocomplete="new-password"
                    onBlur={formBlur}
                    name="companyAddress"
                    defaultValue={values.companyAddress}
                    hasError={hasError('companyAddress')}
                    onBlur={formBlur}
                    data-track={'Principal Info - Address'}
                  />
                  <FormInput
                    placeholder="Suite, Unit, Building, Floor, Etc."
                    data-inputtype="address"
                    name="companyAddress2"
                    autocomplete="new-password"
                    onBlur={formBlur}
                    defaultValue={values.companyAddress2}
                    hasError={hasError('companyAddress2')}
                    onBlur={formBlur}
                    data-track={'Principal Info - Address 2'}
                  />

                  {errors.companyCity
                  || errors.companyZip
                  || errors.companyState ? (
                    <p className="small-error">
                      Please enter a valid zipcode, city, and state
                    </p>
                    ) : (
                      ''
                    )}
                  <div className="value">
                    <FormInput
                      width={'30%'}
                      name={'companyZip'}
                      placeholder="Zip"
                      defaultValue={values.companyZip}
                      autocomplete="new-password"
                      data-inputtype="zip"
                      required
                      hasError={hasError('companyZip')}
                      data-zip
                      onBlur={formBlur}
                      data-track={'Principal Info - Zip'}
                    />
                    <FormInput
                      width={'48%'}
                      margin={'0% 1%'}
                      name={'companyCity'}
                      placeholder="City"
                      defaultValue={values.companyCity}
                      autocomplete="new-password"
                      required
                      data-zip2city
                      onBlur={formBlur}
                      hasError={hasError('companyCity')}
                      data-track={'Principal Info - City'}
                    />
                    <FormSelect
                      name={'companyState'}
                      defaultValue={values.companyState || ''}
                      required
                      autocomplete="new-password"
                      width={'20%'}
                      hasError={hasError('companyState')}
                      data-zip2state
                      onChange={formBlur}
                      data-track={'Principal Info - State'}
                    >
                      <option value="" disabled>
                        State
                      </option>
                      {StateOptions.map((option) => (
                        <option
                          key={`principalState-${option.value}`}
                          value={option.value}
                        >
                          {option.display}
                        </option>
                      ))}
                    </FormSelect>
                  </div>
                </address>
              </div>
            </div>
          </div>

        </div>
          <button disabled={isSubmitting} onClick={submitForm} style={{ marginBottom: '20px' }}>
          Submit My Application
          </button>
      </form>
    </Layout>
  );
};

function Engage(props) {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'INIT':
        return { ...state, ...action.payload, loading: false };
    }
  };

  // Initial State
  const [state, dispatch] = useReducer(reducer, {
    nonce: props.nonce,
    loading: true,
  });

  // our on mount "data-fetcher"
  useEffect(() => {
    async function getAppState() {
      const search = window.location.search
        ? `${window.location.search}&timestamp=${new Date().getTime()}`
        : `?timestamp=${new Date().getTime()}`;
      const { data } = await Axios.get(
        `/engage/${props.nonce}/get-app-state/${search}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );

      if (data.lead.remarket.confirmed) {
        props.navigate(`/engage/${props.nonce}/market/confirm`);
      }

      initEngagedTracking(props.nonce, { ...data, isConfirmPage: false });

      // dispatch init
      dispatch({ type: 'INIT', payload: data });
    }
    getAppState();
  }, []);
  return (
    <>
      {state.loading ? null : (
        <Engaged navigate={props.navigate} state={state} nonce={props.nonce} dispatch={dispatch} />
      )}
    </>
  );
}

export default Engage;
