import React from 'react';
import Axios from 'axios';
import qs from 'qs';

import { validateInput, getCityState } from '../helpers';

import { ContactFields } from './ContactFields';
import { PageButton } from '../ExpressAppUI';
import { Mixpanel } from '../mixpanel';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    const initProps = props;
    this.state = {
      autoSave: initProps.contact.incompleteFields.length !== 0,
      touched:
        initProps.contact.incompleteFields.length < 11
          ? Object.keys(initProps.contact.fields)
          : [],
      localIncomplete: initProps.contact.incompleteFields,
      fields: initProps.contact.fields,
      errors:
        initProps.contact.incompleteFields.length > 0
          && initProps.contact.incompleteFields.length !== 11
          ? { missingFields: true }
          : {},
      contactId: initProps.contact.id,
      isPrimary: initProps.contact.isPrimary,
      submitting: false,
    };
  }

  blankContact = {
    autoSave: false,
    isPrimary: false,
    errors: [],
    touched: [],
    localIncomplete: [
      '[name][first]',
      '[name][last]',
      'dob',
      'ssn',
      'phone',
      'email',
      'address',
      'zipcode',
      'city',
      'state',
      'home_type',
    ],
    fields: {
      '[name][first]': '',
      '[name][last]': '',
      dob: '',
      ssn: '',
      phone: '',
      email: '',
      address: '',
      address2: '',
      zipcode: '',
      city: '',
      state: '',
      home_type: '',
      experience: '',
    },
  };

  localBlur = async (e) => {
    const {
      name,
      value,
      required,
      dataset: { inputtype = '', track },
    } = e.target;

    Mixpanel.track(track);

    if (inputtype !== 'radio') {
      e.preventDefault();
    }

    const {
      touched, localIncomplete, fields, errors,
    } = this.state;

    const newIncomplete = [...localIncomplete];
    const newTouched = [...touched];
    const newFields = { ...fields };

    if (!newTouched.includes(name)) {
      newTouched.push(name);
    }

    // ValidateField -- returns  {newValue: newVal || '', error: errorMsg || '' }
    const { newValue, error } = validateInput(name, value, inputtype);

    // If we have an
    if (error) {
      errors[name] = error;
    } else {
      errors[name] = false;
    }

    // If we have an
    if (newValue === '') {
      errors.missingFields = true;
      e.target.value = '';
    }

    newFields[name] = newValue;

    // track field in
    const isMissing = newIncomplete.includes(name);

    if (isMissing && required && !!newValue) {
      newIncomplete.splice(localIncomplete.indexOf(name), 1);
    } else if (!isMissing && required && !newValue) {
      newIncomplete.push(name);
    }

    if (newIncomplete.length === 0) {
      errors.missingFields = false;
    }

    // TODO: REFACTOR ZIP BLUR TO WORK
    if (inputtype === 'zip' && !!newValue) {
      const cityState = await getCityState(newValue);
      if (cityState.state) {
        newFields.state = cityState.state;
        newFields.city = cityState.city;
        if (newIncomplete.includes('city')) {
          newIncomplete.splice(newIncomplete.indexOf('city'), 1);
        }
        if (newIncomplete.includes('state')) {
          newIncomplete.splice(newIncomplete.indexOf('state'), 1);
        }
        if (!touched.includes('city')) {
          newTouched.push('city');
        }
        if (!touched.includes('state')) {
          newTouched.push('state');
        }
      }
    }

    this.setState(
      {
        fields: newFields,
        localIncomplete: newIncomplete,
        touched: newTouched,
        errors,
      },
      function () {
        if (newValue) {
          if (this.state.autoSave) {
            // Post to DB

            const data = { [name]: newValue };

            const formData = {
              ...data,
              primary: this.state.isPrimary,
              lastStep: false,
            };

            Axios.post(
              `${window.location.pathname}/principal/${this.state.contactId}${window.location.search}`,
              qs.stringify(formData),
            );

            this.props.saveContact(
              name,
              newValue,
              newIncomplete,
              this.state.contactId,
            );

            // TODO: ADD SAVE FIELDS
          }
          // Post to DB
        }
      },
    );
    // If True call Parent
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Mixpanel.track('Principal Information Submitted');

    const { localIncomplete } = this.state;

    if (localIncomplete.length === 0) {
      this.setState({
        submitting: true,
        errors: { ...this.state.errors, missingFields: false },
      });

      const data = {
        ...this.state.fields,
        primary: this.state.isPrimary,
        id: this.state.contactId,
        updateIncomplete: this.state.localIncomplete,
      };

      // this.props.saveForm(data)

      Axios.post(
        `${window.location.pathname}/principal/${this.state.contactId}${window.location.search}`,
        qs.stringify({ ...data, primary: this.state.isPrimary, lastStep: true }),
      ).then(() => {
        this.setState({ submitting: false });
        this.props.submitContact(data);
      });
    } else if (localIncomplete.length > 0) {
      this.setState(
        {
          errors: { ...this.state.errors, missingFields: true },
          touched: Object.keys(this.state.fields),
        },
        () => {
          window.scrollTo({
            top: document.getElementById('owners').offsetTop,
            behavior: 'smooth',
          });
        },
      );
      return false;
    }

    // TODO: SAVE FORM MARKS GLOBAL MISSING FIELDS AND SAVES FIELDS AS FINAL STEP TO API
  };

  hasError = (field) => {
    const { touched, localIncomplete } = this.state;

    if (localIncomplete.includes(field) && touched.includes(field)) {
      return true;
    }
  };

  localChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    const { fields } = this.state;

    fields[name] = value;

    this.setState({ fields });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <ContactFields
          handleBlur={this.localBlur}
          hasError={this.hasError}
          fields={this.state.fields}
          handleSubmit={this.handleSubmit}
          handleChange={this.localChange}
          incompleteFields={this.state.localIncomplete}
          errors={this.state.errors}
          submitting={this.state.submitting}
        />

        <div className="right-button">
          <PageButton
            onClick={this.handleSubmit}
            // onSubmit={this.newSubmit}
            type="submit"
          >
            {this.state.submitting ? (
              <>
                {'Saving     '}
                <i class="fa fa-spinner fa-spin" />
              </>
            ) : (
              'Save and Continue'
            )}
          </PageButton>
        </div>
      </form>
    );
  }
}
