// eslint-disable-next-line no-unused-vars
import React from 'react';

import '../App.css';

function NotFound() {
  // Use our global title context for event context
  window.location = '/title-bond/404';

  return (
    <>

    </>
  );
}

export default NotFound;
