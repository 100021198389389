import React from 'react';

import TrustSeals from './TrustSeals';

export default function SideBar({ children }) {
  return (
    <div className="side-bar">
      <div className="info-box">
        <div className="info-box__icon">
          <i className="fa fa-user" />
        </div>
        <div className="info-bar__text">
          <div className="text-header">Have Questions ?</div>
          <a href="tel:18003084358" className="phone">
            1 (800) 308-4358
          </a>
          <a class="email" href="mailto:customercare@suretybonds.com">
            customercare@suretybonds.com
          </a>
        </div>
      </div>
      {children}
      <TrustSeals />
    </div>
  );
}
