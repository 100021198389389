import React from 'react';

import { StateOptions } from '../helpers';
import {
  FormInput,
  FormSelect,
  FormItem,
  FormLabel,
  PageButton,
} from '../ExpressAppUI';
import { Mixpanel } from '../mixpanel';
import MaskedInput from 'react-text-mask';
import moment from 'moment';

export const CompanyFields = ({
  handleBlur,
  fields,
  errors,
  hasError,
  handleSubmit,
  handleChange,
  principalOptions,
  principalType,
  submitting,
  setStartDateToday,
}) => (
  <form action="" onSubmit={handleSubmit}>
    <div onBlur={handleBlur}>
      {!!errors.missingFields && (
        <p className="error-text">Please Fill in Missing Fields</p>
      )}
      <h3 className="helper-text">
        If you have not yet finalized your company information, you can update this section at any time.
      </h3>
      <FormItem>
        <FormLabel>
          Business Type<sup>*</sup>
        </FormLabel>
        <FormSelect
          onChange={handleBlur}
          required
          name="companyType"
          hasError={hasError('companyType')}
          defaultValue={fields.companyType}
          data-track="Business Type"
        >
          <option disabled value="">
            Select One
          </option>
          {principalOptions.map((option) => (
            <option value={option.value}>{option.display}</option>
          ))}
        </FormSelect>
      </FormItem>
      <FormItem>
        <FormLabel>
          {['sole_proprietorship', 'partnership', 'individual'].includes(
            fields.companyType,
          )
            ? `${fields.companyType === 'partnership'
              ? "Partner's Name"
              : "Owner's Name"
            }`
            : 'Company Name'}
          <sup>*</sup>
        </FormLabel>
        <FormInput
          defaultValue={fields.companyName}
          name="companyName"
          placeholder="Business Name"
          required
          hasError={hasError('companyName')}
          data-track="Company Name"
        />
      </FormItem>
      {principalType.toLowerCase() !== 'individual' && (
        <DbaField value={fields.companyDBA} handleChange={handleChange} />
      )}
      <FormItem>
        <FormLabel>
          Address
          <sup>*</sup>
          <i className="sub-label">
            This is the address that will appear on your bond.
          </i>
        </FormLabel>

        <FormInput
          defaultValue={fields.companyAddress}
          required
          name={'companyAddress'}
          data-inputtype="address"
          placeholder="Street Address"
          hasError={hasError('companyAddress')}
          data-track="Company Address"
        />

        <FormInput
          defaultValue={fields.companyAddress2}
          data-inputtype="address"
          name={'companyAddress2'}
          placeholder="Suite, Unit, Building, Floor, Etc."
          data-input-type={'address'}
          data-track="Company Address 2"
        />

        <FormInput
          width={'30%'}
          name={'companyZip'}
          defaultValue={fields.companyZip}
          placeholder="Zip"
          data-inputtype="zip"
          required
          hasError={hasError('companyZip')}
          data-track="Company Zip"
        />

        <FormInput
          width={'48%'}
          margin={'0% 1%'}
          name={'companyCity'}
          placeholder="City"
          value={fields.companyCity}
          required
          hasError={hasError('companyCity')}
          onChange={handleChange}
          data-track="Company City"
        />

        <FormSelect
          name={'companyState'}
          onChange={handleChange}
          value={fields.companyState}
          required
          width={'20%'}
          hasError={hasError('companyState')}
          data-track="Company State"
        >
          <option value="" disabled>
            State
          </option>
          {StateOptions.map((option) => (
            <option key={`companyState-${option.value}`} value={option.value}>
              {option.display}
            </option>
          ))}
        </FormSelect>
      </FormItem>
      {/* Only show when the value of companyType or the bond's principalType is NOT individual*/}
      {(!fields.companyType && principalType !== 'individual') || fields.companyType !== 'individual' && (
        <FormItem style={{ position: "relative" }}>
          <FormLabel>
            Company Start Date
            <sup>*</sup>
            <i className="sub-label">If new business/licensee, enter today’s date.</i>
          </FormLabel>
          <MaskedInput
            className={`form-input ${hasError('companyStarted') ? 'has-error' : ''
              }`}
            name={`companyStarted`}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholder="MM/DD/YYY"
            value={moment(fields.companyStarted).format('MM/DD/YYYY')}
            required
            data-inputtype='date'
            data-track="Company Start Date"
            onBlur={handleBlur}
          />
          <button
            type="button"
            style={{ position: 'absolute', top: '0', right: '12px' }}
            className="button-effective-today"
            onClick={setStartDateToday}
          >
            Effective Today
          </button>
        </FormItem>
      )}

      <div className="right-button">
        <PageButton onClick={handleSubmit}>
          {submitting ? (
            <>
              {'Saving     '}
              <i class="fa fa-spinner fa-spin" />
            </>
          ) : (
            'Save and Continue'
          )}
        </PageButton>
      </div>
    </div>
  </form>
);

class DbaField extends React.Component {
  state = {
    showDBA: !!this.props.value,
  };

  toggleDBA = (event) => {
    event.stopPropagation();
    const { value, dataset: { track } } = event.target;
    this.setState({ showDBA: value === 'show' });
    Mixpanel.track(track);
  };

  render() {
    return (
      <>
        <FormItem>
          <FormLabel>Do you have a DBA or trade name?</FormLabel>
          <div className="radio-wrap" onChange={this.toggleDBA}>
            <input
              className="toggle-radio"
              type="radio"
              id="dbaToggleYes"
              name="has-dba"
              value="show"
              checked={!!this.state.showDBA}
              data-track="DBA YES"
            />
            <label htmlFor="dbaToggleYes" className="toggle-label">
              Yes
            </label>
            <input
              className="toggle-radio"
              type="radio"
              id="dbaToggleNo"
              name="has-dba"
              value="hide"
              checked={!this.state.showDBA}
              data-track="DBA NO"
            />
            <label htmlFor="dbaToggleNo" className="toggle-label">
              No
            </label>
          </div>
        </FormItem>
        {this.state.showDBA && (
          <FormItem>
            <FormLabel>DBA</FormLabel>
            <FormInput
              name="companyDBA"
              defaultValue={this.props.value}
              placeholder="Doing Business/Trading As"
              data-track="Company DBA"
            />
          </FormItem>
        )}
      </>
    );
  }
}
