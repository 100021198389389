// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';

class AccordianWrapper extends Component {
  render() {
    return <>{this.props.accordian}</>;
  }
}

export default AccordianWrapper;
