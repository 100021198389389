import React from 'react';
import { useParams } from '@reach/router';
import { useMutation, useQueryClient } from 'react-query';
import { useField } from 'formik';
import Axios from 'axios';
import {
  isInstantPurchase,
  getShippingPrice
} from '../../utils/business-logic';
import { LineBreak, } from '../../ExpressAppUI';
import { useLead } from '../../client';
import { trackShippingChange } from '../tracking';
import { RadioCard, ErrorMessage } from '../../common/forms/fields';
import { useIsMutating } from "react-query";
import { Loading } from './Loading'


export function ShippingMethod() {
  const {lead} = useLead()
  return (
    <>
      <h5 className='panel-title' style={{ position: 'relative' }}>
        Select Shipping Method
        <img
          className='shipping-logo'
          src='/img/logos/fedex-transparent.png'
          alt='FEDEX'
        ></img>
      </h5>
      <FreeShippingText lead={lead} />
      {!['comped', 'shared'].includes(lead.freeShippingFlag) && (
        <ShippingMethodCards/>
      )}
    </>
  );
}

function FreeShippingText({lead}){
  if(lead.freeShippingFlag === 'comped'){
    return (
      <>
        <span className="green">
        You're a SuretyBonds.com Preferred Customer, so no need to worry about
        this part. We're covering your shipping costs to thank you for your
        business!
        </span>
        <LineBreak></LineBreak>
    </>
    )
  } else if(lead.freeShippingFlag === 'shared'){
    return (
      <>
        <span  className="green">
          Your account indicates you're purchasing multiple bonds to be shipped
          together. You should only pay one shipping charge. Your bonds will
          only be issued and shipped once all premiums have been paid.
        </span>
        <LineBreak></LineBreak>
      </>
    )
  }
  else return null
}
function ShippingMethodCards() {
  const { updateShippingMethod, isLoading } = useShippingUpdate();
  function changeHandler(e) { updateShippingMethod(e.target.value);}

  return (
    <Loading isLoading={isLoading}>
      <ErrorMessage name="selectedShippingMethod"></ErrorMessage>
      <ShippingMethodCard
        label='Overnight (1 Business Day)'
        amount='$20'
        name='selectedShippingMethod'
        value='Overnight'
        changeHandler={changeHandler}
      />
      <ShippingMethodCard
        label='2 Day Air (2 Business Days)'
        amount='$15'
        name='selectedShippingMethod'
        value='2 Day Air'
        changeHandler={changeHandler}
      />
      <ShippingMethodCard
        label='Express (3 Business Days)'
        amount='$10'
        name='selectedShippingMethod'
        value='Express'
        changeHandler={changeHandler}
      />
      <LineBreak />
    </Loading>
  );
}

const ShippingMethodCard = ({
  amount, label, name, value, changeHandler
}) => {
  const isMutating = useIsMutating({ mutationKey: "lead" })

  return (
    <RadioCard changeHandler={changeHandler} name={name} value={value} disabled={isMutating}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>{label}</div>
        <div style={{ color: 'rgb(13, 139, 209', fontWeight: 'bold' }}>
          {amount}
        </div>
      </div>
    </RadioCard>
  );
};

function useShippingUpdate() {
  const { nonce } = useParams();
  const { lead } = useLead();
  const queryClient = useQueryClient();
  const [, , helpers] = useField('selectedShippingMethod');

  const shippingUpdateUrl = isInstantPurchase(lead)
    ? `/application/${nonce}/update-shipping`
    : `/purchase-bond/${nonce}/update-shipping`;
  const mutation = useMutation(
    (shippingMethod) => Axios.post(shippingUpdateUrl, { shipping: shippingMethod }),
    {
      mutationKey: 'lead',
      onSuccess: (response, shippingMethod) => {
        // second argument of false prevents formik validation
        helpers.setTouched(true, false);
        helpers.setValue(shippingMethod);
        helpers.setError(null);
        trackShippingChange(shippingMethod);

        queryClient.setQueryData('lead', (oldLead) => {
          // update selectedShippingMethod
          oldLead.data.lead.selectedShipping = shippingMethod;
          const oldShippingChargeIndex = oldLead.data.invoice.charges.findIndex(
            (c) => c.category.toLowerCase() === 'shipping',
          );
          if (oldShippingChargeIndex >= 0) {
            oldLead.data.invoice.charges[
              oldShippingChargeIndex
            ].amount = getShippingPrice(shippingMethod);
          } else {
            oldLead.data.invoice.charges.push({
              category: 'shipping',
              amount: getShippingPrice(shippingMethod),
            });
          }
          oldLead.data.invoice._id = response.data._id
          return oldLead;
        });

        queryClient.invalidateQueries('lead');
      },
      onError: (response, shippingMethod) => {
        queryClient.invalidateQueries('lead');
      },
    },
  );

  return { ...mutation, updateShippingMethod: mutation.mutate };
}
