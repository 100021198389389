import { useDocusignSigTask, useProcessingTask } from './hooks';
import React from 'react';
import { Loading } from '../purchase-bond/components/Loading';
import { useLead } from '../client';
import { toMoney } from '../helpers';
import { useParams } from '@reach/router';
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function Processing() {
  const { task, isLoading, timesTried } = useProcessingTask();
  const [delayedLoad, setDelayedLoad] = React.useState(false);
  const { nonce } = useParams();
  React.useEffect(() => {
    async function delayLoad() {
      await delay(500);

      setDelayedLoad(true);
    }

    delayLoad();
  }, []);


  if (!delayedLoad || isLoading || task?.status !== 'complete')
    return <LoadingTask timesTried={timesTried} />;
  else if (task?.quote) {
    // if the lead is a renewal with the same surety company
    // we don't need docusign, navigate to purchase
    if (!task?.needsDocusign) window.location = `/purchase-bond/${nonce}`;
    else return <BondDocs quote={task.quote} />;
  }
  else return <UnSelectedQuote />;
}

export function PreSignature() {
  const { task, isLoading, timesTried } = useProcessingTask();
  const [delayedLoad, setDelayedLoad] = React.useState(false);
  React.useEffect(() => {
    async function delayLoad() {
      await delay(500);

      setDelayedLoad(true);
    }

    delayLoad();
  }, []);

  if (!delayedLoad || isLoading || task?.status !== 'complete')
    return <LoadingTask timesTried={timesTried} />;
  else if (task?.quote) return <BondDocs />;
  else return <UnSelectedQuote />;
}

export function PostSignature() {
  const { nonce } = useParams();
  const {allDocsCompleted } = useDocusignSigTask();
  React.useEffect(() => {
    if (allDocsCompleted) {
      window.location = `/purchase-bond/${nonce}`;
    }
  }, [allDocsCompleted, nonce]);

  return (
    <div
      style={{
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h5>
        <i
          className="fa fa-spin fa-spinner"
          style={{ marginRight: '10px' }}
        ></i>
        We're wrapping up a few small things.
      </h5>

      <div>
        <div></div>

        <div
          style={{
            columnGap: '.5rem',
            boxShadow: 'rgb(0 0 0 / 8%) 0.1rem 0.2rem 0.2rem',
            padding: '15px',
            margin: '1rem 0',
            borderRadius: '4px',
            // bg-green-100 or bg-yellow-50
            backgroundColor: '#fefce8',
            // text-green-700 or text-yellow-800
            color: '#854d0e',
            fontSize: '18px',
          }}
        >
          Please keep this window open for a couple of minutes.
          <br />
          <br />
          We appreciate your patience. You’ll be able to purchase your bond in
          just a moment.
        </div>
      </div>
    </div>
  );
}

function LoadingTask({ timesTried }) {
  return (
    <div
      style={{
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h5>Thanks! We’re processing your application now. Please keep this window open.</h5>
      <hr />
      {timesTried < 12 ? (
        <Loading isLoading={true}>
          <span></span>
        </Loading>
      ) : (
        <div>
          There was a problem, please reload the page and try again.
          <br/>
          If you continue to encounter errors please contact our customer care team at <a href="tel:+18003084358">1-800-308-4358</a> or <a href="mailto:customercare@suretybonds.com">customercare@suretybonds.com</a>
        </div>
      )}
    </div>
  );
}

function UnSelectedQuote() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h5>
        We were unable to instantly approve a quote at this time. We’ll review
        your application and contact you once we’re able to proceed.
      </h5>
      <div
        style={{
          columnGap: '.5rem',
          boxShadow: 'rgb(0 0 0 / 8%) 0.1rem 0.2rem 0.2rem',
          padding: '15px',
          margin: '1rem 0',
          borderRadius: '4px',
          // bg-green-100 or bg-yellow-50
          backgroundColor: '#fefce8',
          // text-green-700 or text-yellow-800
          color: '#854d0e',
        }}
      >
        If you have questions at this time, call 1(800)308-4358 to speak with a
        friendly surety expert, or email customercare@suretybonds.com.
      </div>
    </div>
  );
}
function BondDocs({ quote }) {
  const [loading, setLoading] = React.useState(false);
  const { invoice} = useLead();
  return (
    <Loading isLoading={!invoice || loading}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <h5>
          <i
            style={{ color: 'rgb(116, 219, 102)', marginRight: '10px' }}
            className="fa fa-check"
          />
          Your application has been approved!
        </h5>

        <div style={{ fontWeight: 600, fontSize: 18 }}>
          Annual Premium: {toMoney(quote.quote)}{' '}
          <div style={{ fontSize: 14, fontWeight: 400 }}>
            <em>
              <sup>*</sup>Multi-year discounts are also available if you’d like
              to extend your bond term.
            </em>
          </div>
        </div>
        <div
          style={{
            columnGap: '.5rem',
            boxShadow: 'rgb(0 0 0 / 8%) 0.1rem 0.2rem 0.2rem',
            padding: '15px',
            margin: '1rem 0',
            borderRadius: '4px',
            // bg-green-100 or bg-yellow-50
            backgroundColor: '#fefce8',
            // text-green-700 or text-yellow-800
            color: '#854d0e',
          }}
        >
          Before you can purchase your bond, you’ll need to verify your
          identity.
          <br />
          <br />
          Please click the button below to start this process.
        </div>
        <br />

        <button
          onClick={async () => {
            setLoading(true);
            const res = await window.fetch(`get-docusign-packet`);
            const data = await res.json();
            const url = data.url;
            window.location = url;
          }}
        >
          Continue
        </button>
      </div>
    </Loading>
  );
}

