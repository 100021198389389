import React from 'react';
import '../App.css';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import { AltProgressBar } from '../Components/ProgressBar';
import SideBar from '../Components/SideBar';
import TrustSeals from '../Components/TrustSeals';
import { Overlay, Wrapper } from '../ExpressAppUI';
import { useMedia } from '../hooks';

const ErrorMessage = () => (
  <>
    <p className="error-text">
      An error has occurred when trying to save your information. Please reload
      the page and try again. If you keep getting this error message, please
      give us a call at <a href="tel:_18003084358"> 1(800)308-4358</a>
    </p>
  </>
);

export const TitleBondLayout = React.memo(({
  children,
  splitView,
  saving,
  hasError,
  title,
  activeStep,
}) => {
  const isMobile = useMedia('(max-width: 800px)');
  return (
    <>
      <Header />
      <div class="mobile-subheader">
        <div class="mobile-title-wrapper">
          <div class="container mobile-title">
            <i class="fa fa-lock fa-sm fa-inverse" />
            Bond Application
          </div>
        </div>
      </div>
      <div
        className="container main-container"
        style={{ marginBottom: '40px' }}
      >
        <h1
          class="checkout-title"
          style={{ marginTop: '45px', marginBottom: '-10px' }}
        >
          Bond Application
        </h1>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <AltProgressBar steps={3} activeStep={activeStep} />
        </div>

        {!isMobile ? (
          <Overlay
            isMobile={false}
            active={saving}
            fadeSpeed={800}
            spinner={!hasError}
            text={hasError ? <ErrorMessage /> : null}
          >
            <div className="split-layout title">
              <Wrapper className="title-wrap">
                {hasError && <ErrorMessage></ErrorMessage>}
                {children}
              </Wrapper>

              <SideBar />
            </div>
          </Overlay>
        ) : (
          <div className="mobile-layout">
            <Overlay
              isMobile={true}
              active={saving}
              fadeSpeed={800}
              spinner={!hasError}
              text={hasError ? <ErrorMessage /> : null}
            >
              <div
                style={{
                  backgroundColor: '#f9f9fa',
                  padding: '30px',
                  textAlign: 'center',
                }}
              >
                {hasError && <ErrorMessage></ErrorMessage>}

                {children}
              </div>
            </Overlay>

            <TrustSeals />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
});
