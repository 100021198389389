import React from 'react';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import {
  toFullStateName,
  toMoney,
  StateOptions,
} from '../helpers';

import {
  FormInput,
  FormSelect,
  FormItem,
  FormLabel,
  PageButton,
} from '../ExpressAppUI';

export const BondFields = ({
  handleBlur,
  fields,
  customFields,
  hasError,
  handleSubmit,
  handleChange,
  incompleteFields,
  bondInfo,
  submitting,
  errors,
  effectiveToday,
}) => (
  <div onBlur={handleBlur}>
    {!!errors.missingFields && (
      <p className="error-text">Please fill out the Inputs Marked in Red</p>
    )}

    <h3 className="helper-text">
      Fields with a red asterisk are required for quoting. Unrequired fields left blank can be completed later on in the process.
    </h3>

    <FormItem>
      <FormLabel>
        Bond Type <sup>*</sup>
      </FormLabel>
      <div className="form-text">
        {!bondInfo.isFederal && toFullStateName(fields.state)} {bondInfo.name}
      </div>
    </FormItem>

    {/* Bond Amount */}
    {!bondInfo.isGeneric && (
      <BondAmountsField
        hasError={hasError}
        bondInfo={bondInfo}
        value={fields.amount}
        submitting={submitting}
      />
    )}
    {/* Bond Amount */}

    {bondInfo.isFederal ? (
      <FormItem>
        <FormLabel>Bond State</FormLabel>
        <p className="helper-text">
          In What state is your primary business location?
        </p>
        <FormSelect
          value={fields.state}
          name="state"
          required
          hasError={hasError('state')}
          data-track="Bond State"
          onChange={handleBlur}
        >
          <option disabled value="">Select a State</option>
          {StateOptions.map((option) => (
            <option key={`bondState-${option.value}`} value={option.value}>
              {option.display}
            </option>
          ))}
        </FormSelect>
      </FormItem>
    ) : (
      <input hidden value={fields.state} name="state" />
    )}

    {!!bondInfo.isV2 && !bondInfo.isGeneric && (
      <FormItem style={{ position: 'relative' }}>
        <FormLabel>Desired Effective Date <sup>*</sup></FormLabel>
        {errors.effectiveDate && (
          <p className="small-error">{errors.effectiveDate}</p>
        )}
        <MaskedInput
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          placeholder="MM/DD/YYYY"
          name="effectiveDate"
          value={moment(fields.effectiveDate).format('MM/DD/YYYY')}
          required
          className={`${
            hasError('effectiveDate') ? 'has-error' : ''
          } form-input`}
          data-inputtype="date"
          onBlur={handleBlur}
          data-track="Bond Effective Date"
        />
        {!bondInfo.fixedDay && (
          <button
            type="button"
            style={{ position: 'absolute', top: '0', right: '12px' }}
            className="button-effective-today"
            onClick={effectiveToday}
          >
            Effective Today
          </button>
        )}
      </FormItem>
    )}

    {bondInfo.isV2 && Object.keys(customFields).length > 0 && (
      <>
        <CustomFields
          customFields={customFields}
          fields={fields}
          hasError={hasError}
          handleChange={handleChange}
          errors={errors}
        />
      </>
    )}

    <div className="right-button">
      <PageButton onClick={handleSubmit}>
        {submitting ? (
          <>
            {'Saving     '}
            <i class="fa fa-spinner fa-spin" />
          </>
        ) : (
          'Save and Continue'
        )}
      </PageButton>
    </div>
  </div>
);

class CustomFields extends React.Component {
  renderSwitch = (field) => {
    const name = `customFields[${field.field}]`;

    if (field.type.toLowerCase() === 'date') {
      return (
        <>
          {this.props.errors[name] && (
            <p className="small-error">{this.props.errors[name]}</p>
          )}
          <MaskedInput
            className={`form-input ${
              this.props.hasError(`customFields[${field.field}]`)
                ? 'has-error'
                : ''
            }`}
            name={`customFields[${field.field}]`}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholder="MM/DD/YYY"
            defaultValue={moment(this.props.fields[name]).format('MM/DD/YYYY')}
            required={field.required}
            data-inputtype={'date'}
            onBlur={this.props.handleBlur}
            data-track={`Bond Requirements - ${field.display}`}
          />
        </>
      );
    }
    if (field.type.toLowerCase() === 'boolean') {
      return (
        <div className="radio-wrap">
          <input
            data-inputtype="checkbox"
            type="checkbox"
            name={`customFields[${field.field}]`}
            defaultChecked={
              this.props.fields[name] === true
              || this.props.fields[name] === 'true'
            }
            onChange={this.props.handleBlur}
            data-track={`Bond Requirements - ${field.display}`}
          />
          <small
            className="checkbox-description"
            style={{ marginLeft: '10px' }}
          >
            {field.description}
          </small>
        </div>
      );
    }
    if (field.options && field.options.length > 0) {
      return (
        <FormSelect
          className="form-input"
          name={`customFields[${field.field}]`}
          value={this.props.fields[name]}
          hasError={this.props.hasError(`customFields[${field.field}]`)}
          required={field.required}
          onChange={this.props.handleChange}
          data-track={`Bond Requirements - ${field.display}`}
        >
          <option value="" disabled>
            Select an Option
          </option>
          {field.options.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </FormSelect>
      );
    }
    if (field.display === 'Bond Amount') {
      return (
        <MaskedInput
          className="form-input"
          name={`customFields[${field.field}]`}
          value={this.props.fields[name]}
          required={field.required}
          data-track={`Bond Requirements - ${field.display}`}
          hasError={this.props.hasError(`customFields[${field.field}]`)}
          onChange={this.props.handleChange}
          type={field.type === 'Number' ? 'number' : 'text'}
          data-inputtype="money"
          mask={createNumberMask({
            prefix: '$',
          })}
        />
      );
    }
    return (
        <FormInput
          className="form-input"
          name={`customFields[${field.field}]`}
          value={this.props.fields[name]}
          required={field.required}
          data-track={`Bond Requirements - ${field.display}`}
          hasError={this.props.hasError(`customFields[${field.field}]`)}
          onChange={this.props.handleChange}
          type={field.type === 'Number' ? 'number' : 'text'}
          data-type={field.type}
        />
    );
  };

  render() {
    const { customFields } = this.props;
    return (
      <>
        {customFields.map((field) => (
            <FormItem>
              <FormLabel>
                {field.display}
                {field.required && <sup>*</sup>}
                <i className="sub-label">{field.description}</i>
              </FormLabel>
              <div>{this.renderSwitch(field)}</div>
            </FormItem>
        ))}
      </>
    );
  }
}

class BondAmountsField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNotes: false,
      noAmount:
        (this.props.bondInfo.isV2
          && this.props.bondInfo.amounts.length === 0
          && !this.props.value)
        || this.props.value === 0,
    };
  }

  state = { showNotes: false };

  render() {
    const {
      value,
      hasError,
      bondInfo: { amounts, webNotes, isRanged },
    } = this.props;

    const moneyMask = createNumberMask({
      prefix: '$',
    });

    const toggleNotes = (event) => {
      event.preventDefault();
      this.setState({ showNotes: !this.state.showNotes });
    };

    return (
      <FormItem>
        <FormLabel>
          Bond Amount
        </FormLabel>
        {this.state.noAmount ? (
          <div className="helper-text">Our service team will contact you to determine your bond amount.</div>
        ) : (
          <>
            {amounts.length > 1 && !isRanged ? (
              <>
                {webNotes && webNotes.length > 0 && (
                  <>
                    <div className="label">
                      <i>
                        The amount of coverage needed for your bond varies.If
                        you are unsure of your bond amount, please refer to{' '}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#" onClick={toggleNotes}>
                          this information
                        </a>
                      </i>
                    </div>
                    {this.state.showNotes && (
                      <ul>
                        {webNotes.map((note, idx) => {
                          const props = {
                            dangerouslySetInnerHTML: { __html: note },
                          };
                          return <li {...props} key={idx} />;
                        })}
                      </ul>
                    )}
                  </>
                )}
                <FormSelect
                  name="amount"
                  defaultValue={value}
                  hasError={hasError('amount')}
                >
                  {amounts.map((amount, idx) => {
                    const amountText = amount.type === 'fixed'
                      ? toMoney(amount.low)
                      : `${toMoney(amount.low)} - ${toMoney(amount.high)}`;

                    return (
                      <option key={`amount-${idx}`} value={amount.low}>
                        {amountText}
                      </option>
                    );
                  })}
                </FormSelect>
              </>
            ) : (
              <div className="form-text">{toMoney(value)}</div>
            )}
          </>
        )}
      </FormItem>
    );
  }
}
