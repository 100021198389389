import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';

import moment from 'moment';
import { validateInput } from '../helpers';
import { BondFields } from './BondFields';
import { Mixpanel } from '../mixpanel';

export default class BondForm extends Component {
  componentDidMount() {
    Mixpanel.register('Bond State', this.state.fields.state);
    Mixpanel.register('Bond Amount', this.state.fields.amount);
  }

  componentDidUpdate(pProps, pState) {
    if (pState.fields.state !== this.state.fields.state) {
      Mixpanel.register('Bond State', this.state.fields.state);
    }
    if (pState.fields.amount !== this.state.fields.amount) {
      Mixpanel.register('Bond Amount', this.state.fields.amount);
    }
  }

  constructor(props) {
    super(props);
    const initProps = { ...props };
    const { incompleteFields, fields, customFields } = initProps;
    const { submitted } = props;
    const cFArray = Object.keys(customFields).map((f) => ({
      ...customFields[f],
    }));

    this.state = {
      errors: { missingFields: false },
      submitting: false,
      autoSave: false,
      customFields: cFArray,
      localIncomplete: incompleteFields,
      touched:
        submitted
        && incompleteFields.length
          < 5 + cFArray.filter((f) => !!f.required && f.type !== 'Boolean').length
          ? Object.keys(fields)
          : [],
    };

    if (props.isV2 && cFArray.length) {
      cFArray.forEach((f) => (fields[f.field] = f.value));
    }

    if(!props.bondInfo.isGeneric && !submitted){
      fields.effectiveDate = "";
    }
    this.state.fields = fields;
  }

  localBlur = (e) => {
    e.preventDefault();
    // mixpanel Blur
    const {
      name,
      value,
      required,
      dataset: { inputtype = '', track },
    } = e.target;

    Mixpanel.track(track);

    const {
      touched, localIncomplete, fields, errors,
    } = this.state;

    if (!touched.includes(name)) {
      touched.push(name);
    }

    // ValidateField -- returns  {newValue: newVal || '', error: errorMsg || '' }
    let { newValue, error } = validateInput(name, value, inputtype);

    if (inputtype === 'checkbox') {
      newValue = e.target.checked;
    }

    // If we have an
    if (error !== '') {
      errors[name] = error;
    } else {
      errors[name] = false;
      if (name === 'effectiveDate') {
        if (this.props.bondInfo.fixedDay) {
          const fDay = moment(this.props.bondInfo.fixedDay)
            .add(1, 'day')
            .format('Do');
          const newDay = moment(newValue).format('Do');
          if (newDay !== fDay) {
            errors[
              name
            ] = `The obligee requires your bond to become effective on the ${fDay}
            day of the month.`;
            newValue = moment(fields[name]).format('MM/DD/YYYY');
          }
        }
      }
    }

    if (newValue) {
      errors.missingFields = false;
    }

    // track field in
    const isMissing = localIncomplete.includes(name);

    if (isMissing && required && !!newValue) {
      localIncomplete.splice(localIncomplete.indexOf(name), 1);
    } else if (!isMissing && required && !newValue) {
      localIncomplete.push(name);
    }

    fields[name] = newValue;

    // TODO: FIX CITY STATE LOOKUP
    // if (name == "zip" && !!newValue) {
    //   const cityState = getCityState(newValue);
    //   if (!!cityState) {
    //     fields.state = cityState.state;
    //     fields.city = cityState.city[("state", "city")].forEach(f => {
    //       if (localIncomplete.includes(f)) {
    //         localIncomplete.splice(localIncomplete.indexOf(f), 1);
    //       }
    //       if (!touched.includes(f)) {
    //         touched.push(f);
    //       }
    //     });
    //   }
    // }

    const updateObj = {
      fields,
      localIncomplete,
      touched,
      errors,
    };

    this.setState(
      { fields, touched, localIncomplete },
      function () {
        if (newValue) {
          if (this.state.autoSave) {
            // Post to DB
            console.table(updateObj);

            // TODO: ADD SAVE FIELDS

            Axios.post(
              `${window.location.pathname}/bond`,
              qs.stringify({ [name]: newValue, lastStep: false }),
            );
          }
          // Post to DB
        }
      },
    );
    // If True call Parent
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...this.state.fields, lastStep: true };
    const { localIncomplete } = this.state;

    if (localIncomplete.length === 0) {
      this.setState(
        { errors: { ...this.state.errors, missingFields: false } },
        () => {
          Mixpanel.track('Bond Information Submitted');
          this.setState({ submitting: true });
          Axios.post(
            `${window.location.pathname}/bond${window.location.search}`,
            qs.stringify(data),
          ).then(() => {
            this.setState({ submitting: false });

            this.props.updateFields(data, 'bond');
          });
        },
      );
    } else if (localIncomplete.length > 0) {
      this.setState({
        errors: { ...this.state.errors, missingFields: true },
        touched: Object.keys(this.state.fields),
      });
      return false;
    }

    // TODO: SAVE FORM MARKS GLOBAL MISSING FIELDS AND SAVES FIELDS AS FINAL STEP TO API
  };

  hasError = (field) => {
    const { touched, localIncomplete } = this.state;
    if (localIncomplete.includes(field) && touched.includes(field)) {
      return true;
    }
  };

  localChange = (e) => {
    if (!['radio', 'checkbox'].includes(e.target.dataset.inputtype)) {
      e.preventDefault();
    }
    const { name, value } = e.target;
    const { fields } = this.state;

    fields[name] = value;

    this.setState({ fields });
  };

  effectiveToday = (e) => {
    e.preventDefault();

    const { fields, errors, localIncomplete } = this.state;

    errors.effectiveDate = false;

    fields.effectiveDate = moment(new Date()).format('MM/DD/YYYY');

    if (localIncomplete.includes('effectiveDate')) {
      localIncomplete.splice(localIncomplete.indexOf('effectiveDate'), 1);
    }

    this.setState({ fields, errors, localIncomplete });
  };

  validate = (field) => {
    // Validate field based on Type
    // Address
    // Email
    // Phone
    // SSN
    // Date
    // If NewVal is diff than current value, update w/ e.target.value
    // If newValue is inValid and not in Incomplete add to incomplete
    // add fieldName to incomplete and add Error Message (if applicable)
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <BondFields
          handleSubmit={this.handleSubmit}
          bondInfo={this.props.bondInfo}
          fields={this.state.fields}
          customFields={this.state.customFields}
          handleBlur={this.localBlur}
          handleChange={this.localChange}
          incompleteFields={this.state.localIncomplete}
          hasError={this.hasError}
          submitting={this.state.submitting}
          errors={this.state.errors}
          effectiveToday={this.effectiveToday}
        />
      </form>
    )
  }
}
