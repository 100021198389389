import React from 'react';
import styled from 'styled-components';
import { toFullStateName, toMoney } from '../../helpers';

const BondP = styled.p`
  margin-bottom:0;
  margin-top:0;
`;

const BondInformation = (props) => (
    <>
      <h5 className='panel-title'>Bond Information</h5>
      <BondP><strong>Type:</strong> {props.type} </BondP>
      <BondP><strong>State:</strong> {toFullStateName(props.state)} </BondP>
      <BondP><strong>Coverage Amount:</strong> {toMoney(props.coverageAmount, { noCents: false })} </BondP>
      <BondP><strong>Term:</strong> {props.term} </BondP>
    </>
);

export default BondInformation;
