import React from 'react';
import styled from 'styled-components';
import { useParams } from '@reach/router';
import { useQueryClient } from 'react-query';
import Footer from '../../Components/Footer';
import TrustSeals from '../../Components/TrustSeals';
import ProgressTracker from '../../instant-purchase/components/ProgressTracker';
import MobileBondSummary from './MobileBondSummary';
import AccountManagerBox from './AccountManagerBox';
import { isInstantPurchase } from '../../utils/business-logic';
import { useLead } from '../../client';
import { formatDateToMMDDYYYY } from '../../helpers'
import { Loading } from './Loading'

import BondSummary from './BondSummary';

import {
  TopBar,
  CheckoutHeader,
  SplitLayout,
  Card,
} from '../../DesignGuide';

const MessageP = styled.p`
  text-align: center;
`;

// Layout for StartApp
export function PurchaseBondLayout(props) {
  return (
    <>
      <TopBar title='Secure Checkout' subHeader={true} />
      <MobileBondSummary rightBox={props.rightBox}></MobileBondSummary>
      <div className='container main-container' style={{ minHeight: '80vh' }}>
        <CheckoutHeader>
          <div className='hide-on-print instant-purchase-heading'>
            {props.title ? props.title : 'Purchase Bond'}
          </div>
        </CheckoutHeader>
        {props.progressTracker && (
          <ProgressTracker
            nonce={props.nonce}
            canEdit={props.canEdit}
            currentStep={props.currentStep}
          ></ProgressTracker>
        )}
        {props.message ? <MessageP>{props.message}</MessageP> : null}
        <SplitLayout>
          <div>
            <Card
              className={props.noPadding ? 'no-padding' : ''}
              color={props.color || 'rgb(249, 249, 250)'}
            >
              {props.children}
            </Card>
          </div>

          <div className='right-box hide-on-print'>
            {props.rightBox ? (
              <>
                {props.isIP ? (
                  <BondSummary rightBox={props.rightBox}></BondSummary>
                ) : (
                  <>
                    {props.salesRep && (
                      <AccountManagerBox
                        salesRep={props.salesRep}
                      ></AccountManagerBox>
                    )}
                  </>
                )}
              </>
            ) : null}
            <TrustSeals />
          </div>
        </SplitLayout>
      </div>
      <Footer />
    </>
  );
}

export function LoadingShell({ title }) {
  return (
    <>
    <TopBar subHeader={true} title={title}></TopBar>
    <div style={{margin: "0 auto", display: "flex", height: "100vh", fontSize: "36px", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      <i className="fa fa-spinner fa-spin" />
    </div>
    </>
  )
}

export function RefactoredPurchaseBondLayout(props) {
  const { lead, invoice } = useLead();
  const queryClient = useQueryClient();
  const { nonce } = useParams();
  if (!lead || !invoice) {
    queryClient.invalidateQueries('lead');
    return <Loading isLoading={true} />;
  }
  const { suretyBond } = lead.bond.application;
  const bond = {
    version: suretyBond.version,
    id: suretyBond._id.toString(),
    state: lead.bond.state,
    name: suretyBond.name,
    amount: Number.parseFloat(lead.bond.amount.replace(/[$,]/gi, '')),
    originalRequired: suretyBond.originalRequired,
    effectiveDate: formatDateToMMDDYYYY(lead.bond.application.effectiveDate),
    expirationDate: formatDateToMMDDYYYY(lead.bond.application.expirationDate),
  };

  const rightBox = {
    bond,
    charges: invoice.charges,
    balance: invoice.balance,
  };
  const salesRep = lead.claimedBy;
  const isIP = isInstantPurchase(lead);
  const currentStep = 'Purchase Bond';
  const canEdit = isIP && !lead.prevPolicy;

  return (
    <>
      <TopBar title='Secure Checkout' subHeader={true} />
      <MobileBondSummary rightBox={rightBox}></MobileBondSummary>
      <div className='container main-container' style={{ minHeight: '80vh' }}>
        <CheckoutHeader>
          <div className='hide-on-print instant-purchase-heading'>
            Purchase Bond
          </div>
        </CheckoutHeader>
        <ProgressTracker
          nonce={nonce}
          canEdit={canEdit}
          currentStep={currentStep}
        ></ProgressTracker>
        <SplitLayout>
          <div>
            <Card className={'no-padding'} color={'rgb(249, 249, 250)'}>
              {props.children}
            </Card>
          </div>
          <div className='right-box hide-on-print'>
            {isIP ? (
              <BondSummary rightBox={rightBox}></BondSummary>
            ) : (
              <>
                {salesRep && (
                  <AccountManagerBox salesRep={salesRep}></AccountManagerBox>
                )}
              </>
            )}

            <TrustSeals />
          </div>
        </SplitLayout>
      </div>
      <Footer/>
    </>
  );
}

export default PurchaseBondLayout;
