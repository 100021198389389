import React from 'react';
import { Router } from '@reach/router';
import Confirmed from './Confirmed';
import Market from './Market';

function index(props) {
  return (
    <Router>
      <Market path='/market'></Market>
      <Confirmed path='/market/confirm'></Confirmed>
    </Router>
  );
}

export default index;
