import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import Layout from './Layout';
import { toFullStateName, formatPhone } from '../helpers';
import { initEngagedTracking } from './tracking';
// TODO: CN-1498 use company collection from lead starting line 100
const CustomField = (props) => {
  const { field, i } = props;
  let { value } = field;
  if (field.type.toLowerCase() == 'date') {
    value = moment(field.value).format('MM/DD/YYYY');
  }
  if (field.type.toLowerCase() == 'boolean') {
    value = field.value === true ? 'Yes/True' : 'No/False';
  }
  if (field.type.toLowerCase() === 'phone') {
    value = formatPhone(field.value);
  }
  if (field.required) {
    return (
      <div classname="verify-group" key={i}>
        <div className="black-label">{field.display}</div>
        <div className="value">{value}</div>
      </div>
    );
  } return null;
};

function Confirmed(props) {
  // Initial State
  const [state, setState] = React.useState({
    nonce: props.nonce,
    loading: true,
  });

  // our on mount "data-fetcher"
  React.useEffect(() => {
    async function getAppState() {
      const search = window.location.search
        ? `${window.location.search}&timestamp=${new Date().getTime()}`
        : `?timestamp=${new Date().getTime()}`;
      const { data } = await Axios.get(
        `/engage/${props.nonce}/get-app-state/${search}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );

      initEngagedTracking(props.nonce, { ...data, isConfirmPage: true });
      //
      // dispatch init
      setState({ loading: false, ...data });
    }
    getAppState();
  }, []);
  return (
    <>
      {state.loading ? null : (
        <Layout>
          <div className="center">
            <div className="thank-you-copy" style={{ marginTop: '20px' }}>
              <h1>Your application has been submitted successfully!</h1>
              <h3>We will send an email shortly with your renewal quote.</h3>
            </div>
            <div className="thank-you-page">
              <div className="thank-you-heading">Bond Information</div>
              <hr />
              <div className="black-label">
                {toFullStateName(state.lead.bond.state)}
                <br />
                {state.lead?.bond?.application?.suretyBond?.name || state.lead.bond.type}
              </div>
              <div className="thank-you-section bond">
                <div className="verify-group tb">
                  <div className="black-label">Bond Amount</div>
                  <div className="value">{state.lead.bond.amount}</div>
                </div>
                <div className="verify-group tb">
                  <div className="black-label">Effective Date</div>
                  <div className="value">
                    {moment(
                      state.lead.bond.application.effectiveDate,
                    ).format('MM/DD/YYYY')}
                  </div>
                </div>

                {state.lead.customFields
                  && state.lead.customFields.length > 0
                  && state.lead.customFields.map((field, i) => (
                    <CustomField field={field} i={i} />
                  ))}
              </div>
              <div className="thank-you-middle">
                <div className="thank-you-heading">Principal Information</div>
                <div className="thank-you-section principal">
                  <div className="black-label">
                    {state.lead.company_name}
                    {state.lead.company_dba && (
                      <>
                        <strong> DBA </strong>
                        {state.lead.company_dba}
                      </>
                    )}
                    <address>
                      <div className="value">{state.lead.company_address}</div>
                      {state.lead.company_address2 && (
                        <div className="value">
                          {state.lead.company_address2}
                        </div>
                      )}
                      <div className="value">
                        {state.lead.company_city}, {state.lead.company_state},{' '}
                        {state.lead.company_zip}
                      </div>
                    </address>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Layout>
      )}
    </>
  );
}

export default Confirmed;
