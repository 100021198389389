import React from 'react';
import styled from 'styled-components';
import { toMoney } from '../../helpers';

const UnstyledRadioCard = (props) => {
  const {
    value, checked, onChange, price, name,
  } = props;
  return (
    <div className={props.className}>
      <CustomCheckBox
        type='radio'
        name={name}
        id={value}
        value={props.value}
        checked={checked === value}
        onChange={onChange}
        required
        >
      </CustomCheckBox>
      <label htmlFor={value}>
        <div style={{ float: 'left', marginLeft: '25px' }}>
          <span> {value}</span>
          <span>{!!props.moreText && props.moreText}</span>
        </div>
        <div style={{ float: 'right', color: '#0d8bd1' }} >
          {!!props.oldPrice
          && <>
            <Savings>Save {toMoney((props.oldPrice - props.price), { noCents: false })}! </Savings>
            <OldPrice>{toMoney(props.oldPrice, { noCents: false })}</OldPrice>
          </>
          }
          {!!props.price
            && <strong>${price}</strong>
          }
        </div>
        <div style={{ clear: 'both' }}></div>
      </label>
    </div>
  );
};

const CustomCheckBox = styled.input`
  // input stuff
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  // before label when checked
  :checked + label:before{
    border: 4px solid #0d8bd1;
    background-color: #0d8bd1;
    box-shadow: inset 0 0 0 0.22em rgba(255, 255, 255, 0.95);
  }
  // label before element
  + label::before{
    content: '';
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    border-radius: 100%;
    margin-right:5px;
    background: #fff;
    border: 4px solid #d6dcde;
    background-color: rgba(255, 255, 255, 0.8);
    display: block;
    box-sizing: border-box;
    float: left;
    width: 20px;
    height: 20px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all .08s ease-out;
    transition: all .08s ease-out;
  }
`;

const RadioCard = styled(UnstyledRadioCard)`
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
  position:relative;
  margin: 8px 0;
  color: black;
  background: white;
  border-radius: 4px;
  :hover{
    box-shadow: 0 0.125rem 0 #156c9f;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    transition:ease all 0.4s;
  }
  label{
    display:block;
    padding:10px;
  }
  label::before{
    margin-right:5px;
  }
`;
const OldPrice = styled.span`
  text-decoration:line-through;
  margin-right:2em;
  color:#9E9E9E;
`;
const Savings = styled.span`
  color: #99c432;
  margin-right:2em;
`;

export default RadioCard;
